<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">
    <input style="width: 40px; margin-right: 5px"
           maxlength="3"
           class="formcontrol"
           [value]="value"
           (blur)="onTouched()"
           (input)="onChange($event.target.value)"
           type="text"
           id="Input">

    <!--<input style="width: 170px"
           class="formcontrol"
           [value]="value"
           (blur)="onTouched()"
           (input)="onChange($event.target.value)"
           type="text"
           id="Input">-->

    <select style="height:24px; padding: 2px; width: 180px" 
            class="formcontrol"
            id="Input"
            (blur)="onTouched()"
            (input)="onChange($event.target.value)">
      <option value=""></option>
      <option value="ABC">ABC</option>
      <option value="ZZZ">ZZZ</option>
    </select>
  </div>
</div>
