// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


////Production environment
//export const environment = {
//  production: false,
//  appUrl: 'http://192.168.2.7:44350/',
//  apiUrl: '/api'
//};

////Production with cert
export const environment = {
  production: false,
  appUrl: 'https://fwb.arubaaloe.com:44350/',
  apiUrl: '/api'
};


//////XO test/dev machine
//export const environment = {
//  production: false,
//  appUrl: 'https://192.168.0.140:44346/',
//  apiUrl: '/api'
//};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
