<h1>{{ 'Users' | translate }}</h1>
<p *ngIf="!items$"><em>Loading...</em></p>

<p>
  <a *ngIf="!isNewItem$"
     (click)="newItem()"
     name="newUserButton"
     class="btntest btn btn-default float-right mb-3">{{ 'New' | translate }} {{ 'User' | translate }}</a>
</p>

<form>
  <div class="input_container">
    <i class="fas fa-envelope"></i>
   
    <input placeholder="{{ 'Search' | translate }}..."
           size="50"
           type="search"
           class='formcontrol'
           (keyup)="doFilter($event.target.value)">
  </div>



  <table mat-table [dataSource]="listDataSource" matSort>
    <ng-container matColumnDef="usUserName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Username' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.usUserName}} </td>
    </ng-container>
    <ng-container matColumnDef="usFirstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'First Name' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.usFirstName}} </td>
    </ng-container>
    <ng-container matColumnDef="usLastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'Last Name' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.usLastName}} </td>
    </ng-container>
    <ng-container matColumnDef="usCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Code' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.usCode}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns; let i = index"
        (click)="setSelectedItem(row)"
        tabindex="1"></tr>
  </table>
</form>
