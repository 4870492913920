[
  {
    "Id": "1c49251d-9efc-46a2-8084-d2cf7218abfa",
    "displayName": "Home",
    "iconName": "home",
    "route": "home",
    "accessId": 0,
    "notes": null,
    "children": [
    ],
    "IsExpanded": false,
    "IsSelected": false
  },
  {
    "Id": "edac8428-afc5-4c57-bd1f-177311093ecb",
    "displayName": "System",
    "iconName": "computer",
    "route": "",
    "accessId": 100000,
    "notes": null,
    "children": [
      {
        "Id": "76d99d56-cb6a-446c-b24b-6c484e982c1b",
        "displayName": "Database Manager",
        "iconName": "admin_panel_settings",
        "route": "system/admin",
        "accessId": 110000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "abce4a3a-bd52-4795-9538-dc1143295c97",
        "displayName": "Global Settings",
        "iconName": "language",
        "route": "system/settings/global",
        "accessId": 120000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "8478fc0c-45a2-441b-8532-d08d4929e3c2",
        "displayName": "Holidays",
        "svgIconName": "holidays",
        "iconName": "Holidays",
        "route": "system/holidays",
        "accessId": 130000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      }
    ],
    "IsExpanded": false,
    "IsSelected": false
  },
  {
    "Id": "bd75be68-07c4-4b3a-983f-bb4e8314dcc9",
    "displayName": "Security",
    "iconName": "security",
    "route": "",
    "accessId": 200000,
    "notes": null,
    "children": [
      {
        "Id": "1056d74a-7a25-44ef-b809-92d1fa8d77db",
        "displayName": "My Account",
        "iconName": "account_box",
        "route": "system/myaccount",
        "accessId": 210000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "fe0211e1-f6b3-4e6f-92c1-e9b2aaa85d9c",
        "displayName": "Users",
        "iconName": "group",
        "route": "security/users",
        "accessId": 220000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "b26946f5-e7fc-46ae-844c-db1aeea7cf1c",
        "displayName": "Roles",
        "iconName": "groups",
        "route": "security/roles",
        "accessId": 230000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "ec5d5407-a1fa-43fb-8bae-932275cdd16e",
        "displayName": "Switch user",
        "iconName": "swap_horiz",
        "route": "switchuser",
        "accessId": 240000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      }
    ],
    "IsExpanded": false,
    "IsSelected": false
  },
  {
    "Id": "718ce58e-7c59-4e39-9309-206cd3e0f607",
    "displayName": "Reports",
    "iconName": "bar_chart",
    "route": "",
    "accessId": 300000,
    "notes": null,
    "children": [
        {
            "Id": "33231ae0-cc90-407d-a3fc-512dcc192cae",
            "displayName": "Security",
            "svgIconName": "security",
            "iconName": "",
            "route": "reportview",
            "accessId": 310000,
            "notes": null,
            "children": [
            ],
            "IsExpanded": false,
            "IsSelected": false
        },
        {
            "Id": "0455ad2d-2f4f-4629-bd87-2b8cb2029611",
            "displayName": "Audit",
            "svgIconName": "audit",
            "iconName": "",
            "route": "reportview",
            "accessId": 320000,
            "notes": null,
            "children": [
            ],
            "IsExpanded": false,
            "IsSelected": false
        },
        {
            "Id": "0f837433-9ef6-4784-93d7-9a56683e2247",
            "displayName": "Time Track",
            "svgIconName": "timetrack",
            "iconName": "",
            "route": "reportview",
            "accessId": 330000,
            "notes": null,
            "children": [
            ],
            "IsExpanded": false,
            "IsSelected": false
        }
    ],
    "IsExpanded": false,
    "IsSelected": false
  },
  {
    "Id": "a2dee995-b912-4ca0-ad52-a4bca80bf34c",
    "displayName": "Settings",
    "svgIconName": "settings",
    "iconName": "",
    "route": null,
    "accessId": 400000,
    "notes": null,
    "children": [
      {
        "Id": "7e926f17-2f5c-42a6-b76b-e764a7d84c05",
        "displayName": "Projects",
        "svgIconName": "projects",
        "iconName": "",
        "route": "settings/projects",
        "accessId": 410000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "37564146-8716-409d-b6ad-72bab60cc713",
        "displayName": "Work Types",
        "svgIconName": "worktypes",
        "iconName": "",
        "route": "settings/worktypes",
        "accessId": 420000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      },
      {
        "Id": "c038db01-78e9-458c-a553-76d4174082df",
        "displayName": "Clients",
        "svgIconName": "clients",
        "iconName": "",
        "route": "settings/clients",
        "accessId": 430000,
        "notes": null,
        "children": [
        ],
        "IsExpanded": false,
        "IsSelected": false
      }
    ],
    "IsExpanded": false,
    "IsSelected": false
  },
  {
    "Id": "8bdcbdcc-4ca1-4f5a-8cbf-c43a3eee2bed",
    "displayName": "Time Sheets",
    "svgIconName": "timesheets",
    "iconName": "",
    "route": "timesheets",
    "accessId": 500000,
    "notes": null,

    "IsExpanded": false,
    "IsSelected": false
  },
  {
    "Id": "c8314058-4255-4f83-b9b2-4f8f1244895e",
    "displayName": "Invoicing",
    "svgIconName": "invoicing",
    "iconName": "",
    "route": "invoicing",
    "accessId": 600000,
    "notes": null,

    "IsExpanded": false,
    "IsSelected": false
  }
]