"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buffer = void 0;
var lift_1 = require("../util/lift");
var noop_1 = require("../util/noop");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function buffer(closingNotifier) {
  return lift_1.operate(function (source, subscriber) {
    var currentBuffer = [];
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      return currentBuffer.push(value);
    }, function () {
      subscriber.next(currentBuffer);
      subscriber.complete();
    }));
    closingNotifier.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function () {
      var b = currentBuffer;
      currentBuffer = [];
      subscriber.next(b);
    }, noop_1.noop));
    return function () {
      currentBuffer = null;
    };
  });
}
exports.buffer = buffer;
