<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" (click)="treeControl.toggle(node)" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    {{node.name | translate}}
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button (click)="treeControl.toggle(node)"
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.name | translate}}
  </mat-tree-node>
</mat-tree>


{{ getSelected() }}
<!--{{ TreeViewDATA }}-->
<!--{{ TreeViewLOAD }}-->
