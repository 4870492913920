import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportPreviewItem, ReportComponentBase } from '../../../../../core/report-component-base';
import { ReportService } from '../../../../../services/reports/report.service';

@Component({
  selector: 'ReportHeaderButtons',
  templateUrl: './report-header-button.component.html',
  styleUrls: ['./report-header-button.component.scss']
})

export class ReportHeaderButtonComponent extends ReportComponentBase implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReportHeaderButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportPreviewItem,
    public reportService: ReportService) {
    super(dialogRef, data, reportService);

    //Set orientation; portrait | landscape
    //this.reportOrientation = 'portrait';
  }

  onKeydown(event) {
    if (event.key === "p") {
      event.preventDefault();
      if (event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
      } else {
        event.stopPropagation();
      }
      super.PrintReport()
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}

