<!--<button class="float-right" mat-icon-button id="close" (click)="closeView()">
  <span class="material-icons">close</span>
</button>-->
<!--<div mat-dialog-content style="background-color:#fff">
  <button class="float-right" mat-icon-button id="close" (click)="closeView()">
    <span class="material-icons">close</span>
  </button>
</div>-->
<!--<h1>Switch users</h1>
<div mat-dialog-content>
  <p>Are you sure you want to switch user?</p>
</div>
<div mat-dialog-actions>
  <button class="btntest btn btn-default" (click)="onNoClick()">No</button>
  <button class="btntest btn btn-default" (click)="onYesClick()">Yes</button>
</div>-->

  <div mat-dialog-content>
      <span class="material-icons float-right" id="close" (click)="closeView()">close</span>
  </div>

<body (keydown)="onKeydown($event)">

  <div mat-dialog-content id="form_wrapper">
    <div id="form_left">
      <img src="./assets/images/FW/gamma.png" alt="icon">
      <h1>Framework 2020</h1>

      <p>Version: 1.0</p>
    </div>

    <form [formGroup]="loginForm">
      <div id="form_right">
        <h1>User Log In</h1>
        <div class="input_container">
          <i class="fas fa-envelope"></i>
          <input placeholder="Username"
                 type="text"
                 id="username"
                 formControlName="username"
                 class='input_field'
                 [(ngModel)]="username"
                 (change)="onUsernameChange($event)"
                 (keydown.enter)="onUsernameChange($event)">
        </div>
        <div class="input_container">
          <i class="fas fa-lock"></i>
          <input placeholder="Password"
                 type="password"
                 id="password"
                 formControlName="password"
                 class='input_field'
                 [(ngModel)]="password"
                 (change)="onPasswordChange($event)"
                  (keydown.enter)="onPasswordChange($event)">
        </div>

        <!--<input type="submit" value="Login" id='input_submit' class='input_field' click="doLogin()">-->

        <button type="button" (click)="doLogin()" id='input_submit' class='input_field'>Login</button>


      </div>
    </form>
  </div>

</body>
