"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.window = void 0;
var Subject_1 = require("../Subject");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var noop_1 = require("../util/noop");
function window(windowBoundaries) {
  return lift_1.operate(function (source, subscriber) {
    var windowSubject = new Subject_1.Subject();
    subscriber.next(windowSubject.asObservable());
    var errorHandler = function (err) {
      windowSubject.error(err);
      subscriber.error(err);
    };
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      return windowSubject === null || windowSubject === void 0 ? void 0 : windowSubject.next(value);
    }, function () {
      windowSubject.complete();
      subscriber.complete();
    }, errorHandler));
    windowBoundaries.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function () {
      windowSubject.complete();
      subscriber.next(windowSubject = new Subject_1.Subject());
    }, noop_1.noop, errorHandler));
    return function () {
      windowSubject === null || windowSubject === void 0 ? void 0 : windowSubject.unsubscribe();
      windowSubject = null;
    };
  });
}
exports.window = window;
