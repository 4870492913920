import { Component, Input, ViewChild } from '@angular/core';
import { MatTab, MAT_TAB_GROUP, } from '@angular/material/tabs';

@Component({
  selector: 'custom-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  providers: [
    {
      provide: MAT_TAB_GROUP,
      useValue: undefined,
    },
  ]
})
export class TabComponent {
  @Input() label: string;

  @ViewChild(MatTab)
  public matTab: MatTab;
}
