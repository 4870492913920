'use strict';

var Parser = require('./Parser');
module.exports = function parse(data, options) {
  var p;
  if (typeof options === 'function') {
    p = new options(); // eslint-disable-line
  } else {
    p = new Parser(options);
  }
  var result = null;
  var error = null;
  p.on('tree', function (tree) {
    result = tree;
  });
  p.on('error', function (e) {
    error = e;
  });
  p.write(data);
  p.end();
  if (error) {
    throw error;
  } else {
    return result;
  }
};