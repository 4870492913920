/* src/app/FW/controls/containers/multicombobox-entry/multicombobox-entry.component.scss */
:host ::ng-deep .ng-value-container {
  min-height: 2px;
}
:host ::ng-deep .ng-select .ng-select-container {
  min-height: 2px;
}
:host ::ng-deep .ng-select.ng-select-single .ng-select-container {
  height: 2px;
}
:host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0px;
  padding-left: 7px;
  overflow-y: auto !important;
  overflow-x: hidden;
  flex-wrap: nowrap;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
  word-wrap: break-word !important;
}
button.btntest.btn.btn-sm.btn-secondary.ng-star-inserted {
  color: #000;
}
/*# sourceMappingURL=multicombobox-entry.component.css.map */
