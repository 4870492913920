<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button class="btntest btn-lg btn btn-default float-right" (click)="onConfirm()" autoFocus>Yes</button>
  <button class="btntest btn-lg btn btn-default float-right" (click)="onDismiss()">No</button>
</div>
