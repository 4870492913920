<div style="padding: 10px 15px 0 15px;">
  <div>
    <h1>{{ 'My Account' | translate }}</h1>
  </div>

  <UserDetail [MyAccount]="true"
              [ActiveUser]="user$"></UserDetail>
</div>


