<!--<mat-toolbar color="primary" class="mat-elevation-z1" *ngIf="isUserLoggedIn$">-->
  <mat-toolbar style="background: linear-gradient(#3f86e8, #3172cc)" class="mat-elevation-z1">

    <button type="button" class="hamburger" mat-icon-button id="menu" (click)="menuService.toggleNav()" *ngIf="isUserLoggedIn$">
      <mat-icon>menu</mat-icon>
    </button>
    <!--<span>APPLICATION NAME</span>-->
    <span class="myaccount-spacer"></span>
    <span flex></span>
    <div *ngIf="isUserLoggedIn$" class="menuActiveUser">
      <mat-icon mat-button [matMenuTriggerFor]="menuDropDown" style="font-size: 25px">person</mat-icon>
      <mat-menu #menuDropDown="matMenu" class="LogoutMenu">
        <button mat-menu-item (click)="doLogout()" class="logoutButton"> {{ 'Logout' | translate }}Logout</button>
      </mat-menu>
      {{ user$.usUserName }}
    </div>
  </mat-toolbar>
