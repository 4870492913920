'use strict';

function nameEqual(a, b) {
  return a.name === b.name;
}
function attrsEqual(a, b) {
  var attrs = a.attrs;
  var keys = Object.keys(attrs);
  var length = keys.length;
  if (length !== Object.keys(b.attrs).length) return false;
  for (var i = 0, l = length; i < l; i++) {
    var key = keys[i];
    var value = attrs[key];
    if (value == null || b.attrs[key] == null) {
      // === null || undefined
      if (value !== b.attrs[key]) return false;
    } else if (value.toString() !== b.attrs[key].toString()) {
      return false;
    }
  }
  return true;
}
function childrenEqual(a, b) {
  var children = a.children;
  var length = children.length;
  if (length !== b.children.length) return false;
  for (var i = 0, l = length; i < l; i++) {
    var child = children[i];
    if (typeof child === 'string') {
      if (child !== b.children[i]) return false;
    } else {
      if (!child.equals(b.children[i])) return false;
    }
  }
  return true;
}
function equal(a, b) {
  if (!nameEqual(a, b)) return false;
  if (!attrsEqual(a, b)) return false;
  if (!childrenEqual(a, b)) return false;
  return true;
}
module.exports.name = nameEqual;
module.exports.attrs = attrsEqual;
module.exports.children = childrenEqual;
module.exports.equal = equal;