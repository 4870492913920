<form [formGroup]="parameters" #formDir="ngForm" novalidate>

  <div >
    <DateRangePickerEntry [Label]="'Period'"
                          formControlName="dateRange"
                          [defaultDates]="3">
    </DateRangePickerEntry>
  </div>

  <div style="margin-bottom: 5px;">
    <MultiComboBoxEntry [Label]="'Role'"
                        [items]="roles"
                        formControlName="role">

    </MultiComboBoxEntry>
  </div>


</form>


<!--<form [formGroup]="parameters" #formDir="ngForm" novalidate>

  <ComboBoxEntry [Label]="'Role'"
                 formControlName="role">
    <option [value]="All">
      Select All
    </option>

    <option *ngFor="let role of roles" [value]="role.value">
      {{role.name}}
    </option>
  </ComboBoxEntry>

</form>-->
