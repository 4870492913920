<h1>{{ 'Roles' | translate }}</h1>
<p *ngIf="!items$"><em>Loading...</em></p>

<p>
  <a *ngIf="!isNewItem$"
     (click)="newItem()"
     name="newUserButton"
     class="btntest btn btn-default float-right mb-3">{{ 'New' | translate }} {{ 'Role' | translate }}</a>
</p>

<form>
  <div class="input_container">
    <i class="fas fa-envelope"></i>

    <input placeholder="{{ 'Search' | translate }}..."
           size="50"
           type="search"
           class='formcontrol'
           (keyup)="doFilter($event.target.value)">
  </div>

  <table mat-table [dataSource]="listDataSource" matSort>
    <ng-container matColumnDef="roCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Code' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.roCode}} </td>
    </ng-container>
    <ng-container matColumnDef="roName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Name' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.roName}} </td>
    </ng-container>
    <ng-container matColumnDef="roDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Description' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.roDescription}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns; let i = index"
        (click)="setSelectedItem(row)"
        class="table-row"
        tabindex="1"></tr>
  </table>
</form>
