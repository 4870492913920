/* src/app/FW/controls/_Examples/control-view.component.scss */
.btn-default {
  color: #000000;
  background-color: #f2f2f2;
  border-color: #e8e8e8;
  height: 30px;
  text-align: center;
  line-height: 14px;
  text-transform: none;
  font-size: 16px;
}
:host ::ng-deep .mat-tree {
  background: none;
}
.tab-content {
  background-color: #e8e8e8;
  opacity: 1;
  overflow: hidden;
  padding: 5px 5px 5px 5px;
  box-sizing: border-box;
}
.mat-tab-body-content {
  overflow: hidden;
}
:host ::ng-deep .mat-tab-label .mat-tab-label-content {
  margin-left: 10px;
  margin-right: 10px;
}
:host ::ng-deep .mat-tab-label {
  border-width: 1px;
  background: #ffffff;
  color: #000000;
  min-width: 20px;
  max-height: 30px;
  opacity: 1;
  border-radius: 7px 7px 0px 0px;
}
:host ::ng-deep .mat-ink-bar {
  border-width: 1px;
  border-style: solid;
  background: #e8e8e8;
  color: #e8e8e8;
  width: 20px;
  max-height: 30px;
  opacity: 1;
}
:host ::ng-deep .mat-tab-label-active {
  background: #e8e8e8;
  border-radius: 7px 7px 0px 0px;
  color: #000000;
  opacity: 1;
}
::ng-deep .mat-tab-label,
::ng-deep .mat-tab-label-active {
  min-width: 0 !important;
  padding: 1px !important;
  margin-right: 3px !important;
  margin-bottom: -1px !important;
}
:host ::ng-deep .mat-checkbox-checkmark-path {
  stroke-width: 5.133333px;
}
/*# sourceMappingURL=control-view.component.css.map */
