import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { ReportParameterComponentBase } from '../../../../FW/core/report-parameter-component-base';
import { ComboBoxItem } from '../../../../FW/controls/combobox/combobox.component';
import { UserService } from '../../../../FW/services/security/users/user.service';
import { ReportService } from '../../../../FW/services/reports/report.service';

@Component({
  selector: 'AuditActionsParametersComponent',
  templateUrl: './audit-actions-parameters.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None // Add this line
})

export class AuditActionsParametersComponent extends ReportParameterComponentBase implements OnInit {
  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};

  selectedItems = [];

  users: ComboBoxItem[];
  public selectedUser = new Subject<string>();

  constructor(private userService: UserService,
    public reportService: ReportService,
    private cookieService: CookieService) {
    super(reportService);

    //Create parameter group
    this.parameters = new UntypedFormGroup({
      user: new UntypedFormControl('', [Validators.required]),
      dateRange: new UntypedFormControl('', [Validators.required])
    });

  }


  ngOnInit() {
    super.ngOnInit();

    //LOAD ROLES
    this.loadUsers();
    this.reportService.setShowUserFilter(true);
    this.reportService.setShowDateRangeFilter(true);

    this.parameters.get("dateRange").valueChanges.subscribe(x => {
      console.log(x);

      const pipe = new DatePipe('en-US');
      let start = x.StartDate;
      let end = x.EndDate;

      let startDate = pipe.transform(start, 'dd-MM-yyyy')
      let endDate = pipe.transform(end, 'dd-MM-yyyy')

      this.reportService.setDateRangeFilterValues(startDate + ' to ' + endDate);
    });

    this.parameters.get("user").valueChanges.subscribe(selectedValues => {

      if (selectedValues.length > 0) {
        this.cookieService.set("AuditActions,User", JSON.stringify(selectedValues));
      }

   

      var selectedUsers = [];

      selectedValues.length;
      this.users.length;

      if (selectedValues.length === this.users.length) {
        selectedUsers.push('All');
      } else {
        selectedValues.forEach((su) => {
          var user = this.users.find(u => u.value === su)


          if (user != undefined) {
            var userName = this.users.find(u => u.value === su).name;

            if (userName != undefined) {
              selectedUsers.push(this.users.find(u => u.value === su).name);
            }
          }



        });

        if (this.users.length === selectedUsers.length) {
          //selectedUsers.push('All');
          this.parameters.controls.user.setValue(selectedUsers);
        }

      }

      this.reportService.setUserFilterValues(selectedUsers);
  
    })

    this.parameters.get("dateRange").valueChanges.subscribe(selectedValues => {
      console.log(selectedValues);
    })


    //this.parameters.valueChanges.subscribe(x => {
    //  console.log('form value changed')
    //  console.log(x)
    //})
  }

  loadUsers() {
    this.users = [];

    this.userService.getList()
      .subscribe(result => {

        var userList = [];
        result.forEach((u) => {
          var user = new ComboBoxItem(u.usKey, u.usUserName)
          userList.push(user);

          //this.roles = [this.roles, ];
        });

        this.users = userList;

        //// SELECT ALL ON LOAD
        var selectAllByDefault = this.users.map(x => x.value);
        //this.parameters.controls.role.setValue(test);

        var lastUsedParameters = JSON.parse(this.cookieService.get("AuditActions,User"));

       

        if (lastUsedParameters != '') {
          this.parameters.controls.user.setValue(lastUsedParameters);
        } else {
          this.parameters.controls.user.setValue(selectAllByDefault);
        }


   

      });
  }
}
