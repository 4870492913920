<button class="float-right" mat-icon-button id="close" (click)="closeDetailView()" *ngIf="!MyAccount">
  <mat-icon>close</mat-icon>
</button>

<!--<h1>{{ actionType | translate }} {{ itemType | translate }}: {{ selectedItemName }}</h1>-->
<h1 *ngIf="!MyAccount">{{ actionType | translate }} {{ itemType | translate }}: {{ selectedItemName }}</h1>

<form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate>
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{ 'Information' | translate }}">
      <div>

        <div class="tab-content">
          <div class="left">

            <label class="label">{{ 'User' | translate }} {{ 'Information' | translate }}</label>
            <hr class="solid" style="margin-right:327px;">

            <!--First Name Field-->
            <TextEntry Label="{{ 'First Name' | translate }}"
                       formControlName="usFirstName"
                       [IsDisabled]="MyAccount">
            </TextEntry>
            <span class="text-danger" *ngIf="form.controls.usFirstName.invalid && formDir.submitted">
              {{ 'First Name' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Last Name Field-->
            <TextEntry Label="{{ 'Last Name' | translate }}"
                       formControlName="usLastName"
                       [IsDisabled]="MyAccount">
            </TextEntry>
            <span class="text-danger" *ngIf="form.controls.usLastName.invalid && formDir.submitted">
              {{ 'Last Name' | translate }} {{ 'is required' | translate }}
            </span>

            <!--User Name Field-->
            <TextEntry Label="{{ 'Username' | translate }}"
                       formControlName="usUserName"
                       [IsDisabled]="MyAccount">
            </TextEntry>
            <span class="text-danger" *ngIf="form.controls.usUserName.invalid && formDir.submitted">
              {{ 'Username' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Code Field-->
            <CodeEntry Label="{{ 'Code' | translate }}"
                       formControlName="usCode"
                       [IsDisabled]="MyAccount">
            </CodeEntry>
            <span class="text-danger" *ngIf="form.controls.usCode.invalid && formDir.submitted">
              {{ 'Code' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Email Field-->
            <TextEntry Label="{{ 'Email' | translate }}"
                       formControlName="usEmail"
                       [IsDisabled]="MyAccount">
            </TextEntry>
            <span class="text-danger" *ngIf="form.controls.usEmail.invalid && form.touched">
              {{ 'Email' | translate }} {{ 'is invalid' | translate }}
            </span>





            <!--Status Field-->
            <ComboBoxEntry Label="{{ 'Status' | translate }}"
                           formControlName="usStatus"
                           [IsDisabled]="MyAccount">
              <option *ngFor="let status of statuses" [value]="status.value">
                {{ status.name | translate }}
              </option>
            </ComboBoxEntry>
            <span class="text-danger" *ngIf="form.controls.usStatus.invalid && formDir.submitted">
              {{ 'Status' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Role Field-->
            <ComboBoxEntry Label="{{ 'Role' | translate }}"
                           formControlName="usRoKey"
                           [IsDisabled]="MyAccount">
              <option *ngFor="let role of roles" [value]="role.roKey">
                {{role.roName | translate}}
              </option>
            </ComboBoxEntry>
            <span class="text-danger" *ngIf="form.controls.usRoKey.invalid && formDir.submitted">
              {{ 'Role' | translate }} {{ 'is required' | translate }}
            </span>

            <br />

            <label class="label">{{ 'User' | translate }} {{ 'Settings' | translate }}</label>
            <hr class="solid" style="margin-right:327px;">

            <!--Language Field-->
            <ComboBoxEntry Label="{{ 'Language' | translate }}"
                           formControlName="usLanguage"
                           (change)="onLanguageValueChange($event)">
              <option *ngFor="let language of languages" [value]="language.value">
                {{language.name | translate}}
              </option>
            </ComboBoxEntry>
            <span class="text-danger" *ngIf="form.controls.usLanguage.invalid && formDir.submitted">
              {{ 'Language' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Default Hours Per Day Field-->
            <DecimalEntry Label="{{ 'Hours Per Day' | translate }}"
                          formControlName="usDefaultHoursPerDay">
            </DecimalEntry>
            <span class="text-danger" *ngIf="form.controls.usDefaultHoursPerDay.invalid && formDir.submitted">
              {{ 'Hours Per Day' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Hourly Rate Field-->
            <DecimalEntry Label="{{ 'Hourly Rate' | translate }}"
                          formControlName="usHourlyRate">
            </DecimalEntry>
            <span class="text-danger" *ngIf="form.controls.usHourlyRate.invalid && formDir.submitted">
              {{ 'Hourly Rate' | translate }} {{ 'is required' | translate }}
            </span>


            <!--User Start Time Field-->
            <div class="row timepicker" style="">
              <label class="col-sm-2" for="Input" style="">{{ 'Start Time' | translate }}</label>

              <div class="col-md-8">

                <TimePicker appearance="outline" color="primary" hideMeridian="false" label="" [userTime]="selectedStartTime" (change)="onChangeStartTime($event)"
                            revertLabel="Remove" submitLabel="Ok"></TimePicker>

              </div>
            </div>
            <span class="text-danger" *ngIf="form.controls.usStartTime.invalid && formDir.submitted">
              {{ 'Time' | translate }} {{ 'is required' | translate }}
            </span>






            <br />

          </div>


          <div class="right">

            <div style="margin-left: -240px;">
              <label class="label">{{ 'Portrait' | translate }}</label>
              <hr class="solid" style="margin-right:315px;">
            </div>


            <div style="margin-left:-240px;
                        padding-bottom: 5px;">
              <img style="width: auto;
                        height: 200px;
                        max-width: 200px;
                        padding-bottom: 15px;"
                   src="{{ formImage }}">

              <!--<input class="formcontrol" type="file" formControlName="image" (change)="selectFile($event)">-->
              <!--<input class="formcontrol" type="file" (change)="fileChange($event)">-->

              <br />
              <br />

              <button style="width: 125px; padding: 0px; margin-top: 25px;"
                      class="btntest btn btn-default"
                      type="button"
                      onclick="document.getElementById('getFile').click()">
                {{ 'Change Portrait' | translate }}
              </button>

              <button style="width: 125px; padding: 0px; margin-top: 25px;"
                      class="btntest btn btn-default"
                      type="button"
                      (click)="removePortrait()">
                {{ 'Remove Portrait' | translate }}
              </button>

              <div>
                <input type='file' id="getFile" accept=".jpg,.jpeg,.png" (change)="fileChange($event)" style="display:none">
              </div>

            </div>

          </div>

        </div>
      </div>

    </mat-tab>


    <mat-tab label="{{ 'Password' | translate }}" *ngIf="!MyAccount">
      <div class="tab-content">

        <label class="label"> {{ 'Password Information' | translate }}</label>
        <hr class="solid" style="margin-right:560px;">

        <!--Password Field-->
        <PasswordEntry *ngIf="isNewItem$"
                       Label="{{ 'Password' | translate }}"
                       formControlName="usPassword">
        </PasswordEntry>
        <span class="text-danger" *ngIf="form.controls.usPassword.invalid && formDir.submitted">
          {{ 'Password' | translate }} {{ 'is required' | translate }}
        </span>

        <!--New Password Field-->
        <PasswordEntry *ngIf="!isNewItem$"
                       Label="{{ 'New Password' | translate }}"
                       formControlName="newPassword">
        </PasswordEntry>

        <!--Confirm Password Field-->
        <!--<PasswordEntry *ngIf="!isNewItem$"
                 [Label]="'Confirm Password'"
                 formControlName="confirmNewPassword">
  </PasswordEntry>-->
        <PasswordEntry Label="{{ 'Confirm Password' | translate }}"
                       formControlName="confirmNewPassword">
        </PasswordEntry>
        <span class="text-danger" *ngIf="form.controls.confirmNewPassword.invalid && formDir.submitted">
          {{ 'Confirm Password' | translate }} {{ 'is required' | translate }}
        </span>

        <!--PasswordExpirationDays Field-->
        <NumericEntry Label="{{ 'Expiration After' | translate }}"
                      formControlName="usPasswordExpirationDays">
        </NumericEntry>
        <!--      <label style="margin-left:5px">{{ 'Days' | translate }}</label>-->
        <!--PasswordExpirationDate Field-->
        <DateEntry Label="{{ 'Next Expiration Date' | translate }}"
                   formControlName="usPasswordExpirationDate">
        </DateEntry>

        <!--PasswordLength Field-->
        <NumericEntry Label="{{ 'Minimum Length' | translate }}"
                      formControlName="usPasswordLength">
        </NumericEntry>

      </div>
    </mat-tab>



    <!--MyAccount Password change-->
    <mat-tab label="{{ 'Password' | translate }}" *ngIf="MyAccount">
      <div class="tab-content">

        <label class="label">{{ 'Password Information' | translate }}</label>
        <hr class="solid" style="margin-right:560px;">

        <!--Password Field-->
        <PasswordEntry *ngIf="isNewItem$"
                       Label="{{ 'Password' | translate }}"
                       formControlName="usPassword">
        </PasswordEntry>Password
        <span class="text-danger" *ngIf="form.controls.usPassword.invalid && formDir.submitted">
          {{ 'Password' | translate }} {{ 'is required' | translate }}
        </span>

  <!--New Password Field-->
        <PasswordEntry *ngIf="!isNewItem$"
                       Label="{{ 'New Password' | translate }}"
                       formControlName="newPassword">
        </PasswordEntry>

        <!--Confirm Password Field-->
        <!--<PasswordEntry *ngIf="!isNewItem$"
                 [Label]="'Confirm Password'"
                 formControlName="confirmNewPassword">
  </PasswordEntry>-->
        <PasswordEntry Label="{{ 'Confirm Password' | translate }}"
                       formControlName="confirmNewPassword">
        </PasswordEntry>

      </div>
    </mat-tab>




  </mat-tab-group>



  <div class="form-group" *ngIf="!MyAccount">
    <button [disabled]="form.dirty === false" type="submit" class="btntest btn btn-default float-left">{{ 'Save' | translate }}</button>
    <button *ngIf="!isNewItem$" (click)="delete(item$.usKey, item$.usUserName)" class="btntest btn btn-default float-left">{{ 'Delete' | translate }}</button>
  </div>

  <div class="form-group" *ngIf="MyAccount">
    <button [disabled]="form.dirty === false" type="submit" class="btntest btn btn-default float-left">{{ 'Save' | translate }}</button>
  </div>
</form>
