import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlComponentBase } from '../../core/control-component-base';


export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => DurationTextEntryComponent),
};

@Component({
  selector: 'DurationTextEntry',
  templateUrl: './duration-text-entry.component.html',
  providers: [CONTROL_VALUE_ACCESSOR]
})



export class DurationTextEntryComponent implements ControlValueAccessor, OnInit {
  @Input() Label: string;
  @Input() IsDisabled: boolean;

  constructor() {
    //super();
  }

  ngOnInit(): void {
    
  }







  duration: string = '';

  private onChange: (duration: string) => void = () => { };
  private onTouched: () => void = () => { };

  onKeyPress(event: KeyboardEvent): void {
    const charCode = event.charCode;
    if ((charCode < 48 || charCode > 57) && charCode !== 58) { // Allow digits and colon
      event.preventDefault();
    }
  }

  onInput(event: any): void {
    let input = event.target.value;

    // Ensure the colon is present
    if (input.indexOf(':') === -1) {
      input += ':';
    }

    // Limit to 5 characters
    input = input.slice(0, 5);

    // Update duration
    this.duration = input;

    // Emit value change
    this.onChange(this.duration);
  }

  onBlur(): void {
    // Ensure duration is formatted correctly on blur
    this.duration = this.formatDuration(this.duration);
    this.onTouched();
    this.onChange(this.duration);
  }

  onFocus(): void {
    // Select all text when focus is set to the input
    const inputElement = document.getElementById('durationInput') as HTMLInputElement;
    inputElement.select();
  }

  writeValue(duration: string): void {
    // Ensure duration is formatted correctly when initialized
    this.duration = this.formatDuration(duration);
  }

  registerOnChange(fn: (duration: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if necessary, to handle the disabled state
  }

  private formatDuration(duration: string): string {
    // Ensure duration is in 'hh:mm' format
    const parts = duration.split(':');
    let hours = parseInt(parts[0]) || 0;
    let minutes = parseInt(parts[1]) || 0;

    // Ensure hours and minutes are within range
    hours = Math.min(hours, 23);
    minutes = Math.min(minutes, 59);

    // Format hours and minutes with leading zeros
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return formatted duration
    return `${formattedHours}:${formattedMinutes}`;
  }


  // Add this method to focus the input element
  setFocus(): void {
    const inputElement = document.getElementById('durationInput') as HTMLInputElement;
    inputElement.focus();
  }





  //duration: string = '';

  //// Function to call when the duration changes.
  //onChange = (duration: string) => { };

  //// Function to call when the input is touched.
  //onTouched = () => { };

  //// Handle keypress to ensure only numeric input
  //onKeyPress(event: KeyboardEvent): void {
  //  const charCode = event.charCode;
  //  if (charCode < 48 || charCode > 57) {
  //    event.preventDefault();
  //  }
  //}

  //// Handles user input
  //onInput(event: any): void {
  //  let input = event.target.value;

  //  // Remove all non-digit characters
  //  input = input.replace(/\D/g, '');

  //  // Format the input to h:mm
  //  if (input.length >= 2) {
  //    input = input.slice(0, input.length - 2) + ':' + input.slice(input.length - 2);
  //  }

  //  // Update the duration value
  //  this.duration = input;

  //  // Call the change function
  //  this.onChange(this.duration);

  //  console.log(this.duration); // Log the value emitted
  //}

  //// Handles blur event to finalize the value
  //onBlur(): void {
  //  // Ensure at least "h:mm" format
  //  if (this.duration.length > 0 && this.duration.length <= 2) {
  //    this.duration = this.duration.padStart(3, '0') + ':00';
  //  } else if (this.duration.length === 4) {
  //    this.duration = '0' + this.duration;
  //  }

  //  // Call the touched function
  //  this.onTouched();
  //}


  //// Implement writeValue to update the model with an initial value
  //writeValue(duration: string): void {
  //  this.duration = duration || '';
  //}

  //// Implement registerOnChange to store the change function
  //registerOnChange(fn: (duration: string) => void): void {
  //  this.onChange = fn;
  //}

  //// Implement registerOnTouched to store the touched function
  //registerOnTouched(fn: () => void): void {
  //  this.onTouched = fn;
  //}














}
