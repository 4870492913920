/* src/app/FW/components/reports/report-detail/base/report-header/report-header.component.scss */
.btn-default {
  color: #000000;
  background-color: #f2f2f2;
  border-color: #e8e8e8;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 10px;
  text-transform: none;
  font-size: 16px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
.btn3d.btn-default {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 2px rgba(255, 255, 255, 0.1) inset,
    0 8px 0 0 #BEBEBE,
    0 8px 8px 1px rgba(0, 0, 0, 0.2);
  background-color: #f2f2f2;
}
.btn3d.btn-default:active,
.btn3d.btn-default.active {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}
.btn3d:active,
.btn3d.active {
  top: 2px;
}
.btntest {
  background-image: linear-gradient(#ffffff, #bdbdbd);
}
.btntest:hover {
  background-image: linear-gradient(#bdbdbd, #ffffff);
}
.btntest:active {
  background-image: linear-gradient(#636363, #ffffff);
}
.ReportDialogBackground {
  background-color: #ffffff;
}
table tr:nth-child(odd) td {
  background-color: #F5F5F5;
}
table tr:nth-child(even) td {
  background-color: #fcfcfc;
}
.ReportDialogClass {
}
.ReportDialogClass mat-dialog-container {
  background-color: #ffffff;
}
.title {
  width: 40%;
  float: left;
}
.left {
  width: 60%;
  float: left;
}
.leftbuttons {
  width: 100%;
  float: left;
}
.right {
  padding: 5px;
  float: right;
}
table {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 500px;
  background-color: white;
  margin-top: 15px;
}
::ng-deep th.mat-mdc-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
tr.mat-mdc-header-row {
  height: 30px !important;
}
th.mat-mdc-header-cell {
  text-align: left;
  max-width: 300px;
  height: 30px !important;
}
.mat-mdc-header-cell {
  color: black;
  font: bold 14px/20px Roboto;
  letter-spacing: 0.0179em;
  height: 30px !important;
}
.example-container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  min-width: 300px;
}
.mat-mdc-table {
  overflow: auto;
  max-height: 500px;
}
/*# sourceMappingURL=report-header.component.css.map */
