import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../models/security/user';
import { MenuService } from '../menu/menu.service';
import { LoginService } from '../../../services/security/login/login.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  user$: User;
  isUserLoggedIn$: Observable<boolean>;   


  constructor(
    public menuService: MenuService,
    private router: Router,
    private loginService: LoginService) { }

  ngOnInit() {

    this.loginService.getIsUserLoggedIn().subscribe(isUserLoggedIn => {
      this.isUserLoggedIn$ = isUserLoggedIn;
    });

    this.loginService.getUser().subscribe(user => {
      this.user$ = user;
    });

    //this.user$ = this.loginService.user$; // {2}
    //this.isUserLoggedIn$ = this.loginService.userLoggedIn; // {2}
  }



  doLogout() {
    this.loginService.setisUserLoggedInOnly(false);
    localStorage.clear();
    this.router.navigate(['/']);

    setTimeout(() => {
      location.reload();
    },
      100);

    
  }


}
