/* src/app/FW/components/UI/top-nav/top-nav.component.scss */
.myaccount-spacer {
  flex: 1 1 auto;
}
.menuActiveUser {
  font-size: 15px;
  display: flex;
}
.menuActiveUser:hover {
  color: #e7e7e7;
  text-shadow: 1px 1px 2px black;
}
.logoutButton {
  text-align: center;
}
.logoutButton:hover {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
::ng-deep .LogoutMenu {
  background: white;
}
mat-icon.mat-icon.notranslate.mat-mdc-menu-trigger.material-icons.mat-icon-no-color {
  cursor: pointer;
}
.btn-default {
  color: #000000;
  background-color: #f2f2f2;
  border-color: #e8e8e8;
  height: 30px;
  text-align: center;
  line-height: 14px;
  text-transform: none;
  font-size: 16px;
}
/*# sourceMappingURL=top-nav.component.css.map */
