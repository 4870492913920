<div *ngIf="isItemSelected$; then thenBlock else elseBlock"></div>

<!--LIST-->
<ng-template #thenBlock>
  <div class="list-column">
    <UserList></UserList>
  </div>
</ng-template>

<ng-template #elseBlock>
  <div class="list-column-unselected">
    <UserList></UserList>
  </div>
</ng-template>

<!--DETAIL-->
<div class="detail-column" *ngIf="isItemSelected$">
  <UserDetail [MyAccount]="false"
              [TestReload]="Test"></UserDetail>
</div>
