/* src/app/FW/components/reports/report-view/report-view.component.scss */
.list-column {
  width: 40%;
  height: 100vh;
  padding: 10px 15px 0 15px;
  float: left;
  border-right: 1px solid grey;
}
.list-column-unselected {
  width: 100%;
  height: 100vh;
  padding: 10px 15px 0 15px;
  float: left;
  border-right: 1px solid grey;
}
/*# sourceMappingURL=report-view.component.css.map */
