/* src/app/FW/components/reports/report-detail/report-detail.component.scss */
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #3f86e8;
}
.mat-tree-node {
  min-height: 1.5em !important;
  height: 1.5em;
}
.example-tree-invisible {
  display: none;
}
.example-tree ul,
.example-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.mat-tab-body-content {
  overflow: hidden;
}
:host ::ng-deep .mat-tab-label .mat-tab-label-content {
  margin-left: 10px;
  margin-right: 10px;
}
:host ::ng-deep .mat-tab-label {
  border-width: 1px;
  background: #ffffff;
  color: #000000;
  min-width: 20px;
  max-height: 30px;
  opacity: 1;
  border-radius: 7px 7px 0px 0px;
}
:host ::ng-deep .mat-ink-bar {
  border-width: 1px;
  border-style: solid;
  background: #e8e8e8;
  color: #e8e8e8;
  width: 20px;
  max-height: 30px;
  opacity: 1;
}
:host ::ng-deep .mat-tab-label-active {
  background: #e8e8e8;
  border-radius: 7px 7px 0px 0px;
  color: #000000;
  opacity: 1;
}
::ng-deep .mat-tab-label,
::ng-deep .mat-tab-label-active {
  min-width: 0 !important;
  padding: 1px !important;
  margin-right: 3px !important;
  margin-bottom: -1px !important;
}
.btn-default {
  color: #000000;
  background-color: #f2f2f2;
  border-color: #e8e8e8;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 10px;
  text-transform: none;
  font-size: 16px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
.btn3d.btn-default {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 2px rgba(255, 255, 255, 0.1) inset,
    0 8px 0 0 #BEBEBE,
    0 8px 8px 1px rgba(0, 0, 0, 0.2);
  background-color: #f2f2f2;
}
.btn3d.btn-default:active,
.btn3d.btn-default.active {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}
.btn3d:active,
.btn3d.active {
  top: 2px;
}
.btntest {
  background-image: linear-gradient(#ffffff, #bdbdbd);
}
.btntest:hover {
  background-image: linear-gradient(#bdbdbd, #ffffff);
}
.btntest:active {
  background-image: linear-gradient(#636363, #ffffff);
}
.label {
  margin-top: 20px;
  font-size: 20px;
}
hr.solid {
  border-top: 2px solid #214980;
  margin-top: -5px;
}
.formcontrol {
  border: 1px solid silver;
  border-radius: 5px;
  width: 215px;
}
input:focus {
  border: 1px solid black;
  border-radius: 5px;
  width: 215px;
  outline-width: 0;
}
.tab-content {
  background-color: #e8e8e8;
  opacity: 1;
  overflow: hidden;
  padding: 5px 5px 5px 5px;
  box-sizing: border-box;
}
.left {
  width: 70%;
  float: left;
}
.right {
  width: 30%;
  float: right;
}
/*# sourceMappingURL=report-detail.component.css.map */
