<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">
    <input class="formcontrol"
           style="width:50px"
           [value]="value"
           (blur)="onTouched()"
           (input)="onChange($event.target.value)"
           maxlength="3"
           type="text"
           id="Input"
           [disabled]="IsDisabled">
  </div>
</div>
