/* src/app/FW/components/security/login/login.component.scss */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  height: 100vh;
  font-family: "Roboto";
  background: linear-gradient(#fff, #214980);
  display: flex;
}
#form_wrapper {
  width: 1000px;
  height: 500px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5vw;
  padding: 5vh 15px;
}
#form_left {
  display: grid;
  justify-content: center;
  align-items: center;
  float: left;
  margin-top: -140px;
}
#form_left img {
  width: 400px;
  height: auto;
  align-content: center;
}
#form_left h1 {
  margin-top: -120px;
}
#form_left p {
  font-size: 10px;
  margin-bottom: -155px;
}
#form_right {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 10% 5%;
}
.input_container {
  background-color: #E5E5E5;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.input_container:hover {
  background-color: #eaeaea;
}
.input_container,
#input_submit {
  height: 60px;
  border-radius: 30px;
  width: 100%;
}
.input_field {
  color: #6b6b6b;
  background-color: inherit;
  width: 90%;
  border: none;
  font-size: 1.3rem;
  font-weight: 400;
  padding-left: 30px;
}
.input_field:hover,
.input_field:focus {
  outline: none;
}
#input_submit {
  background-color: #1FCC44;
  padding-left: 0;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
#input_submit:hover {
  background-color: #40e263;
  transition: background-color, 1s;
  cursor: pointer;
}
h1,
span {
  text-align: center;
}
#create_account {
  display: block;
  position: relative;
  top: 30px;
}
a {
  text-decoration: none;
  color: #1FCC44;
  font-weight: bold;
}
a:hover {
  color: #40e263;
}
i {
  color: #6b6b6b;
}
@media screen and (max-width: 768px) {
  #form_wrapper {
    grid-template-columns: 1fr;
    margin-left: 10px;
    margin-right: 10px;
  }
  #form_left {
    display: none;
  }
}
/*# sourceMappingURL=login.component.css.map */
