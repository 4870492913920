import { Component, OnInit, AfterViewInit } from '@angular/core';
import { User } from "../../../../models/security/user";
import { UserService } from "../../../../services/security/users/user.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ListComponentBase } from '../../../../core/list-component-base';

@Component({
  selector: 'UserList',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent extends ListComponentBase implements OnInit, AfterViewInit {

  constructor(private userService: UserService,
    private ngxService2: NgxUiLoaderService) {
    super(ngxService2);

    this.model = User;
    this.itemService = userService;
    this.listColumns = ['usUserName', 'usLastName', 'usFirstName', 'usCode'];

    this.sortBy = 'usUserName';
    this.sortDirection = 'asc';
  }

  ngOnInit() {
    super.ngOnInit();

    //this.ngxService.start("loader-01"); // start foreground spinner of the master loader with 'default' taskId
    //// Stop the foreground loading after 5s
    //setTimeout(() => {
    //  this.ngxService.stop("loader-01"); // stop foreground spinner of the master loader with 'default' taskId
    //}, 1000);
  }

}
