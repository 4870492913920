import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlComponentBase } from '../../core/control-component-base';


export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => NumericEntryComponent),
};

@Component({
  selector: 'NumericEntry',
  templateUrl: './numeric-entry.component.html',
  providers: [CONTROL_VALUE_ACCESSOR]
})
export class NumericEntryComponent extends ControlComponentBase implements ControlValueAccessor, OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
