import { ComponentType } from '@angular/cdk/portal';
import { UsersReportComponent } from './security/users-report/users-report.component';
import { UsersPerRoleReportComponent } from './security/users-per-role-report/users-per-role-report.component';
import { RolesReportComponent } from './security/roles-report/roles-report.component';
import { UserPasswordExpirationDatesReportComponent } from './security/users-password-expiration-dates-report/users-password-expiration-dates-report.component';
import { AuditChangesReportComponent } from './audit/audit-changes-report/audit-changes-report.component';
import { AuditActionsReportComponent } from './audit/audit-actions-report/audit-actions-report.component';
import { AccessSettingsReportComponent } from './security/access-settings-report/access-settings-report.component';

export class ReportViewComponentRegistry {
  response: ComponentType<any>;

  GetTypeOf(ReportBusinessClass: string): ComponentType<any> {

    switch (ReportBusinessClass) {
      //Users report
      case 'GetUsersReport': {
        this.response = UsersReportComponent;
        break;
      }
        //Users per role report
      case 'GetUsersPerRoleReport': {
        this.response = UsersPerRoleReportComponent;
        break;
      }
        //Roles report
      case 'GetRolesReport': {
        this.response = RolesReportComponent;
        break;
      }
        //Users password expiration dates report
      case 'GetUserPasswordExpirationDatesReport': {
        this.response = UserPasswordExpirationDatesReportComponent;
        break;
      }


      //Audit Changes report
      case 'GetAuditChangesReport': {
        this.response = AuditChangesReportComponent;
        break;
      }

      //Audit Actions report
      case 'GetAuditActionsReport': {
        this.response = AuditActionsReportComponent;
        break;
      }

      //Access settings report
      case 'GetAccessSettingsReport': {
        this.response = AccessSettingsReportComponent;
        break;
      }

    }

    return this.response;
  }

}
