import { Component, OnInit, ViewChild } from '@angular/core';
import { DetailComponentBase } from '../../../core/detail-component-base';
import { LoginService } from '../../../services/security/login/login.service';
import { AuditActionService } from '../../../services/audit/audit-action/audit-action.service';
import { HolidayService } from '../../../services/system/holiday/holiday.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComboBoxItem } from '../../../controls/combobox/combobox.component';
import { TranslateService } from '@ngx-translate/core';
import { Holiday } from '../../../models/system/holiday';
import { Guid } from 'guid-typescript';
import { MatDatepickerModule, MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent extends DetailComponentBase implements OnInit {
  dateFormats: ComboBoxItem[] = [];
  selectedDate: any;
  holidays: any;
  datesToHighlight: string[] = [];
  selectedHolidayName: string;
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  //datesToHighlight = [
  //  '2023-08-02T18:30:00.000Z',
  //  '2023-08-22T18:30:00.000Z',
  //  '2023-08-17T18:30:00.000Z',
  //  '2023-08-28T18:30:00.000Z',
  //  '2023-08-24T18:30:00.000Z',
  //  '2023-08-30T18:30:00.000Z',
  //  '2023-08-22T18:30:00.000Z',
  //  '2023-08-25T18:30:00.000Z',
  //];



  constructor(loginService: LoginService,
    auditActionService: AuditActionService,
    public holidayService: HolidayService,
    public formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar) {
    super(snackBar, loginService, auditActionService);

    this.itemService = holidayService;
    this.actionType = 'Edit';

    this.form = new UntypedFormGroup({
      DateFormat: new UntypedFormControl('')
    });    

  }

  ngOnInit(): void {
    this.holidayService.getHolidays()
      .subscribe((data) => {
        this.holidays = data;

        this.holidays.forEach((h) => {
          this.datesToHighlight.push(h.hoDate.toString());


        });

      });

    this.calendar.dateClass = this.dateClass();
   
  }

  onSelect(event) {
    this.selectedDate = event.toDateString();
    console.log(this.selectedDate);
    console.log(event.toDateString());
    var isHoliday = this.holidays.find(h => new Date(h.hoDate).toDateString() === this.selectedDate);
    if (isHoliday != undefined) {
      this.selectedHolidayName = this.holidays.find(h => new Date(h.hoDate).toDateString() === this.selectedDate).hoName;
    } else {
      this.selectedHolidayName = '';
    }
    
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.datesToHighlight
        .map((strDate) => new Date(strDate))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );

      return highlightDate ? 'holiday-date' : '';
    };
  }


  save() {
    let holiday = new Holiday();
    var nowDate = Date.now();


    var activeUser = '';

    this.loginService.getUser().subscribe(user => {
      activeUser = user.usKey;
    });


    //holiday.gsKey = Guid.create().toString();
    //holiday.gsKey = '49318930-9b62-4d67-a7b5-e00d26533070';
    //holiday.gsName = 'DateFormat';
    //holiday.gsContent = this.form.controls.DateFormat.value;
    /*    holiday.gsContent = 'works';*/

    //holiday.createTime = this.item$.createTime;
    //holiday.createUserId = this.item$.createUserId;
    holiday.createTime = new Date(nowDate);
    holiday.createUserId = '00000000-0000-0000-0000-000000000000';
    holiday.changeTime = new Date(nowDate);
    holiday.changeUserId = activeUser;
    holiday.deleteTime = null;
    holiday.deleteUserId = '00000000-0000-0000-0000-000000000000';
    holiday.delFlag = 0;


    //holiday.changeTime = new Date(nowDate);
    //holiday.changeUserId = activeUser;


    //super.save(holiday, holiday.gsKey);


  }

}
