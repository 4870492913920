import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { ReportParameterComponentBase } from '../../../../FW/core/report-parameter-component-base';
import { ComboBoxItem } from '../../../../FW/controls/combobox/combobox.component';
import { RoleService } from '../../../../FW/services/security/roles/role.service';
import { ReportService } from '../../../../FW/services/reports/report.service';

@Component({
  selector: 'UserPasswordExpirationDatesParametersComponent',
  templateUrl: './users-password-expiration-dates-parameters.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None // Add this line
})

export class UserPasswordExpirationDatesParametersComponent extends ReportParameterComponentBase implements OnInit {
  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};

  selectedItems = [];

  roles: ComboBoxItem[];
  public selectedRole = new Subject<string>();

  constructor(private roleService: RoleService,
    public reportService: ReportService,
    private cookieService: CookieService) {
    super(reportService);

    //Create parameter group
    this.parameters = new UntypedFormGroup({
      role: new UntypedFormControl('', [Validators.required]),
      dateRange: new UntypedFormControl('', [Validators.required])
    });

  }


  ngOnInit() {
    super.ngOnInit();

    //LOAD ROLES
    this.loadRoles();
    this.reportService.setShowRoleFilter(true);
    this.reportService.setShowDateRangeFilter(true);

    this.parameters.get("dateRange").valueChanges.subscribe(x => {
      console.log(x);

      const pipe = new DatePipe('en-US');
      let start = x.StartDate;
      let end = x.EndDate;

      let startDate = pipe.transform(start, 'dd-MM-yyyy')
      let endDate = pipe.transform(end, 'dd-MM-yyyy')

      this.reportService.setDateRangeFilterValues(startDate + ' to ' + endDate);
    });

    this.parameters.get("role").valueChanges.subscribe(selectedValues => {

      if (selectedValues.length > 0) {
        this.cookieService.set("UsersPasswordExpirationDates,Role", JSON.stringify(selectedValues));
      }

   

      var selectedRoles = [];

      selectedValues.length;
      this.roles.length;

      if (selectedValues.length === this.roles.length) {
        selectedRoles.push('All');
      } else {
        selectedValues.forEach((sr) => {
          var role = this.roles.find(u => u.value === sr)


          if (role != undefined) {
            var roleName = this.roles.find(r => r.value === sr).name;

            if (roleName != undefined) {
              selectedRoles.push(this.roles.find(r => r.value === sr).name);
            }
          }



        });

        if (this.roles.length === selectedRoles.length) {
          //selectedUsers.push('All');
          this.parameters.controls.role.setValue(selectedRoles);
        }

      }
      this.reportService.setRoleFilterValues(selectedRoles);
  
    })

    this.parameters.get("dateRange").valueChanges.subscribe(selectedValues => {
      console.log(selectedValues);
    })


    //this.parameters.valueChanges.subscribe(x => {
    //  console.log('form value changed')
    //  console.log(x)
    //})
  }

  loadRoles() {
    this.roles = [];

    this.roleService.getList()
      .subscribe(result => {

        var roleList = [];
        result.forEach((r) => {
          var role = new ComboBoxItem(r.roKey, r.roName)
          roleList.push(role);

          //this.roles = [this.roles, ];
        });

        this.roles = roleList;

        //// SELECT ALL ON LOAD
        var selectAllByDefault = this.roles.map(x => x.value);
        //this.parameters.controls.role.setValue(test);

        var lastUsedParameters = JSON.parse(this.cookieService.get("UsersPasswordExpirationDates,Role"));

       

        if (lastUsedParameters != '') {
          this.parameters.controls.role.setValue(lastUsedParameters);
        } else {
          this.parameters.controls.role.setValue(selectAllByDefault);
        }


   

      });
  }
}
