<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">
    <input id="durationInput"
           class="formcontrol"
           type="text"
           [value]="duration"
           (keypress)="onKeyPress($event)"
           (input)="onInput($event)"
           (blur)="onBlur()"
           (focus)="onFocus()"
           maxlength="5"
           placeholder="hh:mm"
           pattern="\d{1,2}:\d{2}" />
  </div>
</div>
