/* src/app/FW/components/security/switch-user/switch-user.component.scss */
.btn-default {
  color: #000000;
  background-color: #f2f2f2;
  border-color: #e8e8e8;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 10px;
  text-transform: none;
  font-size: 16px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
.btn3d.btn-default {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 2px rgba(255, 255, 255, 0.1) inset,
    0 8px 0 0 #BEBEBE,
    0 8px 8px 1px rgba(0, 0, 0, 0.2);
  background-color: #f2f2f2;
}
.btn3d.btn-default:active,
.btn3d.btn-default.active {
  color: #666666;
  box-shadow:
    0 0 0 1px #ebebeb inset,
    0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}
.btn3d:active,
.btn3d.active {
  top: 2px;
}
.btntest {
  background-image: linear-gradient(#ffffff, #bdbdbd);
}
.btntest:hover {
  background-image: linear-gradient(#bdbdbd, #ffffff);
}
.btntest:active {
  background-image: linear-gradient(#636363, #ffffff);
}
.ReportDialogClass {
}
.ReportDialogClass mat-dialog-container {
  background-color: #ffffff;
}
.ReportDialogBackground {
  background-color: #ffffff;
}
/*# sourceMappingURL=switch-user.component.css.map */
