<h1>{{ reportGroup | translate }} {{ 'Reports' | translate }}</h1>
<p *ngIf="!items$"><em>Loading...</em></p>

<form>
  <div class="input_container">
    <i class="fas fa-envelope"></i>

    <input placeholder="{{ 'Search' | translate }}..."
           size="50"
           type="search"
           class='formcontrol'
           (keyup)="doFilter($event.target.value)">
  </div>

  <table mat-table [dataSource]="listDataSource" matSort>
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns; let i = index"
        (click)="setSelectedItem(row)"
        class="table-row"
        tabindex="1"></tr>
  </table>

</form>
