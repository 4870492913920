import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_TAB_GROUP } from '@angular/material/tabs';
import { ComboBoxItem } from '../combobox/combobox.component';
import { Subject } from 'rxjs';
import { RoleService } from '../../services/security/roles/role.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


const TREE_DATA = [
  {
    id: '0',
    name: 'Parent 1',
    children: [
      {
        id: 1,
        name: 'Child 1'
      },
      {
        id: 2,
        name: 'Child 2'
      },
      {
        id: 3,
        name: 'Child 3'
      },
      {
        id: 4,
        name: 'Child 4'
      }
    ]
  },
  {
    id: '0',
    name: 'Parent 2',
    children: [
      {
        id: 1,
        name: 'Child 1'
      },
      {
        id: 2,
        name: 'Child 2'
      },
      {
        id: 3,
        name: 'Child 3'
      },
      {
        id: 4,
        name: 'Child 4'
      }
    ]
  }
]


@Component({
  selector: 'app-control',
  templateUrl: './control-view.component.html',
  styleUrls: ['./control-view.component.scss'],
  providers: [
    {
      provide: MAT_TAB_GROUP,
      useValue: undefined,
    },
  ]
})

export class ControlViewComponent implements OnInit {
  selectedItems = [];
  roles: ComboBoxItem[];
  public selectedRole = new Subject<string>();


  form: UntypedFormGroup;

  formTextEntry: string;
  formCodeSearchBoxEntry: string;
  formNumericEntry: string;
  formPasswordEntry: string;
  formDateEntry: string;
  formDateTimeEntry: string;
  formComboBoxEntry: string;
  formCheckBox: string;
  formToggleButton: string;
  formRadioButton: string;
  formCodeEntry: string;
  formSpinner: string;

  GetRadioButton: string;

  RadioButtonItems: string[] = ['Option 1', 'Option 2', 'Option 3'];

  DATA = TREE_DATA;

  label1 = "Test 1";
  label2 = "Test 2";
  label3 = "Test 3";



  constructor(private formBuilder: UntypedFormBuilder,
    private roleService: RoleService,
    private ngxService: NgxUiLoaderService)
  {
    this.formTextEntry = 'TextEntry';
    this.formCodeSearchBoxEntry = 'CodeSearchBox';
    this.formNumericEntry = 'NumericEntry';
    this.formPasswordEntry = 'PasswordEntry';
    this.formDateEntry = 'DateEntry';
    this.formDateTimeEntry = 'DateTimeEntry';
    this.formComboBoxEntry = 'ComboBoxEntry';
    this.formCheckBox = 'CheckBox';
    this.formToggleButton = 'ToggleButton';
    this.formRadioButton = 'RadioButton';
    this.formCodeEntry = 'CodeEntry';
    this.formSpinner = 'Spinner';

    this.loadRoles();

    //this.ngxService.start("loader-01");


    this.form = this.formBuilder.group(
      {
        TextEntry: [''],
        CodeSearchBox: [''],
        NumericEntry: [''],
        PasswordEntry: [''],
        DateEntry: [''],
        DateTimeEntry: [''],
        ComboBoxEntry: [''],
        CheckBox: [''],
        ToggleButton: [''],
        RadioButton: [''],
        CodeEntry: [''],
        Spinner: [''],
      }
    )

  }

  loadRoles() {
    this.roles = [];

    this.roleService.getList()
      .subscribe(result => {

        var roleList = [];
        result.forEach((r) => {
          var role = new ComboBoxItem(r.roKey, r.roName)
          roleList.push(role);

          //this.roles = [this.roles, ];
        });

        this.roles = roleList;

        //// SELECT ALL ON LOAD
        var selectAllByDefault = this.roles.map(x => x.value);
        //this.parameters.controls.role.setValue(test);

      

        //if (lastUsedParameters != '') {
        //  this.parameters.controls.role.setValue(lastUsedParameters);
        //} else {
        //  this.parameters.controls.role.setValue(selectAllByDefault);
        //}




      });
  }

  ngOnInit(): void {
   
  }

}
