import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Form } from '@angular/forms';
import { BaseService } from '../../../FW/core/base.service';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { StoresList } from '../../models/forms/storeList';
import { Country } from '../../models/forms/country';

//interface SubscriptionData {
//  email: string;
//  firstName: string;
//  lastName: string;
//  dateOfBirth: string;
//  store: string;
//  country: string;
//}

@Injectable({
  providedIn: 'root'
})
export class FormService extends BaseService<Form> {

  //
  //private apiUrl = 'https://rectangular-gainful-seagull.glitch.me/api/subscribe'

  

 

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Forms/';
  }

  // Error handling method
  errorHandler(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Client-side error - Contact IT Department.`;
    } else {
      // Server-side error
      errorMessage = `Server-side error - Contact IT Department.`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  //GET LIST WITH NUMBER IN NAME
  getStoresList(): Observable<StoresList> {
    return this.http.get<StoresList>(this.myAppUrl + this.myApiUrl + 'GetStoresList/')
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  //GET LIST WITH NUMBER IN NAME
  getCountriesList(): Observable<Country[]> {
    return this.http.get<Country[]>(this.myAppUrl + this.myApiUrl + 'GetCountriesList/')
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  //POST SUBSCRIBE USER
  subscribeUser(form): Observable<Form> {
    return this.http.post<Form>(this.myAppUrl + this.myApiUrl + 'SubscribeUser/' + JSON.stringify(form), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  //subscribeUser(data: SubscriptionData): Observable<any> {
  // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //  return this.http.post(this.apiUrl, data, { headers });
  //}
}
