import { UntypedFormGroup } from '@angular/forms';
import { ReportService } from '../services/reports/report.service';

export class ReportParameterComponentBase {
  parameters: UntypedFormGroup;


  constructor(public reportService: ReportService) {
  }

  ngOnInit() {
    //Subscribe to parameter group
    this.parameters.valueChanges.subscribe(data => {
      //Set selected parameters in Report Service
      this.reportService.setReportParameters(this.parameters);

    });

  }
}
