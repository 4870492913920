import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { ReportParameterComponentBase } from '../../../../FW/core/report-parameter-component-base';
import { ComboBoxItem } from '../../../../FW/controls/combobox/combobox.component';
import { RoleService } from '../../../../FW/services/security/roles/role.service';
import { ReportService } from '../../../../FW/services/reports/report.service';

@Component({
  selector: 'AccessSettingsParametersComponent',
  templateUrl: './access-settings-parameters.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None // Add this line
})

export class AccessSettingsParametersComponent extends ReportParameterComponentBase implements OnInit {
  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};

  selectedItems = [];

  roles: ComboBoxItem[];
  public selectedRole = new Subject<string>();

  constructor(private roleService: RoleService,
    public reportService: ReportService,
    private cookieService: CookieService) {
    super(reportService);

    //Create parameter group
    this.parameters = new UntypedFormGroup({
      role: new UntypedFormControl('')
    });

  }


  ngOnInit() {
    super.ngOnInit();

    //LOAD ROLES
    this.loadRoles();
    this.reportService.setShowRoleFilter(true);
    this.reportService.setShowDateRangeFilter(false);



    this.parameters.get("role").valueChanges.subscribe(selectedValues => {

      if (selectedValues != undefined) {



        if (selectedValues.length > 0) {
          this.cookieService.set("AccessSettings,Role", JSON.stringify(selectedValues));
        }


        var selectedRoles = [];

        selectedValues.length;
        this.roles.length;


        var role = this.roles.find(u => u.value === selectedValues)

        if (role != undefined) {
          var roleName = this.roles.find(r => r.value === selectedValues).name;

          if (roleName != undefined) {
            selectedRoles.push(this.roles.find(r => r.value === selectedValues).name);
          }
        }

        this.reportService.setRoleFilterValues(selectedRoles);

      }
    })




    //this.parameters.valueChanges.subscribe(x => {
    //  console.log('form value changed')
    //  console.log(x)
    //})
  }

  loadRoles() {
    this.roles = [];

    this.roleService.getList()
      .subscribe(result => {

        var roleList = [];
        result.forEach((r) => {
          var role = new ComboBoxItem(r.roKey, r.roName)
          roleList.push(role);

          //this.roles = [this.roles, ];
        });

        this.roles = roleList;

        //// SELECT ALL ON LOAD
        var selectAllByDefault = this.roles.map(x => x.value);
        //this.parameters.controls.role.setValue(test);

        var lastUsedParameters = JSON.parse(this.cookieService.get("AccessSettings,Role"));



        if (lastUsedParameters != '') {
          this.parameters.controls.role.setValue(lastUsedParameters);
        } else {
          this.parameters.controls.role.setValue(selectAllByDefault);
        }




      });
  }
}
