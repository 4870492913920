<div *ngIf="isItemSelected$; then thenBlock else elseBlock"></div>

<!--LIST-->
<ng-template #thenBlock>
  <div class="list-column">
    <report-list></report-list>
  </div>
</ng-template>

<ng-template #elseBlock>
  <div class="list-column-unselected">
    <report-list></report-list>
  </div>
</ng-template>

<!--DETAIL-->
<div class="detail-column" *ngIf="isItemSelected$">
  <report-detail></report-detail>
</div>
