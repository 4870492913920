<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
   (click)="onItemSelected(item); ClickedRow(i)"
   [class.active]="i == HighlightRow"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
   class="menu-list-item">


  <div class="left-column">

    <span fxFlex *ngIf="item.children && item.children.length">
      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </span>

  </div>

  <div class="right-column">


    <span style="margin-right: 20px;"><mat-icon class="routeIcon" svgIcon="{{item.svgIconName}}">{{item.iconName}}</mat-icon></span>
    <span style="width: 120px;">{{item.displayName | translate}}</span>


  </div>

</a>

<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
