import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RoleService } from '../../../../services/security/roles/role.service';
import { Role } from '../../../../models/security/role';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ListComponentBase } from '../../../../core/list-component-base';

@Component({
  selector: 'RoleList',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent extends ListComponentBase implements OnInit, AfterViewInit {

  constructor(private roleService: RoleService,
    private ngxService2: NgxUiLoaderService) {
    super(ngxService2);

    this.model = Role;
    this.itemService = roleService;
    this.listColumns = ['roCode', 'roName', 'roDescription'];

    this.sortBy = 'roName';
    this.sortDirection = 'asc';
  }

  ngOnInit() {
    super.ngOnInit();
  }

}

