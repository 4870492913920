import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { WTimeComponent } from '../controls/timepicker-entry/w-time/w-time.component';
import { WClockComponent } from '../controls/timepicker-entry/w-clock/w-clock.component';
import { TimePickerComponent } from '../controls/timepicker-entry/timepicker-component/timepicker.component';
import { WTimeDialogComponent } from '../controls/timepicker-entry/w-time-dialog/w-time-dialog.component';
import { DurationWTimeComponent } from '../controls/durationpicker-entry/durationw-time/durationw-time.component';
import { DurationWClockComponent } from '../controls/durationpicker-entry/durationw-clock/durationw-clock.component';
import { DurationPickerComponent } from '../controls/durationpicker-entry/durationpicker-component/durationpicker.component';
import { DurationWTimeDialogComponent } from '../controls/durationpicker-entry/durationw-time-dialog/durationw-time-dialog.component';


@NgModule({
    declarations: [
        TimePickerComponent,
        WTimeDialogComponent,
        WClockComponent,
        WTimeComponent,
        DurationWTimeComponent,
        DurationWClockComponent,
        DurationPickerComponent,
        DurationWTimeDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatToolbarModule,
        CommonModule,
        FormsModule,
        FlexLayoutModule,
    ],
    exports: [
        TimePickerComponent,
        WTimeDialogComponent,
        WClockComponent,
        WTimeComponent,
        DurationWTimeComponent,
        DurationWClockComponent,
        DurationPickerComponent,
        DurationWTimeDialogComponent,
    ]
})
export class MaterialTimePickerModule { }
