import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject } from 'rxjs';
import { ViewChild } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export class ListComponentBase {
  @ViewChild(MatSort) sort: MatSort;

  //Properties
  public listDataSource = new MatTableDataSource<any>();

  items$: any[];
  itemService: any;

  model: any;

  listHeaders: string[];

  isNewItem$: boolean;
  refreshList: boolean;

  listColumns: string[];

  sortBy: string;
  sortDirection: SortDirection;

  constructor(private ngxService: NgxUiLoaderService) {
  }


  ngOnInit() {
    this.ngxService.start("loader-01");

    this.loadList();

    //Subscribe to isNewItem
    this.itemService.getIsNewItem().subscribe(isNewItem => {
      this.isNewItem$ = isNewItem;
    });

    //Subscribe to refreshList$
    this.itemService.refreshList$.subscribe(
      refreshList => {
        this.refreshList = refreshList;

        if (this.refreshList === true) {
          this.itemService.item$ = new ReplaySubject(1);
          this.itemService.setIsNewItem(false);

          this.loadList();
        }
      });


  }

  ngAfterViewInit(): void {
    //this.InitializeSorting();
  }

  ngAfterContentInit(): void {
    this.InitializeSorting();
  }

  //List Searchbox filter
  public doFilter = (value: string) => {
    this.listDataSource.filter = value.trim().toLocaleLowerCase();
  }

  //Load list of items
  loadList() {

   // start foreground spinner of the master loader with 'default' taskId


    this.itemService.getList()
      .subscribe(result => {
        this.ngxService.stop("loader-01"); 

        this.items$ = result;


        this.listDataSource.data = this.items$;



        //// Stop the foreground loading after 5s
        //setTimeout(() => {
        //  this.ngxService.stop("loader-01"); // stop foreground spinner of the master loader with 'default' taskId
        //}, 1000);

      })
  }

  //Configure sorting for table
  public InitializeSorting() {
    //this.sort.disableClear = true;
    //this.listDataSource.sort = this.sort;

    //const sortState: Sort = { active: this.sortBy, direction: this.sortDirection };
    //this.sort.active = sortState.active;
    //this.sort.direction = sortState.direction;
    //this.sort.sortChange.emit(sortState);
  }

  //Set selected Item
  public setSelectedItem(selectedItem: any) {

    this.itemService.item$.next(new ReplaySubject(1));
    this.itemService.item === undefined;
    this.itemService.setIsNewItem(false);
    this.itemService.setIsItemSelected(false);


    //Set in timeout to await form reinitialization
    setTimeout(() => {
      this.itemService.setIsItemSelected(false);
      this.itemService.setIsItemSelected(true);
      this.itemService.setItem(selectedItem);
    }, 50);


  }

  //Flag to indicate new item
  public newItem() {

    this.itemService.item$.next(new ReplaySubject(1));
    this.itemService.item === undefined;
    this.itemService.setIsNewItem(false);

    this.itemService.setIsItemSelected(false);


    //Set in timeout to prevent from loading before form was cleared
    setTimeout(() => {
      let itemModel = this.model;
      this.itemService.setItem(itemModel);
      this.itemService.setIsNewItem(true);
      this.itemService.setIsItemSelected(true);
    }, 100);


  }
}





