<ReportHeaderButtons></ReportHeaderButtons>

<div mat-dialog-content>

  <ReportHeader></ReportHeader>


  <table id="dataTable"
         mat-table
         [dataSource]="reportDataSource">



    <!-- Date Column -->
    <ng-container matColumnDef="date">

      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let item"> {{item.date}} </td>
    </ng-container>


    <!-- Code Column -->
    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef> User </th>
      <td mat-cell *matCellDef="let item"> {{item.userId}} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let item"> {{item.action}} </td>
    </ng-container>







    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[20]"
                 showFirstLastButtons
                 (page)="pageEvent = onPageChange($event)">
  </mat-paginator>
</div>
