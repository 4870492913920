import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
//import { NgModule } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from "ngx-ui-loader";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { AngularMaterialModule } from './angular-material.module';
import { MaterialTimePickerModule } from './material-timepicker.module';


//Translations
import { TranslateResxHttpLoader } from '@kneefer/ngx-translate-resx-http-loader';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { LoginComponent } from '../components/security/login/login.component';
import { MainMenuComponent } from '../components/UI/menu/main-menu/main-menu.component';
import { HomeComponent } from '../components/home/home.component';

//SYSTEM
//Global Settings
import { GlobalSettingsComponent } from '../components/system/global-settings/global-settings.component';
//Holiday
import { HolidayComponent } from '../components/system/holiday/holiday.component';


//SECURITY
//Users
import { UserViewComponent } from '../components/security/users/user-view/user-view.component';
import { UserListComponent } from '../components/security/users/user-list/user-list.component';
import { UserDetailComponent } from '../components/security/users/user-detail/user-detail.component';
//Roles
import { RoleViewComponent } from '../components/security//roles/role-view/role-view.component';
import { RoleListComponent } from '../components/security//roles/role-list/role-list.component';
import { RoleDetailComponent } from '../components/security//roles/role-detail/role-detail.component';


//SETTINGS

//CONTROLS
import { ControlViewComponent } from '../controls/_Examples/control-view.component';
//
import { TextEntryComponent } from '../controls/text-entry/text-entry.component';
import { TimeTextEntryComponent } from '../controls/time-text-entry/time-text-entry.component';
import { DurationTextEntryComponent } from '../controls/duration-text-entry/duration-text-entry.component';
//import { EmailEntryComponent } from '../controls/email-entry/email-entry.component';
import { MemoEntryComponent } from '../controls/memo-entry/memo-entry.component';
import { CodeEntryComponent } from '../controls/code-entry/code-entry.component';
import { NumericEntryComponent } from '../controls/numeric-entry/numeric-entry.component';
import { DecimalEntryComponent } from '../controls/decimal-entry/decimal-entry.component';
import { PasswordEntryComponent } from '../controls/password-entry/password-entry.component';
import { DateEntryComponent } from '../controls/date-entry/date-entry.component';
import { DateRangePickerEntryComponent } from '../controls/daterangepicker-entry/daterangepicker-entry.component';
import { ComboboxComponent } from '../controls/combobox/combobox.component';
import { ComboboxEntryComponent } from '../controls/containers/combobox-entry/combobox-entry.component';
import { DateTimeEntryComponent } from '../controls/datetime-entry/datetime-entry.component';
import { RadiobuttonEntryComponent } from '../controls/radiobutton-entry/radiobutton-entry.component';
import { CheckboxEntryComponent } from '../controls/checkbox-entry/checkbox-entry.component';
import { SpinnerComponent } from '../controls/spinner/spinner.component';
import { ToggleEntryComponent } from '../controls/toggle-entry/toggle-entry.component';
import { DropdownEntryComponent } from '../controls/dropdown-entry/dropdown-entry.component';
import { DialogComponent } from '../controls/dialogs/dialog/dialog.component';
import { ConfirmDialogComponent } from '../controls/dialogs/confirm-dialog/confirm-dialog.component';




import { TabComponent } from '../controls/tabs/tab/tab.component';
import { TabsComponent } from '../controls/tabs/tabs/tabs.component';


//SERVICES
import { BaseService } from '../core/base.service';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from '../components/UI/menu/menu.service';
import { UserService } from 'src/app/FW/services/security/users/user.service';
import { RoleService } from '../services/security/roles/role.service';
import { AccessSettingService } from '../services/security/access-setting/access-setting.service';
import { ReportService } from '../services/reports/report.service';

//Reports
import { ReportViewComponent } from '../components/reports/report-view/report-view.component';
import { ReportListComponent } from '../components/reports/report-list/report-list.component';
import { ReportDetailComponent } from '../components/reports/report-detail/report-detail.component';

//Report Garden
//Users report
import { UsersReportComponent } from '../../reports/security/users-report/users-report.component';
//Users per role report
import { UsersPerRoleReportComponent } from '../../reports/security/users-per-role-report/users-per-role-report.component';
import { UsersPerRoleParametersComponent } from '../../reports/security/users-per-role-report/parameters/users-per-role-parameters.component';

//Users Password Expiration Dates
import { UserPasswordExpirationDatesParametersComponent } from '../../reports/security/users-password-expiration-dates-report/parameters/users-password-expiration-dates-parameters.component';
import { UserPasswordExpirationDatesReportComponent } from '../../reports/security/users-password-expiration-dates-report/users-password-expiration-dates-report.component';


//Roles report
import { RolesReportComponent } from '../../reports/security/roles-report/roles-report.component';

//Audit Changes Dates
import { AuditChangesParametersComponent } from '../../reports/audit/audit-changes-report/parameters/audit-changes-parameters.component';
import { AuditChangesReportComponent } from '../../reports/audit/audit-changes-report/audit-changes-report.component';

//Audit Actions Dates
import { AuditActionsParametersComponent } from '../../reports/audit/audit-actions-report/parameters/audit-actions-parameters.component';
import { AuditActionsReportComponent } from '../../reports/audit/audit-actions-report/audit-actions-report.component';

//Access Settings Dates
import { AccessSettingsParametersComponent } from '../../reports/security/access-settings-report/parameters/access-settings-parameters.component';
import { AccessSettingsReportComponent } from '../../reports/security/access-settings-report/access-settings-report.component';

import { DatePipe } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from '../components/system/admin/admin.component';

import { TopNavComponent } from "../components/UI/top-nav/top-nav.component";
import { MenuListItemComponent } from "../components/UI/menu/menu-list-item/menu-list-item.component";


//import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { TreeViewComponent } from '../controls/treeviews/tree-view/tree-view.component';
import { TreeViewWithoutCheckBoxComponent } from '../controls/treeviews/tree-view-without-checkbox/tree-view-without-checkbox.component';
import { CodeSearchTextBoxComponent } from '../controls/code-search-text-box/code-search-text-box.component';
import { MyAccountComponent } from '../components/security/my-account/my-account.component';
import { SwitchUserComponent } from '../components/security/switch-user/switch-user.component';
import { LoginService } from '../services/security/login/login.service';
import { ReportHeaderComponent } from '../components/reports/report-detail/base/report-header/report-header.component';
import { ReportHeaderButtonComponent } from '../components/reports/report-detail/base/report-header-button/report-header-button.component';
import { MultiComboboxComponent } from '../controls/multicombobox/multicombobox.component';
import { MultiComboboxEntryComponent } from '../controls/containers/multicombobox-entry/multicombobox-entry.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';



import { MatIconRegistry } from '@angular/material/icon';

import { DateAdapter } from '@angular/material/core';


//// Forms
import { FormComponent } from '../../FriendsWithBenefits/components/forms/form-view/form-view.component';
import { FormService } from '../../FriendsWithBenefits/services/forms/form.service';




export function createTranslateLoader(http: HttpClient) {
  // Only first constructor parameter is required
  return new TranslateResxHttpLoader(http, 'trans.', 'assets/translations', '.resx');
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  blur: 6,
  overlayColor: "rgba(40,40,40,0.10)",
  pbThickness: 5, // progress bar thickness
  bgsColor: "#fff",
  bgsOpacity: 5,
  bgsSize: 60,
  logoSize: 120,
  logoPosition: "center-center",
  fgsPosition: POSITION.centerCenter,
  fgsSize: 125,
  fgsType: SPINNER.threeStrings,
  hasProgressBar: false,
  text: "Please Wait",
  textColor: "#161616",

}


@NgModule({
  declarations: [
    AppComponent,
    UserViewComponent,
    UserListComponent,
    UserDetailComponent,
    LoginComponent,
    MainMenuComponent,
    HomeComponent,
    AdminComponent,
    TopNavComponent,
    MenuListItemComponent,
    RoleViewComponent,
    RoleListComponent,
    RoleDetailComponent,
    DialogComponent,
    ConfirmDialogComponent,
    TreeViewComponent,
    TreeViewWithoutCheckBoxComponent,
    TextEntryComponent,
    TimeTextEntryComponent,
    DurationTextEntryComponent,
    //EmailEntryComponent,
    MemoEntryComponent,
    CodeEntryComponent,
    NumericEntryComponent,
    DecimalEntryComponent,
    PasswordEntryComponent,
    DateEntryComponent,
    DateRangePickerEntryComponent,
    ComboboxComponent,
    ComboboxEntryComponent,
    MultiComboboxComponent,
    MultiComboboxEntryComponent,
    ReportViewComponent,
    ReportListComponent,
    ReportDetailComponent,
    UsersReportComponent,
    UsersPerRoleParametersComponent,
    UsersPerRoleReportComponent,
    UserPasswordExpirationDatesParametersComponent,
    UserPasswordExpirationDatesReportComponent,
    AuditChangesParametersComponent,
    AuditChangesReportComponent,
    AuditActionsParametersComponent,
    AuditActionsReportComponent,
    AccessSettingsReportComponent,
    AccessSettingsParametersComponent,
    RolesReportComponent,
    ControlViewComponent,
    DateTimeEntryComponent,
    RadiobuttonEntryComponent,
    CheckboxEntryComponent,
    SpinnerComponent,
    GlobalSettingsComponent,
    HolidayComponent,
    TabComponent,
    TabsComponent,
    ToggleEntryComponent,
    CodeSearchTextBoxComponent,
    DropdownEntryComponent,
    MyAccountComponent,
    SwitchUserComponent,
    ReportHeaderComponent,
    ReportHeaderButtonComponent,
    FormComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialTimePickerModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    BaseService,
    LoginService,
    UserService,
    RoleService,
    FormService,
    DatePipe,
    MenuService,
    CookieService,
    AccessSettingService,
    ReportService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      "clients",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Clients.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "holidays",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Holidays.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "projects",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Projects.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "security",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Security.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Settings.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "worktypes",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Work-Types.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "timesheets",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Timesheets.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "invoicing",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Invoicing.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "audit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/Audit.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "timetrack",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/FW/TimeTrack.svg")
    );
  }
}

