import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ReportItem } from '../../../models/reports/report';
import { ReportService } from '../../../services/reports/report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportDetailComponent } from '../report-detail/report-detail.component';
import { ListComponentBase } from '../../../core/list-component-base';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/security/login/login.service';
import { User } from '../../../models/security/user';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})

export class ReportListComponent extends ListComponentBase implements OnInit, AfterViewInit {
  reportGroup: string;
  activeUser: User;


  constructor(private reportService: ReportService,
    private route: ActivatedRoute,
    private ngxService2: NgxUiLoaderService,
    private loginService: LoginService,
    public translate: TranslateService) {
    super(ngxService2);

    this.model = ReportItem;
    this.itemService = reportService;

    //this.loginService.getUser().subscribe(user => {
    //  this.activeUser = user;

    //  if (this.activeUser.usLanguage === 'English') {
    //    this.listColumns = ['Name', 'Description'];
    //    this.sortBy = 'Name';
    //  } else {
    //    this.listColumns = ['Naam', 'Omschrijving'];
    //    this.sortBy = 'Naam';
    //  }

    //});


    //if (this.activeUser.usLanguage === 'English') {
    //  this.listColumns = ['Name', 'Description'];
    //  this.sortBy = 'Name';
    //} else {
    //  this.listColumns = ['Naam', 'Omschrijving'];
    //  this.sortBy = 'Naam';
    //}

    this.listColumns = ['Name', 'Description'];
    this.sortBy = 'Name';
    this.sortDirection = 'asc';



    //Get report group
    this.route.queryParams.subscribe(
      params => {
        //this.reportGroup = params[0];
        var newReportGroup = params[0];

        //Clear detailsview if reportgroup is changed
        if (this.reportGroup != newReportGroup &&
          this.reportGroup != undefined) {
          this.itemService.item$.next(new ReplaySubject(1));
          this.itemService.item === undefined;
          this.itemService.setIsNewItem(false);

          this.itemService.setIsItemSelected(false);
          console.log('different reportgroup logged');
        }

        this.reportGroup = newReportGroup;
        this.reportService.setSelectedReportCategory(this.reportGroup)
        this.itemService.setRefreshList(true);
      }
    )
  }

  setSelectedItem(selectedReport: any) {
    super.setSelectedItem(selectedReport);

  }


  ngOnInit(): void {
    super.ngOnInit();

  }

}
