/* src/app/FW/controls/checkbox-entry/checkbox-entry.component.scss */
::ng-deep .mat-checkbox-ripple .mat-ripple-element {
  background-color: black !important;
}
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black !important;
}
::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: black !important;
}
/*# sourceMappingURL=checkbox-entry.component.css.map */
