import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { ReportService } from '../../../services/reports/report.service';
import { ReportDetailComponent } from '../report-detail/report-detail.component';

@Component({
  selector: 'report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {
  isItemSelected$: boolean;

  //@ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private reportService: ReportService) { }

  ngOnInit(): void {
    this.reportService.isItemSelected$.subscribe(
      selectedItem => {
        this.isItemSelected$ = selectedItem;

      
      });
  }


  ngAfterViewInit() {
    //// create the component factory
    //const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReportDetailComponent);

    //// add the component to the view
    //const componentRef = this.container.createComponent(componentFactory);

    //   // pass some data to the component
    // componentRef.instance.index = this._counter++;
  }


}
