import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportService } from '../../../FW/services/reports/report.service';
import { ReportPreviewItem, ReportComponentBase } from '../../../FW/core/report-component-base';


@Component({
  selector: 'users-password-expiration-dates-report',
  templateUrl: './users-password-expiration-dates-report.component.html',
  styleUrls: ['../../report.component.scss'],
  encapsulation: ViewEncapsulation.None // Add this line
})

export class UserPasswordExpirationDatesReportComponent extends ReportComponentBase implements OnInit {

  constructor(public dialogRef: MatDialogRef<UserPasswordExpirationDatesReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportPreviewItem,
  public reportService: ReportService) {
    super(dialogRef, data, reportService);

    //Set orientation; portrait | landscape
    this.reportOrientation = 'portrait';
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
