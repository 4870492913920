"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TranslateResxHttpLoader = void 0;
var operators_1 = require("rxjs/operators");
var ltx = require("ltx");
var TranslateResxHttpLoader = function () {
  function TranslateResxHttpLoader(http, prefix, transLocation, suffix) {
    if (prefix === void 0) {
      prefix = '';
    }
    if (transLocation === void 0) {
      transLocation = 'assets/i18n';
    }
    if (suffix === void 0) {
      suffix = '.resx';
    }
    this.http = http;
    this.prefix = prefix;
    this.transLocation = transLocation;
    this.suffix = suffix;
  }
  TranslateResxHttpLoader.prototype.getTranslation = function (lang) {
    var _this = this;
    var fullUrl = this.transLocation + "/" + this.prefix + lang + this.suffix;
    return this.http.get(fullUrl, {
      responseType: 'text'
    }).pipe(operators_1.map(function (content) {
      return _this.parse(content, lang);
    }));
  };
  TranslateResxHttpLoader.prototype.parse = function (contents, lang) {
    var xml = ltx.parse(contents);
    var translations = xml.children.filter(function (x) {
      return x.name && x.name === 'data';
    }).reduce(function (total, current) {
      var name = current.attrs.name;
      var value = current.children.filter(function (x) {
        return x.name && x.name === 'value';
      });
      if (value.length === 1) {
        value = value[0].children[0];
        total[name] = value;
      } else {
        console.error("Data node of token '" + name + "' for language '" + lang + "' should contain exactly ONE value node. Found '" + value.length + "' values");
      }
      return total;
    }, {});
    return translations;
  };
  return TranslateResxHttpLoader;
}();
exports.TranslateResxHttpLoader = TranslateResxHttpLoader;
