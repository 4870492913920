import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from '../menu-item';
import { MenuService } from '../menu.service';



@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = true;
  @Input() item: MenuItem;
  @Input() depth: number;

  HighlightRow: Number;
  ClickedRow: any; 
  constructor(public menuService: MenuService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }

    this.ClickedRow = function (index) {
      this.HighlightRow = index;
    }
  }

  ngOnInit() {
    this.menuService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: MenuItem) {
    if (!item.children || !item.children.length) {
      //this.router.navigate([item.route]);

      if (item.route === 'reportview') {
        this.router.navigate([item.route], { queryParams: [item.displayName] });
      } else {
        this.router.navigate([item.route]);
      }

    

      //this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
