  <div class="row">
    <label class="col-sm-2" for="Input">{{ Label }}</label>

    <div class="col-md-8">
      <input class="formcontrol"
             [value]="value"
             (blur)="onTouched()"
             (input)="onChange($event.target.value)"
             type="time"
             id="Input"
             [disabled]="IsDisabled">
    </div>
  </div>
