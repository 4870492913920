/* src/app/FW/components/system/holiday/holiday.component.scss */
.label {
  margin-top: 20px;
  font-size: 20px;
}
.calendar-card {
  height: auto;
  width: 600px;
}
.calendar-container {
  display: flex;
}
.selectedHolidayName {
  margin-left: 15px;
  text-wrap: nowrap;
}
::ng-deep .holiday-date {
  border: 2px solid green;
  border-radius: 100%;
}
/*# sourceMappingURL=holiday.component.css.map */
