"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delayWhen = void 0;
var concat_1 = require("../observable/concat");
var take_1 = require("./take");
var ignoreElements_1 = require("./ignoreElements");
var mapTo_1 = require("./mapTo");
var mergeMap_1 = require("./mergeMap");
function delayWhen(delayDurationSelector, subscriptionDelay) {
  if (subscriptionDelay) {
    return function (source) {
      return concat_1.concat(subscriptionDelay.pipe(take_1.take(1), ignoreElements_1.ignoreElements()), source.pipe(delayWhen(delayDurationSelector)));
    };
  }
  return mergeMap_1.mergeMap(function (value, index) {
    return delayDurationSelector(value, index).pipe(take_1.take(1), mapTo_1.mapTo(value));
  });
}
exports.delayWhen = delayWhen;
