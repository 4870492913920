import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../../../services/security/roles/role.service';

@Component({
  selector: 'role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent implements OnInit {
  isItemSelected$: boolean;

  constructor(private roleService: RoleService) {
  }

  ngOnInit() {
    this.roleService.isItemSelected$.subscribe(
      selectedItem => {
        this.isItemSelected$ = selectedItem;
      });
  }

}
