import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportComponentBase, ReportPreviewItem } from '../../../FW/core/report-component-base';
import { ReportService } from '../../../FW/services/reports/report.service';


@Component({
  selector: 'audit-actions-report',
  templateUrl: './audit-actions-report.component.html',
  styleUrls: ['../../report.component.scss'],
  encapsulation: ViewEncapsulation.None // Add this line
})

export class AuditActionsReportComponent extends ReportComponentBase implements OnInit {

  constructor(public dialogRef: MatDialogRef<AuditActionsReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportPreviewItem,
  public reportService: ReportService) {
    super(dialogRef, data, reportService);

    //Set orientation; portrait | landscape
    //this.reportOrientation = 'landscape';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reportOrientation = 'landscape';
  }

}
