<ReportHeaderButtons></ReportHeaderButtons>

<div mat-dialog-content>

  <ReportHeader></ReportHeader>


  <table id="dataTable"
         mat-table
         [dataSource]="reportDataSource"
         fixedLayout="true">



    <!-- Date Column -->
    <ng-container matColumnDef="date">

      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let item"> {{item.date}} </td>
    </ng-container>

    <!-- Code Column -->
    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef> User </th>
      <td mat-cell *matCellDef="let item"> {{item.userId}} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="table">
      <th mat-header-cell *matHeaderCellDef> Table </th>
      <td mat-cell *matCellDef="let item"> {{item.table}} </td>
    </ng-container>
 
    <!-- Role Column -->
    <ng-container matColumnDef="primaryKey">

      <th mat-header-cell *matHeaderCellDef> Primary Key </th>
      <td mat-cell *matCellDef="let item"> {{item.primaryKey}} </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="fieldName">

      <th mat-header-cell *matHeaderCellDef> Field Name </th>
      <td mat-cell *matCellDef="let item"> {{item.fieldName}} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef> Old Value </th>
      <td mat-cell *matCellDef="let item"> {{item.oldValue}} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef> New Value </th>
      <td mat-cell *matCellDef="let item"> {{item.newValue}} </td>
    </ng-container>








    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[20]"
                 showFirstLastButtons
                 (page)="pageEvent = onPageChange($event)">
  </mat-paginator>
</div>
