import { Injectable } from '@angular/core';
import { ItemNode } from '../models/ItemNode.model';
/**
 * This class transforms a data array into structured tree
 */

@Injectable()
export class TreeBuilder {
  constructor() {

  }
    
  buildTree(obj: any, level: number): ItemNode[] {
    return Object.keys(obj).reduce<ItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new ItemNode(value.id, value.name, value.seKey);
      console.log(value)
      if (value != null) {
        if (typeof value === 'object') {
          if (value.children != undefined) {
            if (value.children.length != 0) {
              node.children = this.buildTree(value.children, level + 1);
            }
          }

        }
      }

      return accumulator.concat(node);
    }, []);
  }
}
