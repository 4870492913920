<div style="padding: 10px 15px 0 15px;">
  <h1>{{ 'Holidays' | translate }}</h1>

  <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate>
    <div class="row">
      <div class="col-md-8">

        <div class="calendar-container">
          <mat-card appearance="outlined" class="calendar-card">
            <h1 *ngIf="selectedHolidayName" class="selectedHolidayName">Holiday: {{selectedHolidayName}}</h1>

            <mat-calendar [dateClass]="dateClass()"
                          [(selected)]="selected"
                          (selectedChange)="onSelect($event)"></mat-calendar>


          </mat-card>


        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />


    </div>
  </form>
</div>
