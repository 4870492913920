import { Component, OnInit, forwardRef, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlComponentBase } from '../../core/control-component-base';


export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => DropdownEntryComponent),
};

//@Component({
//  selector: 'DropdownEntry',
//  templateUrl: './dropdown-entry.component.html',
//  providers: [CONTROL_VALUE_ACCESSOR]
//})

@Component({
  selector: 'DropdownEntry',
  templateUrl: './dropdown-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownEntryComponent),
      multi: true
    }
  ]
})

export class DropdownEntryComponent extends ControlComponentBase implements ControlValueAccessor, OnInit {

  selectedItem = 0;
  selectedItems = [];
  @Input() items = [];

  private propagateOnChange = (value: { id, value }[]) => { };
  private propagateTouched = (value: { id, value }[]) => { };

  constructor(private cdRef: ChangeDetectorRef) {
      super();
  }

  ngOnInit(): void {
  }

  writeValue(items: { id, value }[]) {
    this.selectedItems = items;
  }

  registerOnChange(fn) {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  change(e) {
    this.selectedItem = +e.target.options[e.target.options.selectedIndex].value;
    const notAlreadyAdded = !this.selectedItems.some(item => item.id === this.selectedItem);
    const item = this.items.find(item => item.id === this.selectedItem);

    if (notAlreadyAdded && item) {
      this.selectedItems.push(item);
      this.propagateOnChange(this.selectedItems);
      this.propagateTouched(this.selectedItems);
    }

    // clear selectedItem once Change Detection finished
    setTimeout(() => {
      this.selectedItem = 0;
      // mark this component for Change Detection
      this.cdRef.markForCheck();
    });
  }
}
