'use strict';

var EventEmitter = require('events').EventEmitter;
var inherits = require('inherits');
var Element = require('./Element');
var LtxParser = require('./parsers/ltx');
var Parser = function (options) {
  EventEmitter.call(this);
  var ParserInterface = this.Parser = options && options.Parser || this.DefaultParser;
  var ElementInterface = this.Element = options && options.Element || this.DefaultElement;
  this.parser = new ParserInterface();
  var el;
  var self = this;
  this.parser.on('startElement', function (name, attrs) {
    var child = new ElementInterface(name, attrs);
    if (!el) {
      el = child;
    } else {
      el = el.cnode(child);
    }
  });
  this.parser.on('endElement', function (name) {
    if (!el) {
      /* Err */
    } else if (name === el.name) {
      if (el.parent) {
        el = el.parent;
      } else if (!self.tree) {
        self.tree = el;
        el = undefined;
      }
    }
  });
  this.parser.on('text', function (str) {
    if (el) {
      el.t(str);
    }
  });
  this.parser.on('error', function (e) {
    self.error = e;
    self.emit('error', e);
  });
};
inherits(Parser, EventEmitter);
Parser.prototype.DefaultParser = LtxParser;
Parser.prototype.DefaultElement = Element;
Parser.prototype.write = function (data) {
  this.parser.write(data);
};
Parser.prototype.end = function (data) {
  this.parser.end(data);
  if (!this.error) {
    if (this.tree) {
      this.emit('tree', this.tree);
    } else {
      this.emit('error', new Error('Incomplete document'));
    }
  }
};
module.exports = Parser;