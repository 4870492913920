<button class="float-right" mat-icon-button id="close" (click)="closeDetailView()">
  <mat-icon>close</mat-icon>
</button>

<h1>{{ actionType | translate }} {{ itemType | translate }}: {{ selectedItemName }}</h1>

<form [formGroup]="form" #formDir="ngForm" novalidate>
  <mat-tab-group>
    <mat-tab label="{{ 'Information' | translate }}">
      <div>
        <div class="tab-content">

          <div class="left">
            <label class="label">{{ 'Role' | translate }} {{ 'Information' | translate }}</label>
            <hr class="solid" style="margin-right:327px;">

            <!--Name Field-->
            <TextEntry Label="{{ 'Name' | translate }}"
                       formControlName="roName">
            </TextEntry>
            <span class="text-danger" *ngIf="form.controls.roName.invalid && formDir.submitted">
              {{ 'Name' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Code Field-->
            <CodeEntry Label="{{ 'Code' | translate }}"
                       formControlName="roCode">
            </CodeEntry>
            <span class="text-danger" *ngIf="form.controls.roCode.invalid && formDir.submitted">
              {{ 'Code' | translate }} {{ 'is required' | translate }}
            </span>

            <!--Description Field-->
            <TextEntry Label="{{ 'Description' | translate }}"
                       formControlName="roDescription">
            </TextEntry>
          </div>

        </div>
      </div>
    </mat-tab>


    <mat-tab label=" {{ 'Access Settings' | translate }}">
      <div class="tab-content">

        <label class="label">{{ 'Access Settings' | translate }} {{ 'Information' | translate }}</label>
        <hr class="solid" style="margin-right:560px;">


        <app-tree-view [TreeViewDATA]="securityItems"
                       [TreeViewLOAD]="accessSettings"
                       (TreeViewSELECTION)="readOutputValueEmitted($event)">

        </app-tree-view>

      </div>
    </mat-tab>
  </mat-tab-group>



  <div class="form-group">
    <button [disabled]="form.dirty === false" type="submit" (click)="save()" class="btntest btn btn-default float-left">{{ 'Save' | translate }}</button>
    <button *ngIf="!isNewItem$" (click)="delete(item$.roKey, item$.roName)" class="btntest btn btn-default float-left">{{ 'Delete' | translate }}</button>
  </div>
</form>
