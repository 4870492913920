<div class="left">
  <div style="font-weight:bold; font-size: 30px">
    {{reportTitle}}
  </div>
  <br />
  <br />
  Printed: {{reportDate}}
  <br />
  by {{createdBy}}
  <br />
  Page: {{ pageIndex }}
  <br />
  <div *ngIf="ShowDateRangeFilter">
    Selected Period: {{dateRangeFilterHeader}}
  </div>
  <div *ngIf="ShowRoleFilter">
    Selected Role: {{roleFilterHeader}}
  </div>
  <br />
  <br />
</div>

<div class="right">
  <img style="width: 250px; height: auto; padding-bottom: 5px;"
       src="./assets/images/TimeTrackerWeb/applogo.png"
       alt="AppLogo">
</div>

<br />
<br />
