/* src/app/FW/components/UI/menu/menu-list-item/menu-list-item.component.scss */
.left-column {
  width: 10%;
  float: left;
}
.right-column {
  width: 90%;
  float: right;
}
.mat-mdc-list-item.active {
  background-color: #c9d9ef;
  text-shadow: 1px 1px 2px black;
}
.mat-mdc-list-item:hover {
  text-shadow: 1px 1px 2px black;
}
/*# sourceMappingURL=menu-list-item.component.css.map */
