import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from '../../../../models/security/role';
import { RoleService } from '../../../../services/security/roles/role.service';
import { Guid } from 'guid-typescript';
import { ComboBoxItem } from '../../../../controls/combobox/combobox.component';
import { DetailComponentBase } from '../../../../core/detail-component-base';
import { DatePipe, formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../../services/security/login/login.service';
import { AuditActionService } from '../../../../services/audit/audit-action/audit-action.service';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { User } from '../../../../models/security/user';
import { UserService } from '../../../../services/security/users/user.service';

@Component({
  selector: 'UserDetail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})

export class UserDetailComponent extends DetailComponentBase implements OnInit, AfterViewInit, OnChanges {
  pipe = new DatePipe('en-US');



  @Input() TestReload: any;
  @Input() MyAccount: boolean;
  @Input() ActiveUser: User;

  //Form Fields
  formImage: string;

  id: string;

  ActiveTab: string;
  ActiveTabIndex: number;

  roles: Role[] = [];
  statuses: ComboBoxItem[] = [];
  languages: ComboBoxItem[] = [];




  private selectedStartTime = { hour: 0, minute: 0, meriden: "AM", format: 12 };
  selectedUserStartTime: any;


  constructor(private userService: UserService,
    private roleService: RoleService,
    loginService: LoginService,
    loginServiceBase: LoginService,
    auditActionService: AuditActionService,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    public router: Router) {
    super(snackBar, loginService, auditActionService);


    this.itemService = userService;
    this.model = User;

    this.actionType = 'Add';
    this.itemType = 'User';
    this.uniqueFieldName = 'Code';


    this.form = new UntypedFormGroup({
      usKey: new UntypedFormControl(''),
      usRoKey: new UntypedFormControl('', [Validators.required]),
      usUserName: new UntypedFormControl('', [Validators.required]),
      usFirstName: new UntypedFormControl('', [Validators.required]),
      usLastName: new UntypedFormControl('', [Validators.required]),
      usPassword: new UntypedFormControl(''),
      newPassword: new UntypedFormControl(''),
      confirmNewPassword: new UntypedFormControl(''),
      usCode: new UntypedFormControl('', [Validators.required]),
      usEmail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      usStatus: new UntypedFormControl('', [Validators.required]),
      usLanguage: new UntypedFormControl('', [Validators.required]),
      usPasswordExpirationDays: new UntypedFormControl('', []),
      usPasswordExpirationDate: new UntypedFormControl(null, []),
      usPasswordLength: new UntypedFormControl('', []),
      usDefaultHoursPerDay: new UntypedFormControl('', []),
      usHourlyRate: new UntypedFormControl('', []),
      usStartTime: new UntypedFormControl(null, [])

    });

  }


  onChangeStartTime(event: any) {
    if (Number(event.minute.substring(0, 1)) === 0 && Number(event.minute) !== 0o0) {
      event.minute = event.minute.substring(1, 2);

    }
    this.selectedStartTime = event;
    this.form.controls.usStartTime.setValue(this.selectedStartTime);
    this.form.markAsDirty();
  }



  onLanguageValueChange({ target }) {
    var userLanguage = target.value;

    if (userLanguage != undefined) {

      if (this.MyAccount == true) {
        if (userLanguage == 'English') {
          //const browserLang = translate.getBrowserLang();
          // translate.use(browserLang.match(/en|nl/) ? browserLang : 'en');
          this.translate.use('en');
        }

        if (userLanguage == 'Dutch') {
          this.translate.use('nl');
        }
      } else {
        var activeUser

        this.loginService.getUser().subscribe(user => {
          activeUser = user.usUserName;
        });


        if (this.existingItem != undefined) {
          if (this.existingItem.usUserName === activeUser) {
            if (userLanguage == 'English') {
              this.translate.use('en');
            }

            if (userLanguage == 'Dutch') {
              this.translate.use('nl');
            }
          }
        }
        
      }
    }
    
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.ActiveTab = tabChangeEvent.tab.textLabel;
    this.ActiveTabIndex = tabChangeEvent.index;
  }

  private doSetUserStartTime() {

    this.selectedUserStartTime = this.item$.usStartTimeString;
    //
    var startTimeHour = 0;
    var startTimeMinutes = 0;
    var startTimeMeridian = this.selectedUserStartTime.slice(-2);

    if (this.selectedUserStartTime.toString().length === 7) {
      startTimeHour = this.selectedUserStartTime.substring(0, 1);
      startTimeMinutes = this.selectedUserStartTime.substring(2, 4);
    }

    if (this.selectedUserStartTime.toString().length === 8) {
      startTimeHour = this.selectedUserStartTime.substring(0, 2);
      startTimeMinutes = this.selectedUserStartTime.substring(3, 5);
    }

    if (startTimeMinutes.toString() === '00') {
      startTimeMinutes = 0;
    }

    if (Number(startTimeMinutes.toString().substring(0, 1)) === 0 && Number(startTimeMinutes) !== 0o0) {
      startTimeMinutes = Number(startTimeMinutes.toString().substring(1, 2));

    }


    this.selectedStartTime = { hour: startTimeHour, minute: startTimeMinutes, meriden: startTimeMeridian, format: 12 };
    this.form.controls.usStartTime.setValue(this.selectedStartTime);



  }


  setExistingUser() {
    if (this.primaryKey != undefined) {
      this.isNewItem$ = false;
      if (this.primaryKey.length == 36) {
        this.actionType = 'Edit';
        this.userService.get(this.primaryKey)
          .subscribe((data: User) => (
            this.existingItem = data,
            this.formImage = (data.usImage == '' ? './assets/images/FW/userimage.png' : data.usImage),
            this.form = this.formBuilder.group(data),
            //Add newPassword and confirmNewPassword controls back to form since form was build above from User model class which does not include those fields.
            this.form.addControl('newPassword', new UntypedFormControl('')),
            this.form.addControl('confirmNewPassword', new UntypedFormControl('')),
            this.form.controls.usPasswordExpirationDate.setValue(this.pipe.transform(data.usPasswordExpirationDate, 'yyyy-MM-dd')),
            this.form.controls.usEmail.setValidators([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
            this.form.controls.usRoKey.setValidators([Validators.required]),
            this.form.controls.usUserName.setValidators([Validators.required]),
            this.form.controls.usFirstName.setValidators([Validators.required]),
            this.form.controls.usLastName.setValidators([Validators.required]),
            this.form.controls.usCode.setValidators([Validators.required]),
            this.form.controls.usStatus.setValidators([Validators.required]),
            this.form.controls.usLanguage.setValidators([Validators.required]),

            this.form.controls.usDefaultHoursPerDay.setValidators([Validators.required]),
            this.form.controls.usHourlyRate.setValidators([Validators.required]),

            this.doSetUserStartTime()
          ));

      } 
    } else {
      console.log('in magic button bug');

      this.form.reset();
      this.actionType = 'Add';
    }
  }










  setExistingItem() {
    if (this.primaryKey != undefined) {
      this.isNewItem$ = false;
      if (this.primaryKey.length == 36) {
        this.actionType = 'Edit';
        this.userService.get(this.primaryKey)
          .subscribe((data: User) => (
            this.existingItem = data,
            this.form.controls.usKey.setValue(this.primaryKey)
          ));

      }
    }
  }








  ngOnChanges(changes: SimpleChanges) {

    if (changes) {
      console.log('reloadtest works');
    }
    console.log('reloadtest works');
    console.log('reloadtest works');
  } 

  ngOnInit() {

    this.itemService.getIsNewItem().subscribe(isNewItem => {
      //this.isNewItem$ = isNewItem;

      if (isNewItem === true) {
        console.log('newitemloggedondetailview');
        this.form.reset();
        this.form.markAsPristine();


        this.form = new UntypedFormGroup({
          usKey: new UntypedFormControl(''),
          usRoKey: new UntypedFormControl('', [Validators.required]),
          usUserName: new UntypedFormControl('', [Validators.required]),
          usFirstName: new UntypedFormControl('', [Validators.required]),
          usLastName: new UntypedFormControl('', [Validators.required]),
          usPassword: new UntypedFormControl('', [Validators.required]),
          newPassword: new UntypedFormControl(''),
          confirmNewPassword: new UntypedFormControl('', [Validators.required]),
          usCode: new UntypedFormControl('', [Validators.required]),
          usEmail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
          usStatus: new UntypedFormControl('', [Validators.required]),
          usLanguage: new UntypedFormControl('', [Validators.required]),
          usPasswordExpirationDays: new UntypedFormControl('', []),
          usPasswordLength: new UntypedFormControl('', []),

          usDefaultHoursPerDay: new UntypedFormControl('', []),
          usHourlyRate: new UntypedFormControl('', []),
          usStartTime: new UntypedFormControl(null, [])
        });


        //this.primaryKey = undefined;
      }


    });

    super.ngOnInit();


    if (this.ActiveUser != undefined) {
      //LOAD ROLES
      this.loadRoles();

      this.loadStatuses();
      this.loadLanguages();

      let activeUser = this.ActiveUser;

      this.item$ = activeUser;

      this.formImage = ''
      this.primaryKey = this.item$.usKey;


      if (this.primaryKey != undefined) {
        this.selectedItemName = this.item$.usFirstName;
      } else {
        var localStoredUsername = localStorage.getItem('user');

        if (localStoredUsername != null) {

          this.userService.getUserByUserName(localStoredUsername)
            .subscribe((data) => {

              this.primaryKey = data.usKey;
              this.selectedItemName = data.usFirstName;

              this.setExistingUser();
            });
        }
      }



     // this.selectedItemName = this.item$.usFirstName;
      this.setExistingUser();

    } else {
      //LOAD ROLES
      this.loadRoles();

      this.loadStatuses();
      this.loadLanguages();

      let activeUser = this.ActiveUser;


      this.userService.getSelectedItemDetail().subscribe(user => {

        this.item$ = user;

        if (activeUser != undefined) {
          this.item$ = activeUser;
        }

        this.formImage = ''
        this.primaryKey = this.item$.usKey;

        this.selectedItemName = this.item$.usFirstName;


        //this.form = this.formBuilder.group(user);

        this.setExistingUser();

      });
    }










   
  }

  ngAfterViewInit() {
    this.form.controls.usLanguage.setValue(this.languages[0].value),
    this.form.controls.usStatus.setValue(this.statuses[0].value)


    //this.form.controls.usRoKey.setValue(this.roles[0].roKey),
    //  this.form.controls.usLanguage.setValue(this.languages[0].value),
    //  this.form.controls.usStatus.setValue(this.statuses[0].value)

  }

  loadRoles() {
    this.roleService.getList()
      .subscribe(result => {
        if (result != undefined) {
          this.roles = result;
         // this.form.controls.usRoKey.setValue('');
          this.form.controls.usRoKey.setValue(this.roles[0].roKey)
        }

      });
  }

  loadStatuses() {
    this.statuses.push(new ComboBoxItem(1, 'Enabled')),
    this.statuses.push(new ComboBoxItem(0, 'Disabled'))
  }

  loadLanguages() {
    this.languages.push(new ComboBoxItem('English', 'English')),
    this.languages.push(new ComboBoxItem('Dutch', 'Dutch'))
  }


  save() {
    var date = new Date();
    var passwordExp = this.form.controls.usPasswordExpirationDate.value;
    var passwordExpDate = new Date(passwordExp);

    if (!this.MyAccount) {
      if (passwordExp != null) {
        if (passwordExpDate < date) {
          var message = 'Password Expiration Date can not be set in the past';
          this.snackBar.open(message, "OK", {
            duration: 3000,
          });
        } else {

          this.doSave();

        }
      } else {
        this.doSave();
      }
    } else {
      this.doSave();
    }
   


  




    //this.router.navigate(['/home']);
    //this.router.navigate(['/security/users']);

    //this.TestReload = false;
    //this.TestReload = true;
    //this.TestReload = false;


    //this.close();

    //    this.userService.setIsItemSelected(false);
    //this.userService.setIsItemSelected(true);
    //this.userService.setItem(user);

    //this.userService.item$.next(new ReplaySubject(1));
    //this.userService.item === undefined;
    //this.userService.setIsNewItem(false);

    //this.userService.setIsItemSelected(false);
    //this.userService.setIsItemSelected(true);
    //this.userService.setItem(user);

    //this.userService.setIsItemSelected(false);


  }


  doSave() {
    let user = new User();
    var nowDate = Date.now();


    if (this.form.valid === true) {
     

      var activeUser = '';

      var todayDate = new Date()
      todayDate.setHours(0, 0, 0, 0);


      this.loginService.getUser().subscribe(user => {
        activeUser = user.usKey;
      });


      if (this.actionType === 'Add') {
        user.usKey = Guid.create().toString();
        this.primaryKey = user.usKey;
        user.usRoKey = this.form.controls.usRoKey.value;

        user.usUserName = this.form.controls.usUserName.value;
        //Validation logic for password below
       user.usPassword = this.form.controls.usPassword.value;


        user.usFirstName = this.form.controls.usFirstName.value;
        user.usLastName = this.form.controls.usLastName.value;
        user.usAddress = '';


        user.usCode = this.form.controls.usCode.value;
        user.usEmail = this.form.controls.usEmail.value;
        user.usStatus = Number(this.form.controls.usStatus.value);
        user.usLanguage = this.form.controls.usLanguage.value;
        user.usImage = this.formImage;
        user.usPasswordExpirationDays = Number(this.form.controls.usPasswordExpirationDays.value);
        user.usPasswordExpirationDate = this.form.controls.usPasswordExpirationDate.value;
        user.usPasswordLength = Number(this.form.controls.usPasswordLength.value);

        user.usDefaultHoursPerDay = Number(this.form.controls.usDefaultHoursPerDay.value);
        user.usHourlyRate = Number(this.form.controls.usHourlyRate.value);
        user.usStartTimeString = ((formatDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), this.selectedStartTime.hour, this.selectedStartTime.minute, 0), "yyyy-MM-dd hh:mm:ss", 'en-US')).toString() + ' ' + this.selectedStartTime.meriden)


        user.createTime = new Date(nowDate);
        user.createUserId = activeUser;
        user.changeTime = null;
        user.changeUserId = '';
        user.deleteTime = null;
        user.deleteUserId = '';
        user.delFlag = 0;


        //Password validation logic here
        //check if new password fields match
        if (this.form.controls.usPassword.value === this.form.controls.confirmNewPassword.value) {
          super.save(user, user.usKey);
        } else {
          // return message to user

          var message = 'Password and Password Confirmation do not match';
          this.snackBar.open(message, "OK", {
            duration: 3000,
          });
        }


  

        if (this.actionType != 'Add') {
          console.log('actiontype change save true');
        }
      }



      if (this.actionType === 'Edit') {
        if (this.existingItem === undefined) {
          this.setExistingItem();
        }

        user.usStatus = Number(this.form.controls.usStatus.value);
        var TriedActiveUserDisable = false;

        if (user.usStatus === 0 &&
          activeUser === this.form.controls.usKey.value) {
          TriedActiveUserDisable = true;
        }

        var expirationDays = 0;
        if (this.form.controls.usPasswordExpirationDays.value != null) {
          expirationDays = this.form.controls.usPasswordExpirationDays.value;
        }
        var passwordLength = 0;
        if (this.form.controls.usPasswordLength.value != null) {
          passwordLength = this.form.controls.usPasswordLength.value;
        }

        if (TriedActiveUserDisable === false) {


          user.usKey = this.form.controls.usKey.value;
          user.usRoKey = this.form.controls.usRoKey.value;
          user.usUserName = this.form.controls.usUserName.value;
          user.usPassword = this.form.controls.usPassword.value;
          user.usFirstName = this.form.controls.usFirstName.value;
          user.usLastName = this.form.controls.usLastName.value;
          user.usAddress = '';
          user.usCode = this.form.controls.usCode.value;
          user.usEmail = this.form.controls.usEmail.value;
          user.usStatus = Number(this.form.controls.usStatus.value);
          user.usLanguage = this.form.controls.usLanguage.value;
          user.usImage = this.formImage;
          user.usPasswordExpirationDays = Number(expirationDays);
          user.usPasswordExpirationDate = new Date(this.form.controls.usPasswordExpirationDate.value);
          user.usPasswordLength = Number(passwordLength);

          user.usDefaultHoursPerDay = Number(this.form.controls.usDefaultHoursPerDay.value);
          user.usHourlyRate = Number(this.form.controls.usHourlyRate.value);
          user.usStartTimeString = ((formatDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), this.selectedStartTime.hour, this.selectedStartTime.minute, 0), "yyyy-MM-dd hh:mm:ss", 'en-US')).toString() + ' ' + this.selectedStartTime.meriden)

          user.createTime = this.existingItem.createTime;
          user.createUserId = this.existingItem.createUserId;
          user.changeTime = new Date(nowDate);
          user.changeUserId = activeUser;
          user.deleteTime = this.existingItem.deleteTime;
          user.deleteUserId = this.existingItem.deleteUserId;
          user.delFlag = this.existingItem.delFlag;

          //user.createTime = this.item$.createTime;
          //user.createUserId = this.item$.createUserId;
          //user.changeTime = new Date(nowDate);
          //user.changeUserId = activeUser;
          //user.deleteTime = this.item$.deleteTime;
          //user.deleteUserId = this.item$.deleteUserId;
          //user.delFlag = this.item$.delFlag;



          //check if new password fields are dirty

          if (this.form.controls.newPassword.value !== '' &&
            this.form.controls.confirmNewPassword.value !== '') {

            //check if new password fields match
            if (this.form.controls.newPassword.value == this.form.controls.confirmNewPassword.value) {
              user.usPassword = this.form.controls.newPassword.value;



              super.save(user, user.usKey);
            } else {
              // return message to user

              var message = 'New password confirmation does not match';
              this.snackBar.open(message, "OK", {
                duration: 3000,
              });
            }

          } else {

            super.save(user, user.usKey);
          }


        } else {
          ///here prevent status disabled

          this.snackBar.open("You can not disabled the user that you are currently logged in with", "OK", {
            duration: 3000,
          });



        }




      }
    }
    //If form is invalid; logic here
    else {
      //Since password information is on another tab we need to notify the user with an alert
      //because unless they navigate to the password tab they will not see the validation error message on the form

      //Check if password tab is not selected first because otherwise there is no need to display the alert
      if (this.ActiveTabIndex != 1) {
        if (this.form.controls.confirmNewPassword.valid === false) {
          var message = 'Password confirmation field can not be empty';
          this.snackBar.open(message, "OK", {
            duration: 3000,
          });
        }

        if (this.form.controls.usPassword.valid === false) {
          var message = 'Password field can not be empty';
          this.snackBar.open(message, "OK", {
            duration: 3000,
          });
        }
      }
      
    }

  }



  fileChange(fileInputEvent: any) {
    const file = fileInputEvent.target.files[0];

    var fileType = file.type as string;

    if (fileType.includes('image')) {
      console.log('In fileType Method with image');

      if (file.size > 2097152) {

        var message = 'File is too big! Max file size allowed is 2MB';
        this.snackBar.open(message, "OK", {
          duration: 3000,
        });


      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formImage = reader.result as string;
          console.log(reader.result as string);

          this.form.markAsDirty();
        };
      }

   

    } else {

      var message = 'Please select a valid image';
      this.snackBar.open(message, "OK", {
        duration: 3000,
      });

      console.log('In fileType Method without image');
    }
  }

  removePortrait() {
    if (this.formImage != './assets/images/FW/userimage.png') {
      this.formImage = '';
      this.form.markAsDirty();
    }
  }

}
