/* src/app/FW/controls/treeviews/tree-view-without-checkbox/tree-view-without-checkbox.component.scss */
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #000000;
}
::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #000000;
}
.mat-tree-node {
  min-height: 1.5em !important;
  height: 1.5em;
}
/*# sourceMappingURL=tree-view-without-checkbox.component.css.map */
