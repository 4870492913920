  <div class="row">
    <label class="col-sm-2" for="Input">{{ Label }}</label>

    <div class="col-md-8">
      <textarea style="height:90px"
             class="formcontrol"
             [value]="value"
             (blur)="onTouched()"
             (input)="onChange($event.target.value)"
             type="text"
             id="Input"
             [disabled]="IsDisabled">
      </textarea>
    </div>
  </div>
