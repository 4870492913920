/* src/app/FW/controls/containers/combobox-entry/combobox-entry.component.scss */
.formcontrol {
  border: 1px solid silver;
  border-radius: 5px;
  width: 215px;
}
.disabled {
  pointer-events: none;
  opacity: 0.7;
}
/*# sourceMappingURL=combobox-entry.component.css.map */
