/* src/app/FW/components/security/roles/role-list/role-list.component.scss */
::ng-deep th.mat-mdc-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
.table tr.active td {
  background-color: #bad7ff !important;
}
table {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 400px;
  background-color: white;
}
.table-row {
  height: 30px !important;
}
th.mat-mdc-header-cell {
  text-align: left;
  max-width: 300px;
  height: 30px !important;
}
.mat-mdc-row:hover {
  background-color: #e8e8e8 !important;
}
.mat-mdc-header-cell {
  color: black;
  font: bold 14px/20px Roboto;
  letter-spacing: 0.0179em;
  height: 30px !important;
}
.table-row:focus {
  background: #bad7ff !important;
  outline: none;
}
.formcontrol {
  border: 1px solid silver;
  border-radius: 5px;
  width: 215px;
}
.btn-default {
  color: #000000;
  background-color: #f2f2f2;
  border-color: #e8e8e8;
  height: 30px;
  text-align: center;
  line-height: 14px;
  text-transform: none;
  font-size: 16px;
}
/*# sourceMappingURL=role-list.component.css.map */
