import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportComponentBase, ReportPreviewItem } from '../../../FW/core/report-component-base';
import { ReportService } from '../../../FW/services/reports/report.service';

@Component({
  selector: 'roles-report',
  templateUrl: './roles-report.component.html',
  styleUrls: ['../../report.component.scss']
})

export class RolesReportComponent extends ReportComponentBase implements OnInit {

  constructor(public dialogRef: MatDialogRef<RolesReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportPreviewItem,
    public reportService: ReportService) {
    super(dialogRef, data, reportService);
   
    //Set orientation; portrait | landscape
    this.reportOrientation = 'portrait';
    this.reportService.setShowRoleFilter(false);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
