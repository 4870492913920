"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.distinct = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var noop_1 = require("../util/noop");
function distinct(keySelector, flushes) {
  return lift_1.operate(function (source, subscriber) {
    var distinctKeys = new Set();
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      var key = keySelector ? keySelector(value) : value;
      if (!distinctKeys.has(key)) {
        distinctKeys.add(key);
        subscriber.next(value);
      }
    }));
    flushes === null || flushes === void 0 ? void 0 : flushes.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function () {
      return distinctKeys.clear();
    }, noop_1.noop));
  });
}
exports.distinct = distinct;
