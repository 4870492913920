import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from '../../../core/base.service';
import { Holiday } from '../../../models/system/holiday';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HolidayService extends BaseService<Holiday> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);

    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Holiday/';
  }

  getHolidays(): Observable<Holiday> {
    return this.http.get<Holiday>(this.myAppUrl + this.myApiUrl + 'GetHolidays/')
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }
}
