import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlComponentBase } from '../../../core/control-component-base';


export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => ComboboxEntryComponent),
};

@Component({
  selector: 'ComboBoxEntry',
  templateUrl: './combobox-entry.component.html',
  styleUrls: ['./combobox-entry.component.scss'],
  providers: [CONTROL_VALUE_ACCESSOR]
})
export class ComboboxEntryComponent extends ControlComponentBase implements ControlValueAccessor, OnInit {
  private innerValue: any;

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  get Value(): any {
    return this.innerValue;
  }

  set Value(value: any) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.onchange(value);
    }
  }

  writeValue(value: any) {
    this.innerValue = value;
  }

  onchange = (_) => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => void): void { this.onchange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}

export class ComboBoxItem {
  value: any;
  name: string;

  constructor(value: any, name: string) {
    this.value = value;
    this.name = name;
  }
}
