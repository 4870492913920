<div class="row">
  <label class="col-sm-2 inputlabel" for="Input">{{ Label }}</label>

  <div class="col-md-8">

    <select style="height:24px; padding: 2px;"
            class="formcontrol"
            [ngClass]=" IsDisabled ? 'disabled' : 'enabled'"
            [(ngModel)]="Value">
      <option disabled selected></option>
      <ng-content></ng-content>
    </select>

  </div>
</div>

