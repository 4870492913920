<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">

    <mat-checkbox [value]="value"
                  (blur)="onTouched()"
                  (input)="onChange($event.target.value)"
                  type="checkbox"
                  id="Input">
    </mat-checkbox>

  </div>
</div>
