/* src/app/FW/controls/timepicker-entry/w-time/w-time.component.scss */
:host {
  display: block;
}
.w-time {
  max-height: 100%;
  min-height: 350px;
  height: 350px;
}
.w-time .w-timepicker-time-container {
  padding: 15px;
  min-width: 160px;
  width: 160px;
}
.w-time .w-timepicker-time-container.mat-toolbar-single-row {
  height: 100%;
}
.w-time .w-timepicker-selected-time {
  font-size: 3.5rem;
  font-weight: 400;
  display: flex;
}
.w-time .w-timepicker-selected-ampm {
  font-size: 1rem;
  line-height: 1.3rem;
  padding-top: 2rem;
}
.w-time .w-time-content {
  width: 100%;
  height: 100%;
  padding: 6px;
}
.w-time .w-time-content w-clock {
  padding: 12px 0;
  height: calc(100% - 58px);
}
.w-time.vertical-time {
  height: auto;
}
.w-time.vertical-time .w-timepicker-time-container {
  min-width: auto;
  width: 100%;
  height: 100px;
}
.w-time.vertical-time .w-timepicker-time-container .w-timepicker-selected-ampm {
  padding: 0 12px;
}
.w-timepicker-selected-time > span,
.w-timepicker-selected-ampm > span {
  outline: 0;
  opacity: 0.5;
}
.w-timepicker-selected-time > span:not(.active),
.w-timepicker-selected-ampm > span:not(.active) {
  cursor: pointer;
}
.w-timepicker-selected-time > span.active,
.w-timepicker-selected-ampm > span.active {
  opacity: 1;
}
.w-animate-next {
  opacity: 0;
  -webkit-transform: translate3d(50%, 0, 1px);
  transform: translate3d(50%, 0, 1px);
}
.w-animate-next-remove {
  -webkit-transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  opacity: 0;
  -webkit-transform: translate3d(50%, 0, 1px);
  transform: translate3d(50%, 0, 1px);
}
.w-animate-next-remove-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.w-animate-prev {
  opacity: 0;
  -webkit-transform: translate3d(-50%, 0, 1px);
  transform: translate3d(-50%, 0, 1px);
}
.w-animate-prev-remove {
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  opacity: 0;
  -webkit-transform: translate3d(-50%, 0, 1px);
  transform: translate3d(-50%, 0, 1px);
}
.w-animate-prev-remove-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
@-webkit-keyframes w-animation-bounce {
  from {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes w-animation-bounce {
  from {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.w-animation-zoom.ng-enter {
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: w-animation-bounce;
  animation-name: w-animation-bounce;
}
/*# sourceMappingURL=w-time.component.css.map */
