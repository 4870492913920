/* src/app/FW/main/app.component.scss */
.mat-sidenav {
}
.mat-sidenav-container {
  background-color: white !important;
  height: 93vh;
}
.mat-sidenav-container .mat-sidenav-content {
}
.is-active {
  background-color: red;
}
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}
.sidenav-toolbar {
  height: 64px;
  background-color: #e3e3e3;
  display: flex;
  flex-direction: row;
}
.mat-nav-list {
  padding-top: 0;
}
@media (max-width: 600px) {
  .fixed-topnav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100% !important;
  }
  .mat-drawer-container,
  .mat-drawer {
    padding-top: 56px;
  }
}
.menu-list-item.mat-mdc-list-item {
}
.menu-list-item.mat-mdc-list-item .mat-list-item-content {
  flex: 1 1 100%;
}
.version-info {
  font-size: 8pt;
  padding: 8px;
  align-self: flex-end;
}
/*# sourceMappingURL=app.component.css.map */
