import { Component, OnInit, ComponentRef, ComponentFactoryResolver, ViewContainerRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DetailComponentBase } from '../../../core/detail-component-base';
import { ReportService } from '../../../services/reports/report.service';
import { ReportItem } from '../../../models/reports/report';
import { DatePipe } from '@angular/common'
import { RoleListComponent } from '../../security/roles/role-list/role-list.component';
import { ComponentType } from '@angular/cdk/portal';
import { ReportPreviewItem } from '../../../core/report-component-base';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../../controls/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogModel, DialogComponent } from '../../../controls/dialogs/dialog/dialog.component';
import { ReplaySubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../../services/security/login/login.service';
import { AuditActionService } from '../../../services/audit/audit-action/audit-action.service';
import { UsersPerRoleParametersComponent } from '../../../../reports/security/users-per-role-report/parameters/users-per-role-parameters.component';
import { UserPasswordExpirationDatesParametersComponent } from '../../../../reports/security/users-password-expiration-dates-report/parameters/users-password-expiration-dates-parameters.component';
import { AuditChangesParametersComponent } from '../../../../reports/audit/audit-changes-report/parameters/audit-changes-parameters.component';
import { AuditActionsParametersComponent } from '../../../../reports/audit/audit-actions-report/parameters/audit-actions-parameters.component';
import { AccessSettingsParametersComponent } from '../../../../reports/security/access-settings-report/parameters/access-settings-parameters.component';
import { ReportViewComponentRegistry } from '../../../../reports/report-view-component-registry';

@Component({
  selector: 'report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})

export class ReportDetailComponent extends DetailComponentBase implements OnInit,AfterViewInit {
  date: Date;
  @ViewChild('container', { static: true, read: ViewContainerRef }) container: ViewContainerRef;


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private reportService: ReportService,
    loginService: LoginService,
    auditActionService: AuditActionService,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public router: Router,
  private snackbar: MatSnackBar) {
    super(snackbar, loginService, auditActionService);


    router.events.subscribe((val) => {
      // see also 
      console.log(val)
    });

    this.date = new Date();
    this.itemService = reportService;
    this.model = ReportItem;

  }

  closeDetailViewReportTest() {
    console.log('close works!');

    this.itemService.item$.next(new ReplaySubject(1));
    this.itemService.item === undefined;
    this.itemService.setIsNewItem(false);

    this.itemService.setIsItemSelected(false);

    //this.router.navigate(['/reportview']);
  }

  ngOnInit() {
    super.ngOnInit
    this.item$ = this.itemService.getSelectedReportDetail();

    this.itemService.getSelectedItemDetail().subscribe(itemDetail => {
      this.item$ = itemDetail;
    })
  }

  ngAfterViewInit() {

    this.container.clear();
    console.log('logged');

    let componentFactory = null;

    switch (this.item$.businessClass) {
      case 'GetUsersReport': {
        //statements; 
        break;
      }
      case 'GetUsersPerRoleReport': {
        //statements;

        // create the component factory
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(UsersPerRoleParametersComponent);
        //this.componentFactoryResolver.resolveComponentFactory().
        break;
      }
      case 'GetUserPasswordExpirationDatesReport': {
        //statements;

        // create the component factory
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserPasswordExpirationDatesParametersComponent);
        //this.componentFactoryResolver.resolveComponentFactory().
        break;
      }
      case 'GetAuditChangesReport': {
        //statements;

        // create the component factory
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(AuditChangesParametersComponent);
        //this.componentFactoryResolver.resolveComponentFactory().
        break;
      }
      case 'GetAuditActionsReport': {
        //statements;

        // create the component factory
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(AuditActionsParametersComponent);
        //this.componentFactoryResolver.resolveComponentFactory().
        break;
      }
      case 'GetAccessSettingsReport': {
        //statements;

        // create the component factory
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(AccessSettingsParametersComponent);
        //this.componentFactoryResolver.resolveComponentFactory().
        break;
      }

    }

    if (componentFactory != null) {
      const componentRef = this.container.createComponent(componentFactory);
    }






    this.itemService.getSelectedItemDetail().subscribe(itemDetail => {
      this.container.clear();
      console.log('logged');

      let componentFactory = null;

      switch (this.item$.businessClass) {
        case 'GetUsersReport': {
          //statements; 
          break;
        }
        case 'GetUsersPerRoleReport': {
          //statements;

          // create the component factory
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(UsersPerRoleParametersComponent);
          //this.componentFactoryResolver.resolveComponentFactory().
          break;
        }
        case 'GetUserPasswordExpirationDatesReport': {
          //statements;

          // create the component factory
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserPasswordExpirationDatesParametersComponent);
          //this.componentFactoryResolver.resolveComponentFactory().
          break;
        }
        case 'GetAuditChangesReport': {
          //statements;

          // create the component factory
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(AuditChangesParametersComponent);
          //this.componentFactoryResolver.resolveComponentFactory().
          break;
        }
        case 'GetAuditActionsReport': {
          //statements;

          // create the component factory
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(AuditActionsParametersComponent);
          //this.componentFactoryResolver.resolveComponentFactory().
          break;
        }
        case 'GetAccessSettingsReport': {
          //statements;

          // create the component factory
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(AccessSettingsParametersComponent);
          //this.componentFactoryResolver.resolveComponentFactory().
          break;
        }
      }

      if (componentFactory != null) {
        const componentRef = this.container.createComponent(componentFactory);
      }

    });
  }


  RunReport(reportItemBusinessClass: string, reportViewComponent: ComponentType<any>, reportItemHasParameters: number) {
    let reportParameters = this.itemService.getReportParameters();

    //If report has no parameters set to empty string;
    if (reportParameters === undefined) {
      reportParameters = '';
    }

    var test = reportParameters.value;

    var parameterValueCount = 0;

    for (var property in reportParameters.value) {
      if (reportParameters.value.hasOwnProperty(property)) {
        //console.log(property, ' ', reportParameters.value[property]);
        //console.log(property);
        //console.log('split');

        parameterValueCount = reportParameters.value[property].length;
        console.log(test);
        console.log(reportParameters.value[property]);
      }
    }

    //if (parameterValueCount === 0) {
    if (parameterValueCount === 0 && reportItemHasParameters === 1) {
      const message = ``;

      const dialogData = new DialogModel(message,"Report parameters can not be empty");

      const dialogRef = this.dialog.open(DialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });
    } else {



      this.itemService.runReport(reportItemBusinessClass, reportParameters.value)
        .subscribe(reportData => {

          console.log(reportData);
          //this.itemService.setItem(reportData);

          var activeUser;

          this.loginService.getUser().subscribe(user => {
            activeUser = user;
          });


          const reportTitle = this.item$.name;
          const reportColumns = this.item$.columns;
          const reportOrientation = this.item$.orientation;
          const reportDate = this.datepipe.transform(this.date, 'dd-MM-yyyy HH:mm');
          const createdBy = activeUser.usUserName;

          const dialogData = new ReportPreviewItem(reportData, reportTitle, reportColumns,reportOrientation, reportDate, createdBy);


          let reportViewComponentRegistry = new ReportViewComponentRegistry();
          reportViewComponent = reportViewComponentRegistry.GetTypeOf(this.item$.businessClass);


          var reportWidth = undefined;

          if (reportOrientation === "portrait") {
            reportWidth = "40%";
          } else {
            reportWidth = "80%";
          }


          const dialogRef = this.dialog.open(reportViewComponent, {
            width: reportWidth,
            height: "100%",
            backdropClass: 'ReportDialogBackground',
            panelClass: 'ReportDialogClass',
            data: dialogData
          });


        })




    }
  }

  CloseTest() {
    this.container.clear();
  }

}

