<form [formGroup]="form" #formDir="ngForm" novalidate>
  <div class="row">
    <label class="col-sm-2" for="Input">{{ Label }}</label>

    <div style="margin-left: 34px">
      <ComboBox formControlName="dateSelection">
        <option *ngFor="let date of dateRanges" [value]="date.value">
          {{date.name}}
        </option>
      </ComboBox>
    </div>


        <input style="margin-left: 16px; height: 24px;"
               class="formcontrol"
               (blur)="onTouched()"
               (input)="StartDateChanged($event.target.value)"
               type="date"
               id="StartDate"
               formControlName="StartDate">

        <input style="margin-left: 1px; height: 24px;"
               class="formcontrol"
               (blur)="onTouched()"
               (input)="EndDateChanged($event.target.value)"
               type="date"
               id="EndDate"
               formControlName="EndDate">

      
    </div>
</form>
