<div class="body">

  <h1>{{ 'Database Manager' | translate }}</h1>

  <br />

  <h5>  {{ 'Application' | translate }} {{ 'Name' | translate }}: {{ systemInformation.siApplicationName }}</h5>
  <h5>  {{ 'Application' | translate }} {{ 'Version' | translate }}: {{ systemInformation.siApplicationVersion }}</h5>
  <h5>  {{ 'Database' | translate }} {{ 'Version' | translate }}: {{ systemInformation.siDatabaseVersion }}</h5>

  <br /><br />

        <button (click)="fwUpgradeDatabase()" class="btn btn-danger" id="dropdownBasic1">{{ 'Database' | translate }} {{ 'Upgrade' | translate }} </button>

</div>
