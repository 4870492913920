 import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../../core/base.service';
import { User } from '../../../models/security/user';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService<User> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Users/';
  }

  getUserByUserName(username: string): Observable<User> {
    return this.http.get<User>(this.myAppUrl + this.myApiUrl + 'GetByUsername/' + username)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }
}
