import { Component, OnInit, forwardRef, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlComponentBase } from '../../../core/control-component-base';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Role } from '../../../models/security/role';
import { RoleService } from '../../../services/security/roles/role.service';
import { ComboBoxItem } from '../combobox-entry/combobox-entry.component';


export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => MultiComboboxEntryComponent),
};

@Component({
  selector: 'MultiComboBoxEntry',
  templateUrl: './multicombobox-entry.component.html',
  styleUrls: ['./multicombobox-entry.component.scss'],
  providers: [CONTROL_VALUE_ACCESSOR]
})
export class MultiComboboxEntryComponent extends ControlComponentBase implements ControlValueAccessor, OnInit {
  @Input() items: ComboBoxItem[] = [];
  
  private innerValue: any;

  constructor(public roleService: RoleService) {
    super();

    this.items = [];
      
  }

  selectAll() {
    this.Value = this.items.map(x => x.value);
  }

  unselectAll() {
    this.Value = [];
  }

  ngOnInit(): void {  
    this.Value = this.items.map(x => x.value);
  }

  ngAfterViewInit(): void {
    this.Value = this.items.map(x => x.value);
    console.log('after in it');
  }

  get Value(): any {
    return this.innerValue;
  }

  set Value(value: any) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.onchange(value);
    }
  }

  writeValue(value: any) {
    this.innerValue = value;
  }

  onchange = (_) => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => void): void { this.onchange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}

