<form [formGroup]="parameters" #formDir="ngForm" novalidate>

  <!--<MultiComboBoxEntry [Label]="'Role'"
                      [items]="roles"
                      formControlName="role">

  </MultiComboBoxEntry>-->

  <ComboBoxEntry [Label]="'Role'"
                 formControlName="role">
    <option *ngFor="let role of roles" [value]="role.value">
      {{role.name}}
    </option>
  </ComboBoxEntry>


</form>


<!--<form [formGroup]="parameters" #formDir="ngForm" novalidate>

  <ComboBoxEntry [Label]="'Role'"
                 formControlName="role">
    <option [value]="All">
      Select All
    </option>

    <option *ngFor="let role of roles" [value]="role.value">
      {{role.name}}
    </option>
  </ComboBoxEntry>

</form>-->
