<div class="row">
  <!--<label class="col-sm-2" for="Input">{{ Label }}</label>-->

  <div >
  
    <select style="height:24px;"
            class="formcontrol"
            [ngClass]="[sizeClass]"
            [(ngModel)]="Value">
      <option disabled selected></option>
      <ng-content></ng-content>
    </select>

  </div>
</div>



