import { EventEmitter, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '../../../services/security/login/login.service';

@Injectable()
export class MenuService {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router,
    private loginService: LoginService  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public toggleNav() {
    this.appDrawer.toggle();
  }

  public navIsOpen() {
    console.log(this.appDrawer.opened);
     return this.appDrawer.opened;
  }
}
