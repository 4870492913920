import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "../../../../services/security/users/user.service";

@Component({
  selector: 'user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  router: Router;
  isItemSelected$: boolean;
  Test: boolean;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.isItemSelected$.subscribe(
      selectedItem => {
        //this.Test = false;
        //this.Test = true;
        //this.Test = false;
        this.isItemSelected$ = selectedItem;
      });
  }
}
