import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Role } from '../../../../models/security/role';
import { RoleService } from '../../../../services/security/roles/role.service';
import { MatDialog } from '@angular/material/dialog';
import { AccessSettingService } from '../../../../services/security/access-setting/access-setting.service';
import { Guid } from "guid-typescript";
import { DetailComponentBase } from '../../../../core/detail-component-base';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../../../services/security/login/login.service';
import { AuditActionService } from '../../../../services/audit/audit-action/audit-action.service';

@Component({
  selector: 'RoleDetail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent extends DetailComponentBase implements OnInit {
  //Form Fields
  id: string;
  errorMessage: any;
  existingRole: Role;
  role: Role;
  selectedRole: Role;

  accessSettings: string[];

  securityItems: any;
  selectedSecurityItems: string[];

  constructor(private roleService: RoleService,
    private accessSettingService: AccessSettingService,
    auditActionService: AuditActionService,
    public formBuilder: UntypedFormBuilder,
    loginService: LoginService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {
    super(snackBar, loginService, auditActionService);

    this.itemService = roleService;
    this.model = Role;


    this.actionType = 'Add';
    this.itemType = 'Role';
    this.uniqueFieldName = 'Code';

    this.securityItems = this.accessSettingService.getSecurityItemsJSON();
    //this.securityItems = SecurityItems;
      
    //LOAD ACCESS SETTINGS
    this.loadAccessSettings();


    this.form = new UntypedFormGroup({
      roKey: new UntypedFormControl(''),
      roName: new UntypedFormControl('', [Validators.required]),
      roCode: new UntypedFormControl('', [Validators.required]),
      roDescription: new UntypedFormControl('')
    });

  }

  ngOnInit() {

    this.itemService.getIsNewItem().subscribe(isNewItem => {
      //this.isNewItem$ = isNewItem;

      if (isNewItem === true) {
        console.log('newitemloggedondetailview');
        this.form.reset();
        this.form.markAsPristine();


        this.form = new UntypedFormGroup({
          roKey: new UntypedFormControl(''),
          roName: new UntypedFormControl('', [Validators.required]),
          roCode: new UntypedFormControl('', [Validators.required]),
          roDescription: new UntypedFormControl('')
        });


        //this.primaryKey = undefined;
      }


    });

    super.ngOnInit();

    this.roleService.getSelectedItemDetail().subscribe(selectedItem => {
      this.item$ = selectedItem;
      this.primaryKey = this.item$.roKey;
      this.selectedItemName = this.item$.roName;

      if (this.primaryKey.length == 36) {
        this.actionType = 'Edit';
        this.roleService.get(this.primaryKey)
          .subscribe((data: Role) => (
            this.existingRole = data,
            this.form = this.formBuilder.group(selectedItem)
          ));
      }
    });

  }



  setExistingItem() {
    if (this.primaryKey.length == 36) {
      this.actionType = 'Edit';
      this.roleService.get(this.primaryKey)
        .subscribe((data: Role) => (
          this.existingRole = data,
          this.form.controls.roKey.setValue(this.primaryKey)
        ));
    }
  }



  loadAccessSettings() {
    this.accessSettingService.getListPerRole(this.itemService.item.roKey)
      .subscribe(result => {
        this.accessSettings = result;
      });
  }


  //reading events emitted by app-child component
  readOutputValueEmitted(val) {
    this.selectedSecurityItems = val;
    this.selectedSecurityItems

    if (this.selectedSecurityItems !== undefined) {
      if (this.selectedSecurityItems.length > 0) {
        if (this.accessSettings.length !== this.selectedSecurityItems.length) {
            this.form.markAsDirty();
        }
      }
    }

  }


  delete() {
    var activeUser = '';
    var activeUserRole = '';

    this.loginService.getUser().subscribe(user => {
      activeUser = user.usKey;
      activeUserRole = user.usRoKey;
    });


    if (this.primaryKey != '23e35e52-70f2-4084-966b-e36015aabf6b') {

      if (activeUserRole != this.primaryKey) {
        super.delete();

      } else {
        this.snackBar.open("You can not delete the role that you are currently logged in with", "OK", {
          duration: 3000,
        });
      }
        
    } else {
      this.snackBar.open("Administrator role can not be deleted", "OK", {
        duration: 3000,
      });
    }
    
  }


  save() {
    let role = new Role();
    var nowDate = Date.now();

    role = Object.assign(role, this.form.value);

    var activeUser = '';
    var activeUserRole = '';

    this.loginService.getUser().subscribe(user => {
      activeUser = user.usKey;
      activeUserRole = user.usRoKey;
    });

    if (this.actionType === 'Add') {
      role.roKey = Guid.create().toString();
      this.primaryKey = role.roKey;
      //role.roName = this.form.controls.roName.value;
      //role.roCode = this.form.controls.roCode.value;
      //role.roDescription = this.form.controls.roDescription.value;

      role.createTime = new Date(nowDate);
      role.createUserId = activeUser;
      role.changeTime = null;
      role.changeUserId = '';
      role.deleteTime = null;
      role.deleteUserId = '';
      role.delFlag = 0;
    }

    if (this.actionType === 'Edit') {
      if (this.existingRole === undefined) {
        this.setExistingItem();
      }

      role.roKey = this.form.controls.roKey.value; 
      //role.roName = this.form.controls.roName.value; 
      //role.roCode = this.form.controls.roCode.value; 
      //role.roDescription = this.form.controls.roDescription.value; 

      role.createTime = this.existingRole.createTime;
      role.createUserId = this.existingRole.createUserId;
      role.changeTime = new Date(nowDate);
      role.changeUserId = activeUser;
      role.deleteTime = this.existingRole.deleteTime;
      role.deleteUserId = this.existingRole.deleteUserId;
      role.delFlag = this.existingRole.delFlag;
    }

    super.save(role, role.roKey);
    this.saveAccessSettings(role.roKey, this.selectedSecurityItems);
  }

  saveAccessSettings(itemKey: string, selectedSecurityItems:string[]) {
    if (this.actionType === 'Add') {
      this.accessSettingService.saveAccessSetting(itemKey, selectedSecurityItems)
        .subscribe((data) => {
          //Init after creating record

          this.snackBar.open("Saved successfully", "OK", {
            duration: 1000,
          });

          this.accessSettings = this.selectedSecurityItems;
          this.form.markAsPristine();
          this.itemService.setRefreshList(true);
        });
    }

    if (this.actionType === 'Edit') {
      this.accessSettingService.saveAccessSetting(itemKey, selectedSecurityItems)
        .subscribe((data) => {
          //Init after updating record

          this.snackBar.open("Saved successfully", "OK", {
            duration: 1000,
          });

          this.accessSettings = this.selectedSecurityItems;
          this.form.markAsPristine();
          this.itemService.setRefreshList(true);
        });
    }
  }
 
  //////get name() { return this.form.get(this.formName); }
  //////get code() { return this.form.get(this.formCode); }
  //////get description() { return this.form.get(this.formDescription); }
}
