import { ReplaySubject, Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../controls/dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../services/security/login/login.service';
import { AuditAction } from '../models/audit/auditAction';
import { AuditActionService } from '../services/audit/audit-action/audit-action.service';
import { Guid } from 'guid-typescript';
import { User } from '../models/security/user';

export class DetailComponentBase {
  //Properties
  auditAction: AuditAction;

  item$: any;
  itemService: any;

  primaryKey: any;
  model: any;
  form: UntypedFormGroup;

  actionType: string;
  itemType: string;
  selectedItemName: string;

  existingItem: any;
  isNewItem$: boolean;

  dialog: MatDialog;

  uniqueFieldName: string;

  deleteButtonClicked: boolean;

  saveSuccesful: boolean = false;

  constructor(
    private snackBarBase: MatSnackBar,
    public loginService: LoginService,
    public auditActionService: AuditActionService) {
    this.deleteButtonClicked = false;
  }

  ngOnInit() {
    this.itemService.getIsNewItem().subscribe(isNewItem => {
      this.isNewItem$ = isNewItem;
      this.deleteButtonClicked = false;

      if (this.isNewItem$ === true) {
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');
        console.log('newitemlogged');

     
        //this.form.controls.value 
        //this.closeDetailView();

       
        //this.itemService.setIsNewItem(true);
        //this.itemService.setIsItemSelected(true);
        //this.form.reset();
    

      }

      
    });

   
  }

  ngAfterViewInit(): void {
    //this.InitializeSorting();

    if (this.isNewItem$ === false) {
      this.auditAction = new AuditAction()
      var nowDate = Date.now();

      var activeUser = '';

      this.loginService.getUser().subscribe(user => {
        activeUser = user.usKey;
      });

      this.auditAction.aaKey = Guid.create().toString();
      this.auditAction.aaDescription = 'Viewed ' + this.itemType + ' record: ' + this.primaryKey;
      this.auditAction.aaTimeStamp = new Date(nowDate);
      this.auditAction.aaUsKey = activeUser;
      this.auditAction.changeUserId = '00000000-0000-0000-0000-000000000000';
      this.auditAction.createTime = new Date(nowDate);
      this.auditAction.createUserId = '00000000-0000-0000-0000-000000000000';
      this.auditAction.deleteUserId = '00000000-0000-0000-0000-000000000000';
      this.auditAction.delFlag = 0;

      //this.auditAction.aaUsKey = activeUser;
      //this.auditAction.aaUsKey = activeUser;
      //this.auditAction.aaUsKey = activeUser;


      this.AuditAction(this.auditAction);
    } else {
      this.form.reset();
    }
  }

  close() {
    this.itemService.item$.next(new ReplaySubject(1));
    this.itemService.item === undefined;
    this.itemService.setIsNewItem(false);

    this.itemService.setIsItemSelected(false);
  }

  closeDetailView() {
    if (this.form.dirty === true) {
      const message = `You have unsaved changes on this form. Are you sure you want to close this form and discard your changes?`;

      const dialogData = new ConfirmDialogModel("Discard Changes", message);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  delete() {
    this.deleteButtonClicked = true;
    const message = 'Are you sure you want to delete the ' + this.itemType + ': ' + this.selectedItemName + '?';
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    var activeUser = '';

    this.loginService.getUser().subscribe(user => {
      activeUser = user.usKey;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        //Prevent deletion of activeUser
        if (activeUser != this.primaryKey) {

          this.itemService.doDelete(this.primaryKey, activeUser).subscribe((data) => {

            if (data.result === 1) {
              this.itemService.setRefreshList(true);

              this.itemService.item$.next(new ReplaySubject(1));
              this.itemService.item == undefined;
              this.itemService.setIsNewItem(false);

              this.itemService.setIsItemSelected(false);

              this.deleteButtonClicked = false;

              //audit
              this.auditAction = new AuditAction()
              var nowDate = Date.now();

              var activeUser = '';

              this.loginService.getUser().subscribe(user => {
                activeUser = user.usKey;
              });

              this.auditAction.aaKey = Guid.create().toString();
              this.auditAction.aaDescription = 'Deleted ' + this.itemType + ' record: ' + this.primaryKey;
              this.auditAction.aaTimeStamp = new Date(nowDate);
              this.auditAction.aaUsKey = activeUser;
              this.auditAction.changeUserId = '00000000-0000-0000-0000-000000000000';
              this.auditAction.createTime = new Date(nowDate);
              this.auditAction.createUserId = '00000000-0000-0000-0000-000000000000';
              this.auditAction.deleteUserId = '00000000-0000-0000-0000-000000000000';
              this.auditAction.delFlag = 0;

              this.AuditAction(this.auditAction);
            } else if (data.result === 2) {
              this.snackBarBase.open("You can not delete a role that is currently assigned to users", "OK", {
                duration: 3000,
              });
            }

            



          });
        } else {

          this.snackBarBase.open("You can not delete the user that you are currently logged in with", "OK", {
            duration: 3000,
          });

          
        }

      }
    });
  }

  //delete(id: string, name: string) {
  //  const message = 'Are you sure you want to delete the ' + this.itemType + ': ' + name + '?';
  //  const dialogData = new ConfirmDialogModel("Confirm Action", message);
  //  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //    maxWidth: "400px",
  //    data: dialogData
  //  });

  //  dialogRef.afterClosed().subscribe(dialogResult => {
  //    if (dialogResult == true) {
  //      this.itemService.doDelete(id).subscribe((data) => {
  //        this.itemService.setRefreshList(true);

  //        this.itemService.item$.next(new ReplaySubject(1));
  //        this.itemService.item == undefined;
  //        this.itemService.setIsNewItem(false);

  //        this.itemService.setIsItemSelected(false);
  //      });
  //    }
  //  });
  //}

  saveSuccessful() {
    this.snackBarBase.open("Saved successfully", "OK", {
      duration: 1000,
    });

    this.actionType = 'Edit';
    this.saveSuccesful = true;

    try {
      this.form.controls.confirmNewPassword.setValue('');
      this.form.controls.confirmNewPassword.clearValidators();
      this.form.controls.confirmNewPassword.updateValueAndValidity();
    } catch (e) {
     
    }

    this.itemService.setRefreshList(true);
    this.form.markAsPristine();

  }

  save(item: any, itemID: string) {

    if (this.actionType === 'Add') {
      this.itemService.doSave(item)
        .subscribe((data) => {

          //0 = save unsuccessful
          //1 = save successful
          //2 = save unsuccessful, duplicate record 
          var saveResult = data.result;

          if (saveResult === 1) {
            //Init after saving new record
            this.saveSuccessful();


            this.auditAction = new AuditAction()
            var nowDate = Date.now();

            var activeUser = '';

            this.loginService.getUser().subscribe(user => {
              activeUser = user.usKey;
            });

            this.auditAction.aaKey = Guid.create().toString();
            this.auditAction.aaDescription = 'Saved new ' + this.itemType + ' record: ' + this.primaryKey;
            this.auditAction.aaTimeStamp = new Date(nowDate);
            this.auditAction.aaUsKey = activeUser;
            this.auditAction.changeUserId = '00000000-0000-0000-0000-000000000000';
            this.auditAction.createTime = new Date(nowDate);
            this.auditAction.createUserId = '00000000-0000-0000-0000-000000000000';
            this.auditAction.deleteUserId = '00000000-0000-0000-0000-000000000000';
            this.auditAction.delFlag = 0;

            //this.auditAction.aaUsKey = activeUser;
            //this.auditAction.aaUsKey = activeUser;
            //this.auditAction.aaUsKey = activeUser;


            this.AuditAction(this.auditAction);
          } else if (saveResult === 2) {

            var message = 'Unable to save. ' + this.itemType +' with same ' + this.uniqueFieldName + ' already exists';
            this.snackBarBase.open(message, "OK", {
              duration: 3000,
            });

          }

          

        });
    }

    if (this.actionType === 'Edit') {
      if (this.deleteButtonClicked === false) {
        this.itemService.doUpdate(itemID, item)
          .subscribe((data) => {

            var saveResult = data.result;

            if (saveResult === 1) {
              this.saveSuccessful();



              this.auditAction = new AuditAction()
              var nowDate = Date.now();

              var activeUser;

              this.loginService.getUser().subscribe(user => {
                activeUser = user.usKey;
              });



              this.auditAction.aaKey = Guid.create().toString();
              this.auditAction.aaDescription = 'Edited ' + this.itemType + ' record: ' + this.primaryKey;
              this.auditAction.aaTimeStamp = new Date(nowDate);
              this.auditAction.aaUsKey = activeUser;
              this.auditAction.changeUserId = '00000000-0000-0000-0000-000000000000';
              this.auditAction.createTime = new Date(nowDate);
              this.auditAction.createUserId = '00000000-0000-0000-0000-000000000000';
              this.auditAction.deleteUserId = '00000000-0000-0000-0000-000000000000';
              this.auditAction.delFlag = 0;

              //this.auditAction.aaUsKey = activeUser;
              //this.auditAction.aaUsKey = activeUser;
              //this.auditAction.aaUsKey = activeUser;


              this.AuditAction(this.auditAction);
            }
            else if (saveResult === 2) {

              var message = 'Unable to save. ' + this.itemType + ' with same ' + this.uniqueFieldName + ' already exists';
              this.snackBarBase.open(message, "OK", {
                duration: 3000,
              });

            }


          });
      }      
    }
  }



  AuditAction(action: AuditAction) {

    this.auditActionService.saveAuditActions('test', action)
        .subscribe((data) => {

          console.log('audit action logged');
          ////Init after saving new record
          //this.snackBarBase.open("Saved successfully", "OK", {
          //  duration: 1000,
          //});

          //this.form.markAsPristine();
        });

  }

 
}





