import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AccessSetting } from '../../../models/security/accessSetting';
import { BaseService } from '../../../core/base.service';
import { SecurityItem } from '../../../models/security/securityItem';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessSettingService extends BaseService<AccessSetting> {

  SecurityItems: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/AccessSettings/';
  }

  setSecurityItems(securityItems: any) {
    this.SecurityItems = securityItems;
  }

  public getSecurityItemsJSON(): Observable<any> {
    return this.SecurityItems;
  }

  GetSecurityItems(): Observable<SecurityItem[]> {
    return this.http.get<SecurityItem[]>(this.myAppUrl + this.myApiUrl + 'GetSecurityItems/')
  }




  GetMenu(roleId: string): Observable<string[]> {
    return this.http.get<string[]>(this.myAppUrl + this.myApiUrl + 'GetMenu/' + roleId, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  getListPerRole(roleId: string): Observable<string[]> {
    return this.http.get<string[]>(this.myAppUrl + this.myApiUrl + 'GetListPerRole/' + roleId)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  saveAccessSetting(roleKey: string, securityItems: string[]): Observable<AccessSetting> {
    return this.http.post<AccessSetting>(this.myAppUrl + this.myApiUrl + 'PostAccessSettings/' + roleKey, JSON.stringify(securityItems), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }
}
