import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginUser } from '../../../models/security/LoginUser';
import { User } from '../../../models/security/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  isUserLoggedIn = new ReplaySubject(1);
  user$ = new ReplaySubject(1);
  //user$: User;


  myAppUrl: string;
  myApiUrl: string;
  loginApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Users/';
    this.loginApiUrl = 'api/Login/';
  }

  public getIsUserLoggedIn(): Observable<any> {
    return this.isUserLoggedIn.asObservable();
  }

  public getUser(): Observable<any> {
    return this.user$.asObservable();
  }

  setisUserLoggedIn(isUserLoggedIn: boolean, activeUser: User) {
    this.isUserLoggedIn.next(isUserLoggedIn);
    this.user$.next(activeUser);
  }

  setisUserLoggedInOnly(isUserLoggedIn: boolean) {
    this.isUserLoggedIn.next(isUserLoggedIn);
    this.user$.next(undefined);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.myAppUrl + this.myApiUrl)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  doLogin(loginUser: LoginUser): Observable<any> {
    return this.http.post<User>(this.myAppUrl + this.loginApiUrl + 'DoLogin/', JSON.stringify(loginUser), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  //getUser(id: string): Observable<User> {
  //  return this.http.get<User>(this.myAppUrl + this.myApiUrl + id)
  //    .pipe(
  //      retry(1),
  //      catchError(this.errorHandler)
  //    );
  //}

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
