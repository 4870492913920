import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { User } from '../../../models/security/user';
import { UserService } from '../../../services/security/users/user.service';
import { DialogModel, DialogComponent } from '../../../controls/dialogs/dialog/dialog.component';
import { LoginUser } from '../../../models/security/LoginUser';
import { LoginService } from '../../../services/security/login/login.service';
import { MenuService } from '../../UI/menu/menu.service';



@Component({
  selector: 'app-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.scss']
})
export class SwitchUserComponent implements OnInit {

  constructor(public dialog: MatDialog,
     public router: Router) {
    const dialogRef = this.dialog.open(SwitchUserDialog, {
      width: "80%",
      height: "70%",
      backdropClass: 'ReportDialogBackground',
      panelClass: 'ReportDialogClass',
    });

    //   const dialogRef = this.dialog.open(LoginComponent, {
    //  width: "80%",
    //  height: "50%",
    //  backdropClass: 'ReportDialogBackground',
    //  panelClass: 'ReportDialogClass',
    //});

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.router.navigate(['/home']);
    });

  }

  ngOnInit() {
  }

}


@Component({
  selector: 'app-switch-user-dialog',
  templateUrl: './switch-user-dialog.component.html',
  styleUrls: ['./switch-user-dialog.component.scss']
})
export class SwitchUserDialog {
  users$: Observable<User[]>;
  gotuser: User;

  username: string;
  password: string;

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(1)])
  });

  get form() {
    return this.loginForm.controls;
  }

  constructor(
    public router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private cookieService: CookieService,
    private menuService: MenuService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SwitchUserDialog>) {

  }



  onKeydown(event) {
    if (event.key === "Enter") {
      //console.log(event);
      this.doLogin();
    }
  }
                  

  doLogin() {
    //Name: Jose de Jesus;
    //Function: Validate that the login form fields are not empty;
    if (this.username!= '' && this.password != '') {
      this.Login();
    } else {

      const message = `Please fill in all fields`;
      const dialogData = new DialogModel("Login", message);

      const dialogRef = this.dialog.open(DialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

    }
  }

  Login() {
    var loginDetails = new LoginUser();

    loginDetails.username = this.username;
    loginDetails.password = this.password;

    console.log(loginDetails);

    this.loginService.doLogin(loginDetails).subscribe(loginResponse => {

      switch (loginResponse.result) {
        case 1: {

          var menuOpenedState = this.menuService.navIsOpen();

          if (menuOpenedState == false) {
            this.menuService.toggleNav();
          }

          localStorage.setItem('jwt', 'localstorage test is working');
          localStorage.setItem('user', loginResponse.user.usUserName);
          localStorage.setItem('userRole', loginResponse.user.usRoKey);

          //   localStorage.clear

          var localStorageUser = localStorage.getItem('user');
          localStorage.setItem('userre', localStorageUser);



          this.gotuser = loginResponse.user;
          this.cookieService.set("ActiveUser", this.gotuser.usUserName);
          //mark user as logged in login service
          this.loginService.setisUserLoggedIn(true, this.gotuser);



          this.router.navigate(['/home']);
          break;
        }
        case 2: {

          const message = `Username not found`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 3: {

          const message = `Incorrect password`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 4: {

          const message = `Account Disabled. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 5: {

          const message = `Account Deleted. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 6: {

          const message = `The user role was deleted. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 7: {

          const message = `The user password is expired. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
      }


    });
  }

  onUsernameChange(e) {
    // Fired one time when the focus outside the input, not need ngmodel value for fired
    this.username = e.target.value;
  }

  onPasswordChange(e) {
    // Fired one time when the focus outside the input, not need ngmodel value for fired
    this.password = e.target.value;
  }


  //onNoClick(): void {

  //  this.router.navigate(['home']);
  //  this.dialogRef.close();

  //}

  //onYesClick(): void {

  //  this.router.navigate(['']);
  //  this.dialogRef.close();

  //  //mark user as logged in login service
  //  this.loginService.setisUserLoggedIn(false, null);

  //}

  closeView(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }


}
