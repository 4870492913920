<!--<div fxFlex fxLayout="row"  class="w-mat-timepicker test">
	<mat-form-field fxFlex class="timeContainer" appearance="{{appearance}}">
		<mat-label>{{ label }}</mat-label>
		<input matInput class="timeInput" placeholder="Select time" id="time_Control" name="time_Control" [value]="time()">
		<mat-icon matSuffix (click)="showPicker()" style="margin-right:-10px">access_time</mat-icon>
	</mat-form-field>
</div>-->
<!--<div fxFlex fxLayout="row" class="w-mat-timepicker test">
	<div fxFlex class="timeContainer" appearance="{{appearance}}">
		<mat-label>{{ label }}</mat-label>
		<input matInput class="formcontrol" placeholder="Select time" id="time_Control" name="time_Control" [value]="time()">
		<mat-icon matSuffix (click)="showPicker()" style="margin-left: -20px; font-size: 15px; font-weight: 600; margin-top: 3px; position: absolute; ">access_time</mat-icon>
	</div>
</div>-->

<div fxFlex fxLayout="row" class="w-mat-timepicker test">
	<div fxFlex class="timeContainer" (click)="showPicker()" appearance="{{appearance}}">
		<mat-label>{{ label }}</mat-label>
		<input matInput class="formcontrol" placeholder="Select time" id="time_Control" name="time_Control" [value]="time()">
		<mat-icon matSuffix style="margin-left: -20px; font-size: 15px; font-weight: 600; margin-top: 3px; position: absolute; ">access_time</mat-icon>
	</div>
</div>
