import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/security/user';
import { Observable } from 'rxjs';
import { LoginService } from '../../../services/security/login/login.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})

export class MyAccountComponent implements OnInit {
  user$: User;

  constructor(private loginService: LoginService) {
    this.user$ = new User;
  }

  ngOnInit() {
    this.loginService.getUser().subscribe(user => {
      this.user$ = user;
    });
  }

}












//import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
//import { FormBuilder, Validators } from '@angular/forms';
//import { User } from "src/app/models/security/user";
//import { UserService } from "src/app/services/security/users/user.service";
//import { MatDialog } from '@angular/material/dialog';
//import { DetailComponentBase } from '../../base/detail-component-base';
//import { Role } from '../../../models/security/role';
//import { RoleService } from '../../../services/security/roles/role.service';
//import { LoginService } from '../../../services/login/login.service';

//@Component({
//  selector: 'app-my-account',
//  templateUrl: './my-account.component.html',
//  styleUrls: ['./my-account.component.scss']
//})

//export class MyAccountComponent extends DetailComponentBase implements OnInit, AfterViewInit {
//  @Input() MyAccount: boolean;
//  @Input() ActiveUser: User;

//  //Form Fields
//  formUserId: string;
//  formRole: string;
//  formUsername: string;
//  formFirstName: string;
//  formLastName: string;
//  formPassword: string;
//  formNewPassword: string;
//  formConfirmNewPassword: string;
//  formImage: string;
//  formCode: string;
//  formEmail: string;
//  formStatus: string;
//  formLanguage: string;
//  formPasswordExpirationDays: string;
//  formPasswordExpirationDate: string;
//  formPasswordLength: string;

//  id: string;

//  roles: Role[];
//  selectedRole: string;


//  constructor(private userService: UserService,
//    private loginService: LoginService,
//    private roleService: RoleService,
//    private formBuilder: FormBuilder,
//    public dialog: MatDialog) {
//    super();


//    this.itemService = userService;
//    this.model = User;

//    this.actionType = 'Add';
//    this.itemType = 'User';


//    this.formUserId = 'userId';
//    this.formRole = 'role';
//    this.formUsername = 'userName';
//    this.formFirstName = 'firstName';
//    this.formLastName = 'lastName';
//    this.formPassword = 'password';
//    this.formNewPassword = 'newPassword';
//    this.formConfirmNewPassword = 'confirmNewPassword';
//    this.formCode = 'code';
//    this.formEmail = 'email';
//    this.formStatus = 'status';
//    this.formLanguage = 'language';
//    this.formPasswordExpirationDays = 'passwordExpirationDays';
//    this.formPasswordExpirationDate = 'passwordExpirationDate';
//    this.formPasswordLength = 'passwordLength';

//    this.form = this.formBuilder.group(
//      {
//        userId: [''],
//        role: [''],
//        userName: ['', [Validators.required]],
//        firstName: ['', [Validators.required]],
//        lastName: ['', [Validators.required]],
//        password: [''],
//        newPassword: [''],
//        confirmNewPassword: [''],
//        code: ['', [Validators.required]],
//        email: ['', [Validators.required]],
//        status: ['', [Validators.required]],
//        language: ['English', [Validators.required]],
//        passwordExpirationDays: ['', [Validators.required]],
//        passwordExpirationDate: [null, [Validators.required]],
//        passwordLength: ['', [Validators.required]],
//      }
//    )
//  }

//  ngOnInit() {
//    super.ngOnInit();

//    //LOAD ROLES
//    this.loadRoles();

//    let activeUser = this.ActiveUser;



//    this.loginService.getUser().subscribe(user => {

//      this.item$ = user;

//      if (activeUser != undefined) {
//        this.item$ = activeUser;
//      }

//      this.formImage = ''
//      this.primaryKey = this.item$.usKey;

//      this.selectedItemName = this.item$.usFirstName;

//      if (this.primaryKey.length == 36) {
//        this.actionType = 'Edit';
//        this.userService.get(this.primaryKey)
//          .subscribe((data: User) => (
//            this.existingItem = data,
//            this.formImage = (data.usImage == '' ? './assets/images/userimage.png' : data.usImage),
//            this.form.controls[this.formUserId].setValue(data.usKey),
//            this.form.controls[this.formRole].setValue(data.usRoKey),
//            this.form.controls[this.formUsername].setValue(data.usUserName),
//            this.form.controls[this.formFirstName].setValue(data.usFirstName),
//            this.form.controls[this.formLastName].setValue(data.usLastName),
//            this.form.controls[this.formPassword].setValue(data.usPassword),
//            this.form.controls[this.formNewPassword].setValue(''),
//            this.form.controls[this.formConfirmNewPassword].setValue(''),
//            this.form.controls[this.formCode].setValue(data.usCode),
//            this.form.controls[this.formEmail].setValue(data.usEmail),
//            this.form.controls[this.formStatus].setValue(data.usStatus),
//            this.form.controls[this.formLanguage].setValue(data.usLanguage),
//            this.form.controls[this.formPasswordExpirationDays].setValue(data.usPasswordExpirationDays),
//            this.form.controls[this.formPasswordExpirationDate].setValue(new Date(data.usPasswordExpirationDate).toISOString().split('T')[0]),
//            this.form.controls[this.formPasswordLength].setValue(data.usPasswordLength)
//          ));
//      }
//    });
//  }

//  loadRoles() {
//    this.roleService.getList()
//      .subscribe(result => {
//        this.roles = result;
//        this.form.controls[this.formRole].setValue(this.roles[0].roKey);
//      });
//  }

//  save() {
//    let user = new User();
//    var nowDate = Date.now();

//    if (this.actionType === 'Add') {
//      user.usKey = '00000000-0000-0000-0000-000000000000';
//      user.usRoKey = '00000000-0000-0000-0000-000000000000';
//      user.usUserName = this.form.get(this.formUsername).value;
//      user.usPassword = this.form.get(this.formPassword).value;

//      user.usFirstName = this.form.get(this.formFirstName).value;
//      user.usLastName = this.form.get(this.formLastName).value;
//      user.usAddress = '';


//      user.usCode = this.form.get(this.formCode).value;
//      user.usEmail = this.form.get(this.formEmail).value;
//      user.usStatus = Number(this.form.get(this.formStatus).value);
//      user.usLanguage = this.form.get(this.formLanguage).value;
//      user.usImage = this.formImage;
//      user.usPasswordExpirationDays = Number(this.form.get(this.formPasswordExpirationDays).value);
//      user.usPasswordExpirationDate = this.form.get(this.formPasswordExpirationDate).value;
//      user.usPasswordLength = Number(this.form.get(this.formPasswordLength).value);


//      user.createTime = new Date(nowDate);
//      user.createUserId = '8ed901cb-5d9d-42ec-9cdb-6425b81bc0b0';
//      user.changeTime = null;
//      user.changeUserId = '';
//      user.deleteTime = null;
//      user.deleteUserId = '';
//      user.delFlag = 0;
//    }

//    if (this.actionType === 'Edit') {
//      user.usKey = this.form.get(this.formUserId).value;
//      user.usRoKey = this.form.get(this.formRole).value;
//      user.usUserName = this.form.get(this.formUsername).value;
//      user.usPassword = this.form.get(this.formPassword).value;
//      user.usFirstName = this.form.get(this.formFirstName).value;
//      user.usLastName = this.form.get(this.formLastName).value;
//      user.usAddress = '';
//      user.usCode = this.form.get(this.formCode).value;
//      user.usEmail = this.form.get(this.formEmail).value;
//      user.usStatus = 1;
//      user.usLanguage = this.form.get(this.formLanguage).value;
//      user.usImage = this.formImage;
//      user.usPasswordExpirationDays = this.form.get(this.formPasswordExpirationDays).value;
//      user.usPasswordExpirationDate = this.form.get(this.formPasswordExpirationDate).value;
//      user.usPasswordLength = this.form.get(this.formPasswordLength).value;

//      user.createTime = this.item$.createTime;
//      user.createUserId = this.item$.createUserId;
//      user.changeTime = new Date(nowDate);
//      user.changeUserId = this.item$.changeUserId;
//      user.deleteTime = this.item$.deleteTime;
//      user.deleteUserId = this.item$.deleteUserId;
//      user.delFlag = this.item$.delFlag;

//      //check if new password fields are dirty
//      if (this.form.get(this.formNewPassword).value != '' &&
//        this.form.get(this.formConfirmNewPassword).value != '') {

//        //check if new password fields match
//        if (this.form.get(this.formNewPassword).value == this.form.get(this.formConfirmNewPassword).value) {
//          user.usPassword = this.form.get(this.formNewPassword).value;
//        } else {
//          // return message to user 
//        }
//      }
//    }

//    super.save(user, user.usKey);
//  }

//  fileChange(fileInputEvent: any) {
//    const file = fileInputEvent.target.files[0];
//    const reader = new FileReader();
//    reader.readAsDataURL(file);
//    reader.onload = () => {
//      this.formImage = reader.result as string;
//      console.log(reader.result as string);
//    };
//  }

//  get userId() { return this.form.get(this.formUserId); }
//  get username() { return this.form.get(this.formUsername); }
//  get firstname() { return this.form.get(this.formFirstName); }
//  get lastname() { return this.form.get(this.formLastName); }
//  get password() { return this.form.get(this.formPassword); }
//  get image() { return this.form.get(this.formImage); }
//  get code() { return this.form.get(this.formCode); }
//  get email() { return this.form.get(this.formEmail); }
//  get status() { return this.form.get(this.formStatus); }
//  get language() { return this.form.get(this.formLanguage); }
//  get passwordExpirationDays() { return this.form.get(this.formPasswordExpirationDays); }
//  get passwordExpirationDate() { return this.form.get(this.formPasswordExpirationDate); }
//  get passwordLength() { return this.form.get(this.formPasswordLength); }
//}
