import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemInformation } from '../../../models/system/systeminformation';
import { AdminService } from '../../../services/system/admin/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  systemInformation: SystemInformation;

  constructor(private adminService: AdminService) { 
  }

  ngOnInit(): void {
    this.fwLoadSystemInformation();
  }

  //Name: Jose de Jesus
  //Function: Call service to get system information
  fwLoadSystemInformation() {
    this.adminService
      .GetSystemInformation<SystemInformation>()
      .subscribe((data: SystemInformation) => this.systemInformation = data);
  }

  //Name: Jose de Jesus
  //Function: Call service to start database upgrade
  fwUpgradeDatabase() {
    this.adminService.UpgradeDatabase(this.systemInformation);
  }
}
