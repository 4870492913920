import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Role } from '../../../models/security/role';
import { BaseService } from '../../../core/base.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService<Role> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);

    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Roles/';
  }


}
