import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { AuditAction } from '../models/audit/auditAction';

@Injectable({
  providedIn: 'root'
})

export class BaseService<TItem> {
  myAppUrl: string;
  myApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  item$ = new ReplaySubject(1);
  item: any;

  refreshList = new Subject<boolean>();
  refreshList$ = this.refreshList.asObservable();

  isItemSelected = new Subject<boolean>();
  isItemSelected$ = this.isItemSelected.asObservable();

  isNewItem = new ReplaySubject(1);
  //isNewItem = new Subject<boolean>();
  //isNewItem$ = this.isNewItem.asObservable();






  constructor(public http: HttpClient) {

  }

  setRefreshList(refreshList: boolean) {
    this.refreshList.next(refreshList);
  }

  setIsItemSelected(isItemSelected: boolean) {
    this.isItemSelected.next(isItemSelected);
  }





  setItem(selectedItem: any) {
    this.item$.next(selectedItem);
    this.item = selectedItem;
  }

  public getSelectedItemDetail(): Observable<any> {
    return this.item$.asObservable();
  }

  public getSelectedReportDetail(): Observable<any> {
    return this.item;
  }



  setIsNewItem(isNewItem: boolean) {
    this.isNewItem.next(isNewItem);
  }

  public getIsNewItem(): Observable<any> {
    return this.isNewItem.asObservable();
  }









  //GET LIST
  getList(): Observable<TItem[]> {
    return this.http.get<TItem[]>(this.myAppUrl + this.myApiUrl)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }


  //GET
  get(id: string): Observable<TItem> {
    return this.http.get<TItem>(this.myAppUrl + this.myApiUrl + 'GetById/' + id)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }


  //DO UPDATE
  doUpdate(id: string, item): Observable<TItem> {
    return this.http.put<TItem>(this.myAppUrl + this.myApiUrl + id, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }


  //DO SAVE
  doSave(item): Observable<TItem> {
    return this.http.post<TItem>(this.myAppUrl + this.myApiUrl, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }


  //DO DELETE
  doDelete(id: string, activeUserId: string): Observable<TItem> {
    //return this.http.delete<TItem>(this.myAppUrl + this.myApiUrl + id)
    //  .pipe(
    //    retry(1),
    //    catchError(this.errorHandler)
    //  );

    return this.http.put<TItem>(this.myAppUrl + this.myApiUrl + 'DoDelete/' + id, JSON.stringify(activeUserId), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );

  }


  //doAuditAction(auditAction): Observable<AuditAction> {

  //  return this.http.post<AuditAction>(this.myAppUrl + this.myApiUrl + 'DoAuditAction/' + 'test', JSON.stringify(auditAction), this.httpOptions)
  //    .pipe(
  //      retry(1),
  //      catchError(this.errorHandler)
  //    );


  //}






  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
