<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <!-- <div class="col-md-8" style="position: absolute !important;width: 215px !important; height: 25px; background: white; padding: 0px; margin-left: 205px;">  -->
  <div class="col-md-8" style="position: absolute !important; margin-left: 165px; max-width: 675px; width:auto;">

    <ng-select #combo
               [items]="items"
               [multiple]="true"
               bindLabel="name"
               [selectableGroup]="true"
               [closeOnSelect]="false"
               bindValue="value"
               [(ngModel)]="Value">

      <ng-template ng-header-tmp>
        <button (click)="selectAll()" class="btntest btn btn-sm btn-secondary" style="margin-right: 10px;">Select all</button>
        <button (click)="unselectAll()" class="btntest btn btn-sm btn-secondary">Unselect all</button>
      </ng-template>

      <!--Display All if all items are selected-->
      <ng-template *ngIf="Value.length === items.length" ng-multi-label-tmp let-clear="clear" let-item="item" let-item$="item$" let-index="index">
        <span class="ng-value-label"> ALL </span>
      </ng-template>


      <!--Display selected items-->
      <ng-template *ngIf="Value.length < items.length" let-clear="clear" let-item="item" let-item$="item$" let-index="index">
        <mat-checkbox (click)="false" id="item-{{index}}" [ngModel]="item$.selected">{{item.name}}</mat-checkbox>
      </ng-template>
      <!--Display selected items-->
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <mat-checkbox (click)="false" id="item-{{index}}" [ngModel]="item$.selected">{{item.name}}</mat-checkbox>
      </ng-template>

    </ng-select>
  </div>
</div>



