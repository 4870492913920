import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SystemInformation } from '../../../models/system/systeminformation';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Admin/';
  }

  //Name: Jose de Jesus
  //Function: Get system information
  GetSystemInformation<SystemInformation>(): Observable<SystemInformation> {
    return this.http.get<SystemInformation>(this.myAppUrl + this.myApiUrl);
  }

  //Name: Jose de Jesus
  //Function: Upgrade database
  UpgradeDatabase(systemInformation: SystemInformation) {
    this.http.post(this.myAppUrl + this.myApiUrl, JSON.stringify(systemInformation), this.httpOptions).subscribe(
      (response) => {
        return response;
      }
    )
  }

  UpdateSystemInformation(id: string, systemInformation): Observable<SystemInformation> {
    return this.http.put<SystemInformation>(this.myAppUrl + this.myApiUrl + id, JSON.stringify(systemInformation), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
