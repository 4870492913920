<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">
    <!--<input class="formcontrol"
         style="width:50px"
         [value]="value"
         (blur)="onTouched()"
         (input)="onChange($event.target.value)"
         maxlength="3"
         type="text"
         id="Input"
         [disabled]="IsDisabled">-->

    <select style="height:24px;padding: 2px;" class="formcontrol" [value]="selectedItem" (change)="change($event)">
      <!--<option [value]="0"></option>-->
      <option *ngFor="let item of items" [value]="item.id">{{ item.value }}</option>
    </select>

  </div>
</div>




<!--<div class="row">
  <label class="col-sm-2 " for="status">Status</label>

  <div class="col-md-8" [attr.disabled]="MyAccount">
    <select   formControlName="status">
      <option selected value="1">Enabled</option>
      <option value="0">Disabled</option>
    </select>
  </div>
</div>-->
