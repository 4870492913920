<body (keydown)="onKeydown($event)">

  <div id="form_wrapper">
    <div id="form_left">
      <img style="width: 320px; margin-left: 15%; margin-top: -10px;"
           src="./assets/images/TimeTrackerWeb/applogo.png" alt="icon">
      <!--    <h1>App Name</h1>-->

      <!--<p>Version: 1.0</p>-->
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
      <div id="form_right">
        <!--<h1>User Log In</h1>-->
        <div class="input_container">
          <i class="fas fa-envelope"></i>
          <input placeholder="Username"
                 type="email"
                 id="username"
                 formControlName="username"
                 class='input_field'>
        </div>
        <div class="input_container">
          <i class="fas fa-lock"></i>
          <input placeholder="Password"
                 type="password"
                 id="password"
                 formControlName="password"
                 class='input_field'>
        </div>
        <input type="submit" value="Login" id='input_submit' class='input_field'>


      </div>
    </form>
  </div>

</body>
