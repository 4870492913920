<!--<app-top-nav class="fixed-topnav" fxHide.gt-xs></app-top-nav>-->


<!--<mat-sidenav-container style="overflow:hidden;">-->

  <!--<mat-sidenav #appDrawer mode="side" opened="true" *ngIf="isUserLoggedIn$">-->
  <!--<mat-sidenav #appDrawer mode="side" opened="false">
    <img style="border-bottom: 1px solid grey; padding: 15px; display: block; margin-left: 5%; margin-right: 5%; width: 190px; height: 119px; "
         src="./assets/images/TimeTrackerWeb/applogo.png"
         alt="AppLogo">

    <mat-nav-list style="overflow:hidden">
      <app-menu-list-item *ngFor="let menuItem of MainMenu"
                          [item]="menuItem"
                          [routerLinkActive]="['is-active']">
      </app-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>-->
 
  <router-outlet>
    <ngx-ui-loader>

    



    </ngx-ui-loader>
  </router-outlet>


<!--</mat-sidenav-container>-->


<!--<ngx-ui-loader fgsSize="100" fgsType="three-strings"></ngx-ui-loader>-->
