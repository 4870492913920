import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ControlComponentBase } from '../../core/control-component-base';



export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => RadiobuttonEntryComponent),
};

@Component({
  selector: 'RadioButton',
  templateUrl: './radiobutton-entry.component.html',
  styleUrls: ['./radiobutton-entry.component.scss'],
  providers: [CONTROL_VALUE_ACCESSOR]
})

export class RadiobuttonEntryComponent extends ControlComponentBase implements ControlValueAccessor, OnInit {
  @Input() Items: string[];
  @Input() Orientation: string;
  //orientationClass: string;
  orientationClass = [];
  @Output() SelectedItem = new EventEmitter();

  selectedValue: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
   
  }

  radioChange(event: MatRadioChange) {
    this.SelectedItem.emit(this.selectedValue);
  }

  ngOnChanges() {
    if (this.Orientation) {
      
    }
  }

}
