<!--<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">

    <div *ngIf="Orientation == 'Vertical'">
      <mat-radio-group aria-labelledby="example-radio-group-label"
                       class="vertical"
                       [(ngModel)]="selectedValue">
        <mat-radio-button class="radio-button" *ngFor="let item of Items" [value]="item" (change)="radioChange($event)">
          {{item}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
 

    <div *ngIf="Orientation == 'Horizontal'">
      <mat-radio-group aria-labelledby="example-radio-group-label"
                       class="horizontal"
                       [(ngModel)]="selectedValue">

        <mat-radio-button class="radio-button" *ngFor="let item of Items" [value]="item" (change)="radioChange($event)">
          {{item}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
   

  </div>
</div>-->

  <div class="row">
    <label class="col-sm-2" for="Input">{{ Label }}</label>

    <div class="col-md-8">

      <mat-radio-group aria-labelledby="example-radio-group-label"
                       class="vertical radio-group"
                       [(ngModel)]="selectedValue">
        <mat-radio-button class="radio-button" *ngFor="let item of Items" [value]="item" (change)="radioChange($event)">
          {{item}}
        </mat-radio-button>
      </mat-radio-group>

    </div>
  </div>


<!--{{selectedValue}}-->
