import { ModelBase } from "../core/modelbase";

export class ReportItem extends ModelBase {
  name: string;
  description: string;
  columns: string[];
  orientation: string;
  businessClass: string;
  parameters: number;
}
