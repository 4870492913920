import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReportItem } from '../../models/reports/report';
import { User } from '../../models/security/user';
import { BaseService } from '../../core/base.service';
import { LoginService } from '../security/login/login.service';


@Injectable({
  providedIn: 'root'
})

export class ReportService extends BaseService<ReportItem> {
  reportParameters: any;
  selectedReportCategory: any;

  //User filter properties
  ShowUserFilter: any = false;
  UserFilterValues: any;


  //Role filter properties
  ShowRoleFilter: any = false;
  RoleFilterValues: any;

  //Project filter properties
  ShowProjectFilter: any = false;
  ProjectFilterValues: any;

  //SubProject filter properties
  ShowSubProjectFilter: any = false;
  SubProjectFilterValues: any;

  //SubProject filter properties
  ShowWorkTypeFilter: any = false;
  WorkTypeFilterValues: any;


  //Date Range filter properties
  ShowDateRangeFilter: any = false;
  DateRangeFilterValues: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  constructor(private http1: HttpClient,
  private loginService: LoginService) {
    super(http1);
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/Reports/';
  }


  getDate(dateSelection: number): Observable<any> {
    return this.http.get<any[]>(this.myAppUrl + this.myApiUrl + 'GetDate/' + dateSelection)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  setUserFilterValues(userFilterValues: any) {
    this.UserFilterValues = userFilterValues;
  }

  public getUserFilterValues(): Observable<any> {
    return this.UserFilterValues;
  }

  setShowUserFilter(showUserFilter: any) {
    this.ShowUserFilter = showUserFilter;
  }

  public getShowUserFilter(): Observable<any> {
    return this.ShowUserFilter;
  }

  setRoleFilterValues(roleFilterValues: any) {
    this.RoleFilterValues = roleFilterValues;
  }

  public getRoleFilterValues(): Observable<any> {
    return this.RoleFilterValues;
  }

  setShowRoleFilter(showRoleFilter: any) {
    this.ShowRoleFilter = showRoleFilter;
  }

  public getShowRoleFilter(): Observable<any> {
    return this.ShowRoleFilter;
  }


  setDateRangeFilterValues(dateRangeFilterValues: any) {
    this.DateRangeFilterValues = dateRangeFilterValues;
  }

  public getDateRangeFilterValues(): Observable<any> {
    return this.DateRangeFilterValues;
  }

  setShowDateRangeFilter(showDateRangeFilter: any) {
    this.ShowDateRangeFilter = showDateRangeFilter;
  }

  public getShowDateRangeFilter(): Observable<any> {
    return this.ShowDateRangeFilter;
  }

  setProjectFilterValues(projectFilterValues: any) {
    this.ProjectFilterValues = projectFilterValues;
  }

  public getProjectFilterValues(): Observable<any> {
    return this.ProjectFilterValues;
  }

  setShowProjectFilter(showProjectFilter: any) {
    this.ShowProjectFilter = showProjectFilter;
  }

  public getShowProjectFilter(): Observable<any> {
    return this.ShowProjectFilter;
  }


  setSubProjectFilterValues(subprojectFilterValues: any) {
    this.SubProjectFilterValues = subprojectFilterValues;
  }

  public getSubProjectFilterValues(): Observable<any> {
    return this.SubProjectFilterValues;
  }

  setShowSubProjectFilter(showSubProjectFilter: any) {
    this.ShowSubProjectFilter = showSubProjectFilter;
  }

  public getShowSubProjectFilter(): Observable<any> {
    return this.ShowSubProjectFilter;
  }




  setWorkTypeFilterValues(worktypeFilterValues: any) {
    this.WorkTypeFilterValues = worktypeFilterValues;
  }

  public getWorkTypeFilterValues(): Observable<any> {
    return this.WorkTypeFilterValues;
  }

  setShowWorkTypeFilter(showWorkTypeFilter: any) {
    this.ShowWorkTypeFilter = showWorkTypeFilter;
  }

  public getShowWorkTypeFilter(): Observable<any> {
    return this.ShowWorkTypeFilter;
  }






  setReportParameters(parameters: any) {
   // this.item$.next(parameters);
    this.reportParameters = parameters;
  }

  public getReportParameters(): Observable<any> {
    //return this.reportParameters.asObservable();
    return this.reportParameters;
  }









  setSelectedReportCategory(reportCategory: any) {
    // this.item$.next(parameters);
    this.selectedReportCategory = reportCategory;
  }

  public getSelectedReportCategory(): Observable<any> {
    //return this.reportParameters.asObservable();
    return this.selectedReportCategory;
  }

  getList(): Observable<ReportItem[]> {
    var activeUser = new User;
    var selectedReportCategory = this.getSelectedReportCategory();

    this.loginService.getUser().subscribe(user => {
      activeUser = user;
    });

 
    return this.http.post<ReportItem[]>(this.myAppUrl + this.myApiUrl + 'List/' + activeUser.usRoKey, JSON.stringify(selectedReportCategory), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  runReport(reportItemBusinessClass: string, reportParameters: any): Observable<any[]> {
    return this.http.post<any[]>(this.myAppUrl + this.myApiUrl + 'RunReport/' + reportItemBusinessClass, JSON.stringify(reportParameters), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

}
