import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Role } from '../../../models/security/role';
import { BaseService } from '../../../core/base.service';
import { AuditAction } from '../../../models/audit/auditAction';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditActionService extends BaseService<AuditAction> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);

    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/AuditActions/';
  }

  saveAuditActions(id: string, actions: AuditAction): Observable<AuditAction> {
    return this.http.post<AuditAction>(this.myAppUrl + this.myApiUrl + 'PostAuditActions/' + id, JSON.stringify(actions), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

}
