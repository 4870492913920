<button class="float-right" mat-icon-button id="close" (click)="closeDetailViewReportTest()">
  <mat-icon>close</mat-icon>
</button>


<h1>{{ item$.name | translate }}</h1>
<!--<h1>{{ item$.name | translate }}</h1>-->
<hr class="solid" style="margin-right:327px; margin-top: -15px">
<!--<form [formGroup]="form" #formDir="ngForm" novalidate>-->

<div>
  <div class="tab-content">

    <!--<label class="label"> {{ item$.description | translate }} </label>-->
    <!--<hr class="solid" style="margin-right:327px;">-->
    <!--Name Field-->
    <!--<TextEntry [Label]="'Name'"
               formControlName="name">
    </TextEntry>-->
    <!--Code Field-->
    <!--<CodeEntry [Label]="'Code'"
               formControlName="code">
    </CodeEntry>-->
    <!--Description Field-->
    <!--<TextEntry [Label]="'Description'"
               formControlName="description">
    </TextEntry>-->


    <ng-template #container></ng-template>

  </div>
</div>


<div class="form-group">
  <button (click)="RunReport(item$.businessClass, UsersReportComponent, item$.parameters)"
          class="btntest btn-lg btn btn-default float-left">
    {{ 'Run' | translate }}
  </button>


  <!--<button (click)="CloseTest()"
          class="btntest btn-lg btn btn-default float-left">
    Close
  </button>-->

</div>

<!--</form>-->
