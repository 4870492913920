import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { TreeBuilder } from "./utils/tree-builder";
import { ItemNode } from "./models/ItemNode.model";
import { ItemFlatNode } from "./models/ItemFlatNode.model";
import { BehaviorSubject, Observable, of as observableOf } from "rxjs";


@Component({
  selector: 'TreeViewWithoutCheckBox',
  templateUrl: './tree-view-without-checkbox.component.html',
  styleUrls: ['./tree-view-without-checkbox.component.scss'],
  providers: [TreeBuilder]
})
export class TreeViewWithoutCheckBoxComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() TreeViewLOAD: string[];
  @Input() TreeViewDATA: string;
  @Output() TreeViewSELECTION = new EventEmitter(); 

  treeControl: FlatTreeControl<ItemFlatNode>;
  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;
  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  constructor(private treeBuilder: TreeBuilder) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this._getLevel,
      this._isExpandable,
      this._getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this._getLevel,
      this._isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes["TreeViewLOAD"]) {
      console.log(this.TreeViewLOAD);


      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        let currentNode = this.treeControl.dataNodes[i];
        let matchFound = this.TreeViewLOAD.find(item => item === currentNode.seKey)

        if (matchFound !== undefined) {
          if (!this.checklistSelection.isSelected(currentNode))

            this.checklistSelection.toggle(currentNode);
          this.treeControl.expand(currentNode)
        }
      }
    }
  }

  ngOnInit(): void {
    console.log(this.TreeViewLOAD);

    //Pass binded JSON DATA to Tree View Data source
    this.dataSource.data = this.treeBuilder.buildTree(this.TreeViewDATA, 0)

    //emitting value to parent component using event emitter
    ////let selectedFromTree = this.checklistSelection.selected.map(f => f.seKey);
    //this.TreeViewSELECTION.emit(this.getSelected());
  }

  ngAfterViewInit() {
    ////Pass binded JSON DATA to Tree View Data source
    //this.dataSource.data = this.treeBuilder.buildTree(this.TreeViewDATA, 0)



    //for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
    //  //if (this.treeControl.dataNodes[i].item == 'Fruits') {
    //  //  this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
    //  //  this.treeControl.expand(this.treeControl.dataNodes[i])
    //  //}


    //  for (var item in this.TreeViewLOAD) // for acts as a foreach  
    //  {
    //    if (this.treeControl.dataNodes[i].seKey == item) {
    //      this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
    //      //this.treeControl.expand(this.treeControl.dataNodes[i])
    //    }

    //    //accessSetting.seKey
    //    //this.selectedSecurityItems
    //    //alert(array[v]);
    //  } 
    //}
  }

  transformer = (node: ItemNode, level: number) => {
    return new ItemFlatNode(
      !!node.children,
      node.id,
      node.name,
      node.seKey,
      level
    );
  };

  private _getLevel = (node: ItemFlatNode) => node.level;

  private _isExpandable = (node: ItemFlatNode) => node.expandable;

  private _getChildren = (node: ItemNode): Observable<ItemNode[]> =>
    observableOf(node.children);

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    return descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  getSelected = () => this.TreeViewSELECTION.emit(this.checklistSelection.selected.map(f => f.seKey));
}
