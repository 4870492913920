<div *ngIf="isItemSelected$; then thenBlock else elseBlock"></div>

<!--LIST-->
<ng-template #thenBlock>
  <div class="list-column">
    <RoleList></RoleList>
  </div>
</ng-template>

<ng-template #elseBlock>
  <div class="list-column-unselected">
    <RoleList></RoleList>
  </div>
</ng-template>

<!--DETAIL-->
<div class="detail-column" *ngIf="isItemSelected$">
  <RoleDetail></RoleDetail>
</div>
