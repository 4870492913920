/* src/app/FriendsWithBenefits/components/forms/form-view/form-view.component.scss */
.container {
  height: 90dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./media/abc.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.top-image {
  width: 700px;
  height: 85px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.inner-container {
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 600px;
  border: 1px solid #000;
  border-radius: 5px;
  overflow: hidden;
  z-index: 10;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 515px;
  gap: 15px;
  background-color: rgba(240, 248, 255, 0.9);
}
.store-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
}
.field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 550px;
}
.form-label {
  font-size: 24px;
  width: 180px;
}
.form-input {
  width: 350px;
  font-size: 24px;
  text-align: left;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: white;
}
.form-input-dateOfBirth-container {
  display: flex;
  width: 350px;
  font-size: 24px;
  text-align: left;
  border-radius: 3px;
  box-sizing: border-box;
  justify-content: space-between;
}
.form-input-dateOfBirth-field-day {
  border: 1px solid black;
  border-radius: 3px;
  width: 22%;
  font-size: 24px;
  padding: 5px 5px;
  text-align: center;
}
.form-input-dateOfBirth-field-month {
  border: 1px solid black;
  border-radius: 3px;
  width: 46%;
  font-size: 24px;
  padding: 5px 5px;
  text-align: center;
}
.form-input-dateOfBirth-field-year {
  border: 1px solid black;
  border-radius: 3px;
  width: 28%;
  font-size: 24px;
  padding: 5px 5px;
  text-align: center;
}
.no-arrow::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.no-arrow::-webkit-inner-spin-button,
.no-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrow {
  -moz-appearance: textfield;
}
.form-header {
  height: 125px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form-header p {
  font-size: 24px;
  margin: 0;
  text-align: center;
}
.button-container {
  display: flex;
  justify-content: center;
  width: 550px;
  gap: 20px;
}
.btn {
  font-size: 24px;
  height: 60px;
  width: 275px;
  padding: 5px 8px;
  color: white;
  border: none;
  border-radius: 5px;
  border: 1px solid black;
}
.btn-reset {
  background-color: indianred;
}
.btn-reset:hover {
  background-color: darkred;
}
.btn-submit {
  background-color: mediumseagreen;
}
.btn-submit:hover {
  background-color: seagreen;
}
.btn-submit:disabled {
  background-color: lightgrey;
}
.btn-std {
  background-color: darkcyan;
  width: 200px;
}
.btn-std:hover {
  background-color: teal;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  z-index: 99;
  gap: 20px;
}
.overlay-text {
  text-align: center;
}
.text-danger {
  margin: 0;
}
/*# sourceMappingURL=form-view.component.css.map */
