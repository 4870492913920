import { Component, OnInit } from '@angular/core';
import { DetailComponentBase } from '../../../core/detail-component-base';
import { LoginService } from '../../../services/security/login/login.service';
import { AuditActionService } from '../../../services/audit/audit-action/audit-action.service';
import { GlobalSettingService } from '../../../services/system/global-setting/global-setting.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComboBoxItem } from '../../../controls/combobox/combobox.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSetting } from '../../../models/system/globalSetting';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent extends DetailComponentBase implements OnInit {
  dateFormats: ComboBoxItem[] = [];

  constructor(loginService: LoginService,
    auditActionService: AuditActionService,
    public globalSettingService: GlobalSettingService,
    public formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar) {
    super(snackBar, loginService, auditActionService);

    this.itemService = globalSettingService;
    this.actionType = 'Edit';

    this.form = new UntypedFormGroup({
      DateFormat: new UntypedFormControl('')
    });

  }

  ngOnInit(): void {
    this.loadDateFormats();



    this.globalSettingService.get('49318930-9b62-4d67-a7b5-e00d26533070')
      .subscribe((data: GlobalSetting) => (
        this.existingItem = data,
        this.form.controls.DateFormat.setValue(data.gsContent))

        //this.formImage = (data.usImage == '' ? './assets/images/userimage.png' : data.usImage),
        //this.form = this.formBuilder.group(data),
        ////Add newPassword and confirmNewPassword controls back to form since form was build above from User model class which does not include those fields.
        //this.form.addControl('newPassword', new FormControl('')),
        //this.form.addControl('confirmNewPassword', new FormControl('')),
        //this.form.controls.usPasswordExpirationDate.setValue(this.pipe.transform(data.usPasswordExpirationDate, 'yyyy-MM-dd'))

      );
    
  }

  loadDateFormats() {
    this.dateFormats.push(new ComboBoxItem('EU', 'dd-mm-yyyy')),
      this.dateFormats.push(new ComboBoxItem('US', 'mm-dd-yyyy'))
  }

  save() {
    let globalSetting = new GlobalSetting();
    var nowDate = Date.now();


      var activeUser = '';

      this.loginService.getUser().subscribe(user => {
        activeUser = user.usKey;
      });


    //globalSetting.gsKey = Guid.create().toString();
    globalSetting.gsKey = '49318930-9b62-4d67-a7b5-e00d26533070';
    globalSetting.gsName = 'DateFormat';
    globalSetting.gsContent = this.form.controls.DateFormat.value;
/*    globalSetting.gsContent = 'works';*/

    //globalSetting.createTime = this.item$.createTime;
    //globalSetting.createUserId = this.item$.createUserId;
    globalSetting.createTime = new Date(nowDate);
    globalSetting.createUserId = '00000000-0000-0000-0000-000000000000';
    globalSetting.changeTime = new Date(nowDate);
    globalSetting.changeUserId = activeUser;
    globalSetting.deleteTime = null;
    globalSetting.deleteUserId = '00000000-0000-0000-0000-000000000000';
    globalSetting.delFlag = 0;


    //globalSetting.changeTime = new Date(nowDate);
    //globalSetting.changeUserId = activeUser;
           

    super.save(globalSetting, globalSetting.gsKey);

    
  }

}
