<form [formGroup]="form" #formDir="ngForm" style="padding: 15px;" novalidate>
  <h1>Controls</h1>


  <!--Text Entry-->
  <TextEntry [Label]="'fwTextBox'"
             formControlName="TextEntry"
             style="margin-top:10px">
  </TextEntry>

  <!--Code Entry-->
  <CodeEntry [Label]="'fwCodeEntry'"
             formControlName="CodeEntry"
             style="margin-top:10px">
  </CodeEntry>

  <!--Numeric Entry-->
  <NumericEntry [Label]="'fwNumericBox'"
                formControlName="NumericEntry">
    <label style="margin-left:5px">days</label>
  </NumericEntry>

  <!--Password Entry-->
  <PasswordEntry [Label]="'fwPasswordBox'"
                 formControlName="PasswordEntry">
  </PasswordEntry>

  <!--Date Entry-->
  <DateEntry [Label]="'fwDatePicker'"
             formControlName="DateEntry">
  </DateEntry>

  <!--DateTime Entry-->
  <DateTimeEntry [Label]="'fwDateTimePicker'"
                 formControlName="DateTimeEntry">
  </DateTimeEntry>

  <!--ComboBox Entry-->
  <ComboBoxEntry [Label]="'fwCombobox'"
                 formControlName="ComboBoxEntry">
  </ComboBoxEntry>

  <!--CheckBox Entry-->
  <CheckBox [Label]="'fwCheckBox'"
            formControlName="CheckBox">
  </CheckBox>

  <!--ToggleButton Entry-->
  <ToggleButton [Label]="'fwSlideToggle'"
                formControlName="ToggleButton">
  </ToggleButton>


  <!--RadioButton Entry-->
  <RadioButton [Label]="'fwRadioButton'"
               [Items]="RadioButtonItems"
               [Orientation]="Horizontal"
               formControlName="RadioButton">
  </RadioButton>

  <!--CodeSearchTextBox-->
  <CodeSearchTextBox [Label]="'fwCodeSearchBox'"
                     formControlName="CodeSearchBox"
                     style="margin-top:10px">

  </CodeSearchTextBox>

  <DateRangePickerEntry [Label]="'fwDateRangePickerEntry'"
                        formControlName="DateRangePicker">
  </DateRangePickerEntry>

  <!--<MultiComboBoxEntry [Label]="'fwMultiComboBoxEntry'"
                      [items]="roles"
                      formControlName="MultiComboBox">

  </MultiComboBoxEntry>-->

  <p>fwWaitIndicator: Instructions on control's examples typescript file</p>


  <!--Button-->
  <button class="btntest btn btn-default float-left mb-3">fwButton</button>

  <br />
  <br />
  <br />

  <div style="width: 200px">
    <label>fwTreeviewWithCheckbox</label>
    <app-tree-view [TreeViewDATA]="DATA"
                   [TreeViewLOAD]="accessSettings">

    </app-tree-view>
  </div>

  <br />

  <div style="width: 200px">
    <label>fwTreeview</label>
    <TreeViewWithoutCheckBox [TreeViewDATA]="DATA">
    </TreeViewWithoutCheckBox>
  </div>

  <br />
  <br />

  <!--TabControl-->
  <label>fwTabControl</label>
  <mat-tab-group style="width: 200px">
    <mat-tab label="Tab 1">
      <div>
        <div class="tab-content">
          Tab 1 content
        </div>
      </div>
    </mat-tab>


    <mat-tab label="Tab 2">
      <div class="tab-content">
        Tab 2 content
      </div>
    </mat-tab>
  </mat-tab-group>



  <!--<custom-tabs>
    <custom-tab [label]="label1">Content A</custom-tab>
    <custom-tab [label]="label2">Content B</custom-tab>
    <custom-tab [label]="label3">Content C</custom-tab>
  </custom-tabs>-->
  <!--<Spinner formControlName="Spinner">
  </Spinner>-->

</form>

