/* src/app/FW/components/security/roles/role-view/role-view.component.scss */
.list-column {
  width: 40%;
  height: 93vh;
  padding: 10px 15px 0 15px;
  float: left;
  border-right: 1px solid grey;
}
.list-column-unselected {
  width: 100%;
  height: 93vh;
  padding: 10px 15px 0 15px;
  float: left;
  border-right: 1px solid grey;
}
/*# sourceMappingURL=role-view.component.css.map */
