import { ModelBase } from "../core/modelbase";

export class User extends ModelBase {
  usKey: string;
  usUserName: string;
  usPassword: string;
  usCode: string;
  usEmail: string;
  usStatus: number;
  usLanguage: string;
  usPasswordExpirationDays: number;
  usPasswordExpirationDate: Date;
  usPasswordLength: number;
  usFirstName: string;
  usLastName: string;
  usAddress: string;
  usImage: string;
  usRoKey: string;
  usDefaultHoursPerDay: number;
  usHourlyRate: number;
  usStartTime: string;
  usStartTimeString: string;
}
