import { AfterViewInit, Component, ContentChildren, Input, QueryList, ViewChild, } from '@angular/core';
import { MatTab, MatTabGroup, MAT_TAB_GROUP, } from '@angular/material/tabs';

import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'custom-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  providers: [
    {
      provide: MAT_TAB_GROUP,
      useValue: undefined,
    },
  ]
})

export class TabsComponent {
  @ViewChild(MatTabGroup)
  public tabGroup: MatTabGroup;

  @ContentChildren(TabComponent)
  protected tabs: QueryList<TabComponent>;

  public ngAfterViewInit() {
    const matTabsFromQueryList = this.tabs.map((tab) => tab.matTab);
    const list = new QueryList<MatTab>();
    list.reset([matTabsFromQueryList]);
    this.tabGroup._tabs = list;
    this.tabGroup.ngAfterContentInit();
  }
}
