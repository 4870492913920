/* src/app/FW/controls/radiobutton-entry/radiobutton-entry.component.scss */
.radio-group {
  flex-direction: column;
  margin: 15px 0;
}
.radio-button {
  margin-right: 5px;
}
.vertical {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.horizontal {
  flex-direction: column;
  margin: 15px 0;
}
::ng-deep .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #000000 !important;
}
::ng-deep .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #000000 !important;
}
::ng-deep .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #000000 !important;
}
/*# sourceMappingURL=radiobutton-entry.component.css.map */
