export class ItemNode {
  id: number;
  name: string;
  seKey: string;
  children: ItemNode[];

  constructor(id, name, seKey) {
    this.id = id;
    this.name = name;
    this.seKey = seKey;
  }
}
