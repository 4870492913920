import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';
import MenuItems from '../../../../../Menu/MenuItems.json';
import { VERSION } from '@angular/material/core';
//import { NavItem } from './nav-item';
import { Observable } from 'rxjs';
import { AccessSettingService } from '../services/security/access-setting/access-setting.service';
import { User } from '../models/security/user';
import { MenuService } from '../components/UI/menu/menu.service';
import { LoginService } from '../services/security/login/login.service';
import { UserService } from '../services/security/users/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterEvent } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('appDrawer') appDrawer: ElementRef;
  version = VERSION;

  user$: User;
  MainMenu: any;
  isUserLoggedIn$: boolean;
  MenuDisplay: boolean;

  constructor(
    private menuService: MenuService,
    public location: Location,
    public router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private accessSettingService: AccessSettingService) {


     this.loginService.getIsUserLoggedIn().subscribe(isUserLoggedIn => {
       this.isUserLoggedIn$ = isUserLoggedIn;

       //this.router.events.subscribe(val => {
       //  var event = val as RouterEvent;

       //  if (event.url != null) {

       //    this.loginService.getIsUserLoggedIn().subscribe(isUserLoggedIn => {
       //      this.isUserLoggedIn$ = isUserLoggedIn;
       //    });

       //    if (event.url === "/") {
       //      // do something
       //      console.log('home path reached');
       //    } else {
       //      // do something else
       //      if (this.isUserLoggedIn$ != true) {
       //        if (event.url != "/home") {
       //          this.router.navigate(['/']);
       //        }
       //      }
       //    }

       //  } else {
       //    //Do nothing
       //  }


       //});
     });





    router.events.subscribe(val => {
      var event = val as RouterEvent;

      if (event.url != null) {

        //this.loginService.getIsUserLoggedIn().subscribe(isUserLoggedIn => {
        //  this.isUserLoggedIn$ = isUserLoggedIn;
        //});

        if (event.url === "/") {
          // do something
          console.log('home path reached');
        } else {
          // do something else
          if (this.isUserLoggedIn$ != true) {
            if (event.url != "/home") {
              this.router.navigate(['/']);
            }           
          }
        }

      } else {
        //Do nothing
      }

      
    });

  }

  ngOnInit() {
    //this.snackBar.open("alert working", "ok", {
    //  duration: 1000,
    //});




    this.MenuDisplay = false;

    this.accessSettingService.GetSecurityItems().subscribe(result => {
      this.accessSettingService.setSecurityItems(result);
    });





    //this.loginService.getIsUserLoggedIn().subscribe(isUserLoggedIn => {
    //  this.isUserLoggedIn$ = isUserLoggedIn;
    //});



   




    this.loginService.getUser().subscribe(user => {
      this.user$ = user;


      //Set translation language
      this.translate.addLangs(['en', 'nl']);
      this.translate.setDefaultLang('en');
      //Get user language
      var userLanguage = this.user$.usLanguage;

      if (userLanguage != undefined) {

        if (userLanguage == 'English') {
          //const browserLang = translate.getBrowserLang();
          // translate.use(browserLang.match(/en|nl/) ? browserLang : 'en');
          this.translate.use('en');
        }

        if (userLanguage == 'Dutch') {
          //const browserLang = translate.getBrowserLang();
          // translate.use(browserLang.match(/en|nl/) ? browserLang : 'en');
          this.translate.use('nl');
        }

      }

      //const browserLang = translate.getBrowserLang();
      // translate.use(browserLang.match(/en|nl/) ? browserLang : 'en');
      //translate.use('nl');



      this.accessSettingService.GetMenu(this.user$.usRoKey)
        .subscribe((data) => {

          var menuOpenedState = this.menuService.navIsOpen();

          if (menuOpenedState == false) {
            this.menuService.toggleNav();
          }
          
          //Reload original menu *important for 'Switch user' function
          this.MainMenu = JSON.parse(JSON.stringify(MenuItems));;


          data.forEach((elementToRemove) => {

            //first find the index of element to be removed based on any condition
            const index = this.MainMenu.findIndex(element => element.accessId === elementToRemove); // 1

            if (index != -1) {
              //now use splice() method
              const result = this.MainMenu.splice(index, 1);
            }
            else {
              this.MainMenu.forEach((menuElement) => {
                if (menuElement.children.length > 0) {
                  const indexChild = menuElement.children.findIndex(element => element.accessId === elementToRemove); // 1

                  if (indexChild != -1) {
                    //now use splice() method
                    const result = menuElement.children.splice(indexChild, 1);
                  }
                }
              })
            }
          });
        });
    });




    var userName = localStorage.getItem('user');
    var userRole = localStorage.getItem('userRole');

    if (userRole == null) {
      this.menuService.navIsOpen();
    }

    if (userRole != null) {



      this.userService.getUserByUserName(userName)
         .subscribe((data) => {


           this.loginService.setisUserLoggedIn(true, data);
           console.log(data);
           console.log(userRole);

        });




      //  this.isUserLoggedIn$ = true;



      //this.accessSettingService.GetMenu(userRole)
      //  .subscribe((data) => {

      //    //this.menuService.toggleNav();
      //    console.log(this.isUserLoggedIn$)

      //    //Reload original menu *important for 'Switch user' function
      //    this.MainMenu = JSON.parse(JSON.stringify(MenuItems));;


      //    data.forEach((elementToRemove) => {

      //      //first find the index of element to be removed based on any condition
      //      const index = this.MainMenu.findIndex(element => element.accessId === elementToRemove); // 1

      //      if (index != -1) {
      //        //now use splice() method
      //        const result = this.MainMenu.splice(index, 1);
      //      }
      //      else {
      //        this.MainMenu.forEach((menuElement) => {
      //          if (menuElement.children.length > 0) {
      //            const indexChild = menuElement.children.findIndex(element => element.accessId === elementToRemove); // 1

      //            if (indexChild != -1) {
      //              //now use splice() method
      //              const result = menuElement.children.splice(indexChild, 1);
      //            }
      //          }
      //        })
      //      }
      //    });
      //  });


    }

    //this.router.navigate(['/timesheets']);


  }

  ngAfterViewInit() {
    this.menuService.appDrawer = this.appDrawer;
        //this.router.navigate(['/timesheets']);
  }

  ngAfterIt() { }
}
