<ReportHeaderButtons></ReportHeaderButtons>

<div mat-dialog-content>

  <ReportHeader></ReportHeader>


  <table id="dataTable"
         mat-table
         [dataSource]="reportDataSource">


    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef> Code </th>
      <td mat-cell *matCellDef="let item"> {{item.code}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let item"> {{item.name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[20]"
                 showFirstLastButtons
                 (page)="pageEvent = onPageChange($event)">
  </mat-paginator>
</div>
