import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';
import { VERSION } from '@angular/material/core';
//import { NavItem } from 'src/app/nav-item';
import { Observable } from 'rxjs';
import { MenuService } from '../menu.service';
import { LoginService } from '../../../../services/security/login/login.service';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('appDrawer') appDrawer: ElementRef;
  version = VERSION;

  isUserLoggedIn$: boolean;   

  //navItems: NavItem[] = [
  //  {
  //    displayName: 'Home',
  //    iconName: 'home',
  //    route: ''
  //  },
  //  {
  //    displayName: 'Security',
  //    iconName: 'security',
  //    route: '',
  //    children: [
  //      {
  //        displayName: 'Users',
  //        iconName: 'group',
  //        route: 'userview'
  //      }
  //    ]
  //  },
  //  {
  //    displayName: 'Contacts',
  //    iconName: 'contacts',
  //    route: 'contact/list'
  //  },
  //  {
  //    displayName: 'Admin Panel',
  //    iconName: 'admin_panel_settings',
  //    route: 'admin'
  //  },
  //];

  constructor(
    private menuService: MenuService,
    private loginService: LoginService) {
  }

  ngOnInit() {
    //this.isUserLoggedIn$ = this.loginService.userLoggedIn; // {2}
    this.isUserLoggedIn$ = true;
    this.loginService.getIsUserLoggedIn().subscribe(isUserLoggedIn => {
      this.isUserLoggedIn$ = isUserLoggedIn;
    });
  }

  ngAfterViewInit() {
    this.menuService.appDrawer = this.appDrawer;
  }
}
