  <div mat-dialog-content tabindex="0" (keydown.control.p)="onKeydown($event)"  >
    <div class="leftbuttons" style="border-bottom:1px solid black;">
      <button (click)="PrintReport()"
              class="btntest btn-lg btn btn-default float-left">
        Print
      </button>

      <button (click)="DownloadPDF()"
              class="btntest btn-lg btn btn-default float-left">
        PDF
      </button>

      <button (click)="DownloadCSV()"
              class="btntest btn-lg btn btn-default float-left">
        CSV
      </button>

      <button class="float-right" mat-icon-button id="close" (click)="closeView()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

<br />
<br />
<br />
<br />
