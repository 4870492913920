import {  forwardRef, Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

//export const EPANDED_TEXTAREA_VALUE_ACCESSOR: any = {
//  provide: NG_VALUE_ACCESSOR,
//  multi: true,
//  useExisting: forwardRef(() => ControlComponentBase),
//};

//@Component({
//  selector: 'app-control-base',
//  providers: [EPANDED_TEXTAREA_VALUE_ACCESSOR]
//})

export class ControlComponentBase {
  @Input() Label: string;
  @Input() IsDisabled: boolean;
  LabelText: string;

  value: string;
  onChange() { }
  onTouched() { }

  constructor() {
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn
  }

  registerOnTouched(fn) {
    this.onTouched = fn
  }
}





