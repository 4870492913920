import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ControlComponentBase } from '../../core/control-component-base';
import { ComboBoxItem } from '../combobox/combobox.component';
import { DatePipe } from '@angular/common';
import { ReportService } from '../../services/reports/report.service';


export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => DateRangePickerEntryComponent),
};

@Component({
  selector: 'DateRangePickerEntry',
  templateUrl: './daterangepicker-entry.component.html',
  providers: [CONTROL_VALUE_ACCESSOR]
})

export class DateRangePickerEntryComponent extends ControlComponentBase implements ControlValueAccessor, OnInit {
  @Input() defaultDates: number;
  pipe = new DatePipe('en-US');

  form: UntypedFormGroup;
  dateRanges: ComboBoxItem[] = [];


  constructor(reportService: ReportService) {
    super();

    this.form = new UntypedFormGroup({
      dateSelection: new UntypedFormControl(''),
      StartDate: new UntypedFormControl(''),
      EndDate: new UntypedFormControl('')
    });


    this.form.get("dateSelection").valueChanges.subscribe(x => {
      reportService.getDate(x).subscribe(dateSelection => {
        this.form.controls.StartDate.setValue(dateSelection.startDate);
        this.form.controls.EndDate.setValue(dateSelection.endDate);

      });
    })
  }

  ngOnInit(): void {
    this.loadDateRanges();

    if (this.defaultDates > 0) {
      this.form.controls.dateSelection.setValue(this.defaultDates);
    }
  }

  writeValue(value: any) {
    if (value) {
      this.form.setValue(value);
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched() { }


  loadDateRanges() {
    this.dateRanges.push(new ComboBoxItem(0, 'Enter Dates')),
      this.dateRanges.push(new ComboBoxItem(1, 'Today')),
      this.dateRanges.push(new ComboBoxItem(2, 'This Week')),
      this.dateRanges.push(new ComboBoxItem(3, 'This Month')),
      this.dateRanges.push(new ComboBoxItem(4, 'This Year')),
      this.dateRanges.push(new ComboBoxItem(5, 'Yesterday')),
      this.dateRanges.push(new ComboBoxItem(6, 'Last Week')),
      this.dateRanges.push(new ComboBoxItem(7, 'Last Month')),
      this.dateRanges.push(new ComboBoxItem(8, 'Last Year'))
  }
}
