import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationStart } from '@angular/router';
import { LoginComponent } from '../components/security/login/login.component';
import { HomeComponent } from '../components/home/home.component';

//SYSTEM
import { AdminComponent } from '../components/system/admin/admin.component';
import { GlobalSettingsComponent } from '../components/system/global-settings/global-settings.component';
import { MyAccountComponent } from '../components/security/my-account/my-account.component';
import { HolidayComponent } from '../components/system/holiday/holiday.component';

//SECURITY
import { UserViewComponent } from '../components/security/users/user-view/user-view.component';
import { RoleViewComponent } from '../components/security/roles/role-view/role-view.component';

import { ReportViewComponent } from '../components/reports/report-view/report-view.component';


import { SwitchUserComponent } from '../components/security/switch-user/switch-user.component';
import { ControlViewComponent } from '../controls/_Examples/control-view.component';

//Forms
import { FormComponent } from '../../FriendsWithBenefits/components/forms/form-view/form-view.component';

const routes: Routes = [
  { path: '', component: FormComponent },
  { path: 'home', component: HomeComponent },
  { path: 'system/admin', component: AdminComponent },
  { path: 'system/settings/global', component: GlobalSettingsComponent },
  { path: 'system/myaccount', component: MyAccountComponent },
  { path: 'system/holidays', component: HolidayComponent },
  { path: 'security/users', component: UserViewComponent },
  { path: 'security/roles', component: RoleViewComponent },
  { path: 'reportview', component: ReportViewComponent },
  { path: 'controlview', component: ControlViewComponent },
  { path: 'switchuser', component: SwitchUserComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

  constructor(router: Router) {
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          // Perform actions
          console.log("back button logged");
        }
      });
  }

}
