<ReportHeaderButtons></ReportHeaderButtons>

<div mat-dialog-content>

  <ReportHeader></ReportHeader>

  <table id="dataTable"
         mat-table
         [dataSource]="reportDataSource">


    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef> Code </th>
      <td mat-cell *matCellDef="let item"> {{item.code}} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let item"> {{item.username}} </td>
    </ng-container>


    <!-- Role Column -->
    <ng-container matColumnDef="role">

      <th mat-header-cell *matHeaderCellDef> Role </th>
      <td mat-cell *matCellDef="let item"> {{item.role}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[20]"
                 showFirstLastButtons
                 (page)="pageEvent = onPageChange($event)">
  </mat-paginator>
</div>
