<div class="row">
  <label class="col-sm-2" for="Input">{{ Label }}</label>

  <div class="col-md-8">

    <mat-slide-toggle>

    </mat-slide-toggle>

  </div>
</div>


