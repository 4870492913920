<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" (click)="treeControl.toggle(node)" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="checklistSelection.toggle(node);">{{node.name | translate}}</mat-checkbox>

  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button (click)="treeControl.toggle(node)"
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">{{node.name | translate}}</mat-checkbox>
  </mat-tree-node>
</mat-tree>


{{ getSelected() }}
<!--{{ TreeViewDATA }}-->
<!--{{ TreeViewLOAD }}-->
