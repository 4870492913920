import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportPreviewItem, ReportComponentBase } from '../../../../../core/report-component-base';
import { ReportService } from '../../../../../services/reports/report.service';

@Component({
  selector: 'ReportHeader',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})

export class ReportHeaderComponent extends ReportComponentBase implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReportHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportPreviewItem,
    public reportService: ReportService) {
    super(dialogRef, data, reportService);

    //Set orientation; portrait | landscape
    //this.reportOrientation = 'portrait';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}

