import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../models/security/user';
import { UserService } from '../../../services/security/users/user.service';
import { DialogModel, DialogComponent } from '../../../controls/dialogs/dialog/dialog.component';
import { LoginUser } from '../../../models/security/LoginUser';
import { LoginService } from '../../../services/security/login/login.service';
import { MenuService } from '../../UI/menu/menu.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  users$: Observable<User[]>;
  gotuser: User;

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(1)])
  });

  get form() {
    return this.loginForm.controls;
  }

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private menuService: MenuService,
    private router: Router,
    private modalService: NgbModal,
    private cookieService: CookieService,
    public dialog: MatDialog) {

   

  }

  onKeydown(event) {
    if (event.key === "Enter") {
      console.log(event);
    }
  }

  ngOnInit(): void {
    let lastUsedUsername = this.cookieService.get("ActiveUser");
    this.loginForm.controls.username.setValue(lastUsedUsername);

    if (localStorage.getItem('user') != null) {
      this.router.navigate(['/home']);
    }

    //location.reload();

    //localStorage.clear();

    //this.menuService.navIsOpen();

    //this.loginService.setisUserLoggedInOnly(false);

    //this.loginService.getUser()
    //  .subscribe((data) => {

    //    console.log(data);


    //  });

    //this.menuService.toggleNav();
  }

  ngAfterViewInit() {
    var menuOpenedState = this.menuService.navIsOpen();

    if (menuOpenedState == true) {
      this.menuService.toggleNav();
    }
  }

  doLogin() {
    //Name: Jose de Jesus;
    //Function: Validate that the login form fields are not empty;
    if (this.loginForm.valid) {
      this.Login();
    } else {

      const message = `Please fill in all fields`;
      const dialogData = new DialogModel("Login", message);

      const dialogRef = this.dialog.open(DialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          ///this close used to be here
        }
      });

    }
  }

  Login() {
    //this.users$ = this.loginService.getUsers();
    var loginDetails = new LoginUser();

    loginDetails.username = this.form.username.value;
    loginDetails.password = this.form.password.value;

    this.loginService.doLogin(loginDetails).subscribe(loginResponse => {

    

      switch (loginResponse.result) {
        case 1: {

          var menuOpenedState = this.menuService.navIsOpen();

          if (menuOpenedState == false) {
            this.menuService.toggleNav();
          }

          localStorage.setItem('jwt', 'localstorage test is working');
          localStorage.setItem('user', loginResponse.user.usUserName);
          localStorage.setItem('userRole', loginResponse.user.usRoKey);

       //   localStorage.clear

          var localStorageUser = localStorage.getItem('user');
          localStorage.setItem('userre', localStorageUser);



          this.gotuser = loginResponse.user;
          this.cookieService.set("ActiveUser", this.gotuser.usUserName);
          //mark user as logged in login service
          this.loginService.setisUserLoggedIn(true, this.gotuser);

         

          this.router.navigate(['/home']);
          break;
        }
        case 2: {

          const message = `Username not found`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 3: {

          const message = `Incorrect password`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 4: {

          const message = `Account Disabled. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 5: {

          const message = `Account Deleted. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 6: {

          const message = `The user role was deleted. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
        case 7: {

          const message = `The user password is expired. Please contact Administrator`;
          const dialogData = new DialogModel("Login", message);

          const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });

          break;
        }
      } 


    });
  }
}
