<div style="padding: 10px 15px 0 15px;">
  <h1>{{ 'Global Settings' | translate }}</h1>

  <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate>
    <div class="row">
      <div class="col-md-8">

        <!--<select style="height:24px; padding: 2px;"
          class="formcontrol"
          id="Input"
          (blur)="onTouched()"
          (input)="onChange($event.target.value)">
    <option value="EU">dd-mm-yyyy</option>
    <option value="US">mm-dd-yyyy</option>
  </select>-->



        <ComboBoxEntry class=""
                       Label="{{ 'Date Format' | translate }}"
                       formControlName="DateFormat">
          <option *ngFor="let format of dateFormats" [value]="format.value">
            {{format.name}}
          </option>
        </ComboBoxEntry>

        <button (click)="save()" type="submit" class="btntest btn btn-default">{{ 'Save' | translate }}</button>

      </div>



      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <!--<button (click)="save()" type="submit" class="globalbtn btntest btn btn-default float-right">{{ 'Save' | translate }}</button>-->
      <div class="form-group">
        <!--<button (click)="save()" type="submit" class="btntest btn btn-default float-right">{{ 'Save' | translate }}</button>-->
      </div>


    </div>

  </form>
</div>
